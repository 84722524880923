import React from 'react';
import autoBind from 'react-autobind';
import axios from 'axios';
import auth from 'services/Authed/Authed.js';
import api from 'services/Api/Api.js';
import ApFormPage from 'common/ApFormPage/ApFormPage.js';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import { errorPopper, ucfirst, formatMoney, onlyNumber, profitPercentToSellPrice, tr } from 'services/Helpers/Helpers.js';
import ApModal from 'common/ApModal/ApModal.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import CodeSelect from 'modules/Storage/Components/ComponentEdit/CodeSelect/CodeSelect.js'

import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import ApButton from 'common/ApButton/ApButton.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import ComponentTooltip from 'modules/Storage/common/ComponentTooltip/ComponentTooltip.js';
import './WholesaleComponents.css';
import { getComponentTypeId } from 'modules/Storage/common/StorageHelpers.js';
import ComponentEdit from 'modules/Storage/Components/ComponentEdit/ComponentEdit.js';

import { ApInput,ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';


const defaultProfitPercent = 10;

class Wholesale extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            pages: 0,
            data: [],

            categories: [],
            wholesales: [],

            loading: false,
            loadingRelated: false,

            selected: [],

            currencySign: auth.getCurrencySign(),

            editModal: {},
            codeModal: {},
            codeSkipped: true,
            allProfitPercent: 30,

            joinModal: {},
            joinDetail: 'nameFull',
            selectedComponent: null,

            cEditModal: {},

            editTotalProfit: defaultProfitPercent,
        }

        this.tableState = {};

        this.cancelToken = null;

        autoBind(this);
    }

    componentDidMount()
    {
        this.getRelated();
    }

    getRelated()
    {
        this.setState({loadingRelated: true});

        api({
            method: 'get',
            url: 'storage/wholesale/related',
        }).then((response) => {
            this.setState({
                loadingRelated: false,
                categories: response.categories,
                wholesales: response.wholesales,
            })
        }).catch((error) => {
            console.error(error);
            this.setState({loadingRealated: false});
            errorPopper(error, tr('get_error'));
        });
    }
    
    setIdentifier( identifier )
    {
        this.setState({
        	identifier: identifier,
        });
        let selected = this.state.selected.slice( 0 );

        	api({
                method: 'post',
                url: 'storage/wholesale/existing',
                data: {
                	identifier:identifier,
                	components:selected
                },
            }).then((response) => {
            	selected=response;
            	this.setState({ selected: selected });
            	this.setState({loading: false});
                
            }).catch((error) => {
              
                console.error(error);
                this.setState({loading: false});

            });

        //this.setState({ selected: selected });
        //console.log(this.state.identifier);
    }
    
    
    
    getData(state = null)
    {
        if( this.cancelToken )
            this.cancelToken.cancel("Only one request allowed at a time.");
        this.cancelToken = axios.CancelToken.source();

        window.clearTimeout(this.getTimer);
        this.getTimer = window.setTimeout(() => {

            this.setState({loading: true});

            if(!state)
                state = this.tableState;
            this.tableState = state;

            api({
                cancelToken: this.cancelToken.token,
                method: 'post',
                url: 'storage/wholesale/components',
                data: {
                    page: state.page,
                    pageSize: state.pageSize,
                    sorted: state.sorted,
                    filtered: state.filtered,
                },
            }).then((response) => {
                //console.log('response', response.data );

                this.setState({
                    data: response.data,
                    pages: response.pages,
                    loading: false,
                })
            }).catch((error) => {
                if( axios.isCancel( error ) ) return null;
                console.error(error);
                this.setState({loading: false});
                errorPopper(error, tr('get_error'));
            });
        }, 500);
    }
    
    getLinked() {
    	
    }


    filterListWholesale()
    {
        let options = [ { value: '', label: ` - ${tr('all')} - ` } ];
        if( !this.state ) return options;
        this.state.wholesales.forEach( item => {
            options.push({ value: item.id, label: ucfirst( item.name ) });
        })
        return options;
    }

    filterListCategory()
    {
        let options = [ { value: '', label: ` - ${tr('all')} - ` } ];
        if( !this.state ) return options;
        this.state.categories.forEach( item => {
            options.push({ value: item.id, label: ucfirst( item.name ) });
        })
        return options;
    }


    // ----------------------------------------
    //  Main table
    // ----------------------------------------

    mainRowSelected( row )
    {
        if ( row === 'visible' )
        {
            return this.state.data.every( i => {
                return this.state.selected.find( s => s.id === i.id );
            })
        }
        return Boolean( this.state.selected.find( i => i.id === row.id ) );
    }

    toggleMainRowSelection( row )
    {
        const selectRow = ( row ) =>{
            let newRow = JSON.parse(JSON.stringify( row ));

            // Add editable name to selected and remove speces
            newRow.saveName = `${ row.name } ${ row.name2 }`.replace(/ +(?= )/g,'');
            newRow.saveUnit = `${ row.unit }`.replace(/ +(?= )/g,'');
            newRow.saveCode = null;
            //newRow.codeSkipped = true;
            newRow.saveComponent = null;
            newRow.saveProfitPercent = this.state.editTotalProfit;
            newRow.saveUpdatePrice = true;

            selected.push( newRow );
        }

        let selected = this.state.selected.slice( 0 );

        if( row === 'visible' )
        {
            if( this.mainRowSelected( 'visible' ) )
            {
                let toggleOffIds = [];
                this.state.data.forEach( row => {
                    const found = selected.find( i => i.id === row.id )
                    if( found ) toggleOffIds.push( found.id );
                })
                selected = selected.filter( i => !toggleOffIds.includes( i.id  ) );
            }
            else
            {
                this.state.data.forEach( row => {
                    const found = selected.find( i => i.id === row.id )
                    if( !found ) selectRow( row )
                })
            }

        }
        else
        {
            const found = selected.find( i => i.id === row.id )
            if( found ) selected = selected.filter( i => i.id !== row.id )
            else selectRow( row )
        }

        this.setState({ selected: selected });
    }

    mainColumns()
    {
        return [
            {
                //Header: () => multiselectActionsButton,
                headerClassName: "multiselect",
                columns: [{
                    sortable: false,
                    resizable: false,
                    width: 70,
                    headerClassName: "multiselect",
                    className: "multiselect",
                    footerClassName: "multiselect",
                    Cell: row => {
                        if( row.original.component ) return <div className="inputWrapper"></div>;
                        return <div className="inputWrapper" onClick={ () => this.toggleMainRowSelection( row.original ) }><input type="checkbox" checked={ this.mainRowSelected( row.original ) } readOnly /></div>
                    },
                    Filter: () => {
                        return (
                            <div className="multiselectFiltered" onClick={ () => this.toggleMainRowSelection( 'visible' ) }>
                                <input type="checkbox" checked={ this.mainRowSelected( 'visible' ) } readOnly />
                                <span className={"apBadge " + ( this.state.selected.length > 0 ? "blue" : "faded" ) }>{ this.state.selected.length }</span>
                            </div>
                        );
                    }
                }]
            },{
                Header: tr('storage_component'),
                accessor: 'component',
                width: 50,
                filterable: false,
                sortable: false,
                className: "overflowableOnHover",
                Cell: row => {
                    if( !row.original.component ) return null;
                    const tooltip = <ComponentTooltip component={ row.original.component } />
                    return <div className="checkboxLinked inputWrapper">
                        <ApTooltip text={ tooltip } position="topleft">
                            <SvgIcon icon="puzzle-piece" type="solid" />
                        </ApTooltip>
                    </div>
                },
            },{
                Header: tr('supplier'),
                accessor: 'wholesale_id',
                customizable: true,
                showInitially: true,
                width: 100,
                Cell: props => {
                    let wholesale = this.state.wholesales.find( i => i.id === props.value );
                    if( !wholesale ) return null;
                    return ucfirst( wholesale.name );
                },
                customFilter: {
                    type: "select",
                    options: this.filterListWholesale(),
                },
            },{
                Header: tr('product_category'),
                accessor: 'category_id',
                customizable: true,
                showInitially: true,
                width: 100,
                Cell: props => {
                    let category = this.state.categories.find( i => i.id === parseInt( props.value, 10 ) );
                    if( !category ) return null;
                    return ucfirst( category.name );
                },
                customFilter: {
                    type: "select",
                    options: this.filterListCategory(),
                },
            },{
                Header: tr('name'),
                accessor: 'name_full',
                customizable: true,
                showInitially: true,
                Cell: props => {
                    return `${ props.original.name } ${ props.original.name2 }`
                },
            },{
                Header: tr('name_start'),
                accessor: 'name',
                customizable: true,
                showInitially: false,
            },{
                Header: tr('name_extension'),
                accessor: 'name2',
                customizable: true,
                showInitially: false,
            },{
                Header: tr('product_number'),
                accessor: 'number',
                customizable: true,
                showInitially: true,
            },{
                Header: tr('search_string'),
                accessor: 'search_string',
                customizable: true,
                showInitially: false,
            },{
                Header: tr('quick_code'),
                accessor: 'quick_code',
                customizable: true,
                showInitially: false,
            },{
                Header: tr('discount_group'),
                accessor: 'discount_group',
                customizable: true,
                showInitially: false,
            },{
                Header: tr('unit'),
                accessor: 'unit',
                customizable: true,
                showInitially: true,
                width: 100,
            },{
                Header: tr('price'),
                accessor: 'price',
                customizable: true,
                showInitially: true,
                filterable: false,
                width: 150,
                Cell: props => {
                    if( props.value === null ) return null;
                    return <div className="text-right">{ formatMoney( props.value, 2 ) } <small>{ this.state.currencySign }</small></div>;
                },
            },{
                Header: tr('other_wholesalers'),
                accessor: 'number_group',
                customizable: true,
                showInitially: true,
                filterable: false,
                sortable: false,
                width: 150,
                Cell: props => {
                    return ( props.original.number_group.length - 1 );
                },
            },{
                Header: tr('unit_weight'),
                accessor: 'weight',
                customizable: true,
                showInitially: false,
                Cell: props => {
                    if( !props.value ) return null;
                    if( props.value === '0') return null;
                    return <div className="text-right">{ formatMoney( props.value, 3 ) } <small>kg</small></div>;
                },
            },{
                Header: tr('unit_capacity'),
                accessor: 'capacity',
                customizable: true,
                showInitially: false,
                Cell: props => {
                    if( !props.value ) return null;
                    if( props.value === '0') return null;
                    return <div className="text-right">{ formatMoney( props.value, 3 )  } <small>ltr</small></div>;
                },

            },{
                Header: tr('common_package_size'),
                accessor: 'package_common',
                customizable: true,
                showInitially: false,
            },{
                Header: `${tr('package_size')} 1`, 
                accessor: 'package_1',
                customizable: true,
                showInitially: false,
            },{
                Header: `${tr('package_size')} 1 ${tr('discount_percentage')}`,
                accessor: 'package_1_discount',
                customizable: true,
                showInitially: false,
            },{
                Header: `${tr('package_size')} 2`,
                accessor: 'package_2',
                customizable: true,
                showInitially: false,
            },{
                Header: `${tr('package_size')} 2 ${tr('discount_percentage')}`,
                accessor: 'package_2_discount',
                customizable: true,
                showInitially: false,
            },{
                Header: `${tr('package_size')} 3`,
                accessor: 'package_3',
                customizable: true,
                showInitially: false,
            },{
                Header: `${tr('package_size')} 3 ${tr('discount_percentage')}`,
                accessor: 'package_3_discount',
                customizable: true,
                showInitially: false,
            },{
                Header: tr('wholesaler_delivery_date_wk'),
                accessor: 'procuring_weeks',
                customizable: true,
                showInitially: false,
            },{
                Header: tr('storing_type'),
                accessor: 'storing_type',
                customizable: true,
                showInitially: false,
            },{
                Header: 'EAN',
                accessor: 'ean',
                customizable: true,
                showInitially: false,
            },{
                Header: tr('usage_unit'),
                accessor: 'use_unit',
                customizable: true,
                showInitially: false,
            },{
                Header: tr('conversion_factor'),
                accessor: 'conversion_factor',
                customizable: true,
                showInitially: false,
            },
        ];

    //
    // tax_group

    // id
    // created_at
    // updated_at
    // number_id
    // buyer_code
    // seller_code
    // latest_operation
    // language

    // valid_from

    }

    mainCustomRowClass( row )
    {
        if( row && row.original )
        {
            if( this.state.selected.find( i => i.id === row.original.id ) )
                return 'selected';
        }
        return '';
    }


    // ----------------------------------------
    //  Edit modal
    // ----------------------------------------

    changeName( index, value )
    {
        let selected = this.state.selected.slice( 0 );
        selected[ index ].saveName = value;
        this.setState({ selected: selected });
    }
    changeUnit( index, value )
    {
        let selected = this.state.selected.slice( 0 );
        selected[ index ].saveUnit = value;
        this.setState({ selected: selected });
    }
    changeProfitPercent( index, value, blur = false )
    {
        value = onlyNumber( value, true );
        if( blur )
        {
            if( parseFloat( value ) >= 100 )
                value = 99.9999;
        }
        let selected = this.state.selected.slice( 0 );
        selected[ index ].saveProfitPercent = value;
        this.setState({ selected: selected });
    }
    changeUpdatePrice(index )
    {
        let selected = this.state.selected.slice( 0 );
        selected[ index ].saveUpdatePrice = !selected[ index ].saveUpdatePrice;
        this.setState({ selected: selected });
    }

    changeAllProfitPercent( value, blur = false )
    {
        value = onlyNumber( value, true );
        if( blur )
        {
            if( parseFloat( value ) >= 100 )
                value = 99.9999;

            let selected = this.state.selected.slice( 0 );
            selected.map( i => {
                i.saveProfitPercent = value;
                return i;
            });
            this.setState({ selected: selected });

        }
        this.setState({ editTotalProfit: value });
    }


    editModalOpen()
    {
       // console.log('editModalOpen', this.state.selected );
        this.setState({ editModal: {
            show: true,
        }});
    }

    editModalColumns()
    {
        return [
            {
                Header: tr('storage_component'),
                width: 50,
                className: "overflowableOnHover",
                Cell: props => {
                    if( props.original.saveComponent )
                    {
                        return <div className="red">
                            <ApTooltip text={ tr('link_to_previous') } position="topleft">
                                <SvgIcon icon="link" type="solid" />
                            </ApTooltip>
                        </div>
                    }
                    return <div className="red">
                        <ApTooltip block text={ tr('new_storage_component') } position="topleft">
                            <SvgIcon icon="plus-circle" type="solid" />
                        </ApTooltip>
                    </div>
                },
            },{
                Header: tr('name'),
                accessor: 'saveName',
                Cell: props => {
                    if( props.original.saveComponent ) return props.original.saveComponent.name;
                    return <input
                        className="textInput"
                        value={ props.value }
                        onChange={ ( e ) => { this.changeName( props.index, e.target.value ) }}
                    />
                },
                /*
            },{
                Header: 'Koodi',
                accessor: 'saveCode',
                Cell: props => {
                    if( props.original.saveComponent ) return props.original.saveComponent.code;
                    return <div className="codeInput" onClick={ ()  => this.codeModalOpen( props.index) }>
                    </div>
                },
                width: 100,
                */
            },{
                Header: tr('unit'),
                accessor: 'saveUnit',
                Cell: props => {
                    if( props.original.saveComponent ) return props.original.saveComponent.unit;

                    return <input
                        className="textInput"
                        value={ props.value }
                        onChange={ ( e ) => { this.changeName( props.index, e.target.value ) }}
                    />
                },
                width: 100,
            },{
                Header: tr('price'),
                accessor: 'price',
                customizable: true,
                showInitially: true,
                filterable: false,
                width: 150,
                Cell: props => {
                    let value = props.value;
                    if( props.value === null ) return null;

                    if( props.original.saveComponent && !props.original.saveUpdatePrice )
                        value = props.original.saveComponent.price;

                    return <div className="text-right">{ formatMoney( value, 2 ) } <small>{ this.state.currencySign }</small></div>;
                },
            },{
                Header: tr('gross_profit_percent'),
                accessor: 'saveProfitPercent',
                Cell: props => {
                    if( props.value === null ) return null;

                    if( props.original.saveComponent && !props.original.saveUpdatePrice )
                        return <div className="text-right"> { props.original.saveComponent.profit_percent } <small>%</small></div>;

                    return <input
                        className="textInput"
                        value={ props.value }
                        onChange={ ( e ) => { this.changeProfitPercent( props.index, e.target.value ) }}
                        onBlur={ ( e ) => { this.changeProfitPercent( props.index, e.target.value, true ) }}
                    />
                },
                width: 100,

            },{
                Header: tr('update_price_automatically'),
                accessor: 'saveUpdatePrice',
                Cell: props => {
                    return <ApSwitch
                        small
                        id={ `update-price-switch-${ props.index }` }
                        on={ props.value }
                        onChange={ () => this.changeUpdatePrice( props.index ) }
                        disabled={ this.state.loading }
                    />
                },
                width: 100,
            },{
                Header: tr('supplier'),
                accessor: 'wholesale_id',
                customizable: true,
                showInitially: true,
                width: 100,
                Cell: props => {
                    let wholesale = this.state.wholesales.find( i => i.id === props.value );
                    if( !wholesale ) return null;
                    return ucfirst( wholesale.name );
                },
                customFilter: {
                    type: "select",
                    options: this.filterListWholesale(),

                },
            },{
                Header: tr('product_category'),
                accessor: 'category_id',
                customizable: true,
                showInitially: true,
                width: 100,
                Cell: props => {
                    let category = this.state.categories.find( i => i.id === parseInt( props.value, 10 ) );
                    if( !category ) return null;
                    return ucfirst( category.name );
                },
                customFilter: {
                    type: "select",
                    options: this.filterListCategory(),
                },
            },{
                Header: tr('product_number'),
                accessor: 'number',
                customizable: true,
                showInitially: true,
            },{
                Header: tr('search_string'),
                accessor: 'search_string',
                customizable: true,
                showInitially: false,
            },{
                Header: tr('quick_code'),
                accessor: 'quick_code',
                customizable: true,
                showInitially: false,
            },{
                Header: tr('discount_group'),
                accessor: 'discount_group',
                customizable: true,
                showInitially: false,
            },{
                Header: tr('unit_weight'),
                accessor: 'weight',
                customizable: true,
                showInitially: false,
                Cell: props => {
                    if( !props.value ) return null;
                    if( props.value === '0') return null;
                    return <div className="text-right">{ formatMoney( props.value, 3 ) } <small>kg</small></div>;
                },
            },{
                Header: tr('unit_capacity'),
                accessor: 'capacity',
                customizable: true,
                showInitially: false,
                Cell: props => {
                    if( !props.value ) return null;
                    if( props.value === '0') return null;
                    return <div className="text-right">{ formatMoney( props.value, 3 )  } <small>ltr</small></div>;
                },

            },{
                Header: tr('common_package_size'),
                accessor: 'package_common',
                customizable: true,
                showInitially: false,
            },{
                Header: `${tr('package_size')} 1`,
                accessor: 'package_1',
                customizable: true,
                showInitially: false,
            },{
                Header: `${tr('package_size')} 1 ${tr('discount_percentage')}`,
                accessor: 'package_1_discount',
                customizable: true,
                showInitially: false,
            },{
                Header: `${tr('package_size')} 2`,
                accessor: 'package_2',
                customizable: true,
                showInitially: false,
            },{
                Header: `${tr('package_size')} 2 ${tr('discount_percentage')}`,
                accessor: 'package_2_discount',
                customizable: true,
                showInitially: false,
            },{
                Header: `${tr('package_size')} 3`,
                accessor: 'package_3',
                customizable: true,
                showInitially: false,
            },{
                Header: `${tr('package_size')} 3 ${tr('discount_percentage')}`,
                accessor: 'package_3_discount',
                customizable: true,
                showInitially: false,
            },{
                Header: tr('wholesaler_delivery_date_wk'),
                accessor: 'procuring_weeks',
                customizable: true,
                showInitially: false,
            },{
                Header: tr('storing_type' ),
                accessor: 'storing_type',
                customizable: true,
                showInitially: false,
            },{
                Header: 'EAN',
                accessor: 'ean',
                customizable: true,
                showInitially: false,
            },{
                Header: tr('usage_unit'),
                accessor: 'use_unit',
                customizable: true,
                showInitially: false,
            },{
                Header: tr('conversion_factor'),
                accessor: 'conversion_factor',
                customizable: true,
                showInitially: false,
            },
        ];

    }

    editModalSave()
    {
        let data = [];

        this.state.selected.forEach( i => {

            const priceData = {
                update_price: i.saveUpdatePrice,
                price: i.price,
                price_sell: profitPercentToSellPrice( i.price, i.saveProfitPercent ),
                profit_percent: i.saveProfitPercent,
            };

            if( i.saveComponent )
            {
                data.push({
                    ...priceData,
                    component_id: i.saveComponent.id,
                    wholesale_component_id: i.id,
                });
            }
            else
            {
                data.push({
                    ...priceData,
                    component_id: null,
                    wholesale_component_id: i.id,
                    name: i.saveName,
                    unit: i.saveUnit,
                    number: i.number,
                });
            }
        });

        //console.log( 'saveComponents', data );

        this.setState({loading: true});
        api({
            method: 'post',
            url: 'storage/wholesale/attach',

            data: {
                components: data,
                identifier: this.state.identifier,
            },
            errorPopper: tr('save_error'),
        }).then((response) => {
            //console.log('response', response);
            this.getData();
            this.setState({
                loading: false,
                editModal: {},
                selected: [],
            })
        }).catch((error) => {
            // console.error(error);
            this.setState({loading: false});
            // errorPopper(error, 'Tukkurimateriaalin yhdistämisessä tapahtui virhe');
        });
    }
    import() {
        this.setState({
            loading: true,
        });

        let data = new FormData();
        data.append('file', this.state.selected_file);
        data.append('seller', this.state.seller?this.state.seller.value:this.state.wholesales[0].id);

        api({
            method: 'post',
            url: 'storage/wholesale/readExcel',
            data: data,
            headers: { 'Content-Type': 'multipart/form-data' },
        }).then((response) => {
            // let headerOptions = this.state.headerOptions;
            // let otherCols = this.state.otherCols;
            console.log('response', response);


            for (let row of response) {
                row.saveName = `${row.name} ${row.name2}`.replace(/ +(?= )/g, '');
                row.saveUnit = `${row.unit}`.replace(/ +(?= )/g, '');
                row.saveCode = null;
                //row.codeSkipped = true;
                row.saveComponent = null;
                
                if (row.components?.length > 0) {
                    row.saveComponent = row.components[0];
                }
                row.saveProfitPercent = this.state.editTotalProfit;
                row.saveUpdatePrice = true;
            }
            
            let selected=this.state.selected;
            selected=[...selected, ...response];
            
            this.setState({
                loading: false,
                selected: selected,
            });
        }).catch((error) => {
            console.error(error);
            errorPopper(error, tr('file_read_error'));
            this.setState({
                loading: false,
            });
        });
    }

    renderEditModal()
    {
        const sellers = this.state.wholesales.map(i => { return {value: i.id, label: i.name} });
        
        return <ApModal
            show={ Boolean( this.state.editModal.show ) }
            handleClose={ () => this.setState({ editModal: {}, identifier:null}) }
            className="editModal"
            header={
                <div className="padding">
                    { tr('add_as_storage_components') }
                </div>
            }
            body={ <div className="padding">

                 <ApInput
                    label={ tr('gross_profit_percent') }
                    className="text-right"
                    value={ this.state.editTotalProfit ? this.state.editTotalProfit : '' }

                    onChange={ ( e ) => this.changeAllProfitPercent( e.target.value ) }
                    onBlur={ ( e ) => this.changeAllProfitPercent( e.target.value, true ) }
                />
                
                 <ApSelect
                 label={ tr('link_product_number_to_identifier') }
                 value={ this.state.identifier ? this.state.identifier : null }
                 optionRenderer={ identifier => 
                     <div className="identifierOptions">
                         <strong>{ identifier.name }</strong>
                     </div>
                 }

                 onChange={ ( c ) => this.setIdentifier( c ? c : null ) }
                 objKeyId="id"
                 objKeyValue="name"
                 clearable
                 apiUrl="storage/wholesale/identifiers"
                 apiData={{
                 }}
                />
                <h5>Excel</h5>
                <ApSelect
                    label={tr('supplier')}
                    value={this.state.seller ? this.state.seller.label : (sellers[0] ? sellers[0].label:"")}
                    options={sellers}
                    optionRenderer='label'
                    objKeyId="value"
                    objKeySearchable="label"
                    valueRenderer="label"
                    onChange={(edit => {
                        this.setState({ seller: edit });
                    })}
                    // clearable
                    loading={this.state.loading}
                    disabled={this.state.loading}
                />
                
                <div className="fileSelectContainer">
                    <ApInputStack gap="0">
                        <ApAddon width="300px" noRightBorder>
                            {tr('select_excel_file')}
                        </ApAddon>
                        <ApInput
                            type="file"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            id="selected_file"
                            name="selected_file"
                            label={`${tr('select_excel_file')}...`}
                            value={this.state.selected_file}
                            onChange={(file) => { this.setState({ selected_file: file }) }}
                            loading={this.state.loading}
                            disabled={this.state.loading}
                        />
                        <ApAddon noLeftBorder width="150px">
                            <ApTooltip block text={tr('read_data_excel')} position="bottom">
                                <div className="apSimpleButton" onClick={this.import}>
                                    {tr('read_file')}
                                </div>
                            </ApTooltip>
                        </ApAddon>
                    </ApInputStack>
                    <div className="apInfo small">
                        <SvgIcon icon="info-circle" type="solid" />
                        {tr('wholesale_component_excel_info')}

                    </div>
                </div>
                <ApReactTable
                    columns={ this.editModalColumns() }
                    data={ this.state.selected }
                    loading={ this.state.loading }
                    defaultPageSize={ 10 }

                    rowActions={[
                        { label: tr('link_to_previous'), icon: "link", action: this.joinModalOpen },
                    ]}
                />
            </div> }
            footer={
                <div className="padding">
                    <ApButton  color="white" onClick={ this.editModalSave } loading={ this.state.loading }>
                        <SvgIcon icon="check" type="solid" />
                        OK
                    </ApButton>
                </div>
            }
        />
    }


    // ----------------------------------------
    //  Code modal
    // ----------------------------------------

    codeModalOpen( index )
    {
        //console.log( 'open', index );
        this.setState({ codeModal: {
            show: true,
        } })
    }

    codeModalChange( code, tail )
    {
        //console.log( code, tail );

        this.setState({
            code: code,
            codeTail: tail,
        })
    }

    codeModalSubmit()
    {
        const code = this.state.code;
        const codeTail = this.state.codeTail;
        //console.log( 'codeModalSubmit', code, codeTail );
    }

    renderCodeModal()
    {
        return <ApModal
            show={ Boolean( this.state.codeModal.show ) }
            handleClose={ () => this.setState({ codeModal: {} }) }
            className="narrow codeModal"
            header={
                <div className="padding">
                    { tr('define_code') }
                </div>
            }
            body={ <div className="padding">
                <CodeSelect
                    componentId={ null }
                    skipped={ this.state.codeSkipped }
                    tail={ this.state.codeTail }
                    code={ this.state.code  }

                    codeLimit={ this.state.codeLimit  }
                    codeLevelCount={ this.state.codeLevelCount  }
                    codeSeparator={ this.state.codeSeparator }

                    onSkippedChange={ () => { this.setState({codeSkipped: !this.state.codeSkipped }) } }
                    onChange={ this.codeModalChange }

                    afterTailCheck={ ( exists ) => { this.setState({ codeTailExists: exists }) } }
                    //validationState={ this.validator.getState( 'code' ) }
                    //tailValidationState={ this.validator.getState( 'codeTail' ) }
                />
            </div> }
            footer={
                <div className="padding">
                    <ApButton  color="white" onClick={ this.codeModalSubmit }>
                        <SvgIcon icon="check" type="solid" />
                        OK
                    </ApButton>
                </div>
            }
        />
    }

    // ----------------------------------------
    //  Join modal
    // ----------------------------------------

    joinModalOpen( id, closeFunc )
    {
        const index = this.state.selected.findIndex(  i => i.id === id );
        this.setState({
            joinModal: { show: true },
            selectedComponent: null,
            selectedIndex: index,
        });
        closeFunc();
    }

    joinModalSubmit()
    {
        const index = this.state.selectedIndex;
        const component = this.state.selectedComponent;
        let selected = this.state.selected.slice( 0 );
        selected[ index ].saveComponent = component;
        this.setState({ selected: selected });

        this.setState({ joinModal: {} });

    }

    renderJoinModal()
    {
        const details = this.state.selected[ this.state.selectedIndex ];
        let showDetailDom = null;
        if( details )
        {

            const detailOptions = [
                { value: 'nameFull', label: tr('full_name') },
                { value: 'name', label: tr('name_start') },
                { value: 'name2', label: tr('name_end') },
                { value: 'saveName', label: tr('edited_name') },
                { value: 'number', label: tr('product_number') },
                { value: 'ean', label: 'EAN' },
            ];

            let value = '';
            if( this.state.joinDetail === 'nameFull' )
                value = `${ details.name } ${ details.name2 }`;
            else
                value = details[ this.state.joinDetail ];

            showDetailDom  = <ApInputStack gap="0">
                <ApInput
                    type="select"
                    options={ detailOptions }
                    id="showDetail"
                    name="showDetail"
                    value={ this.state.joinDetail }
                    onChange={ (e) => { this.setState({ joinDetail: e.target.value }) } }
                />
                <ApInput
                    value={ value }
                    readOnly
                />
            </ApInputStack>


        }

        return <ApModal
            show={ Boolean( this.state.joinModal.show ) }
            handleClose={ () => this.setState({ joinModal: {} }) }
            className="narrow codeModal"
            header={
                <div className="padding">
                    { tr('combine_with_storage_component') }
                </div>
            }
            body={ <div className="padding">
                { showDetailDom }
                <ApSelect
                    label={ tr('search_storage_component') }
                    loading={ this.state.loading }
                    value={ this.state.selectedComponent }
                    optionRenderer="component_detail"
                    onChange={ ( s ) => this.setState({ selectedComponent: s }) }
                    clearable

                    objKeyId="id"
                    objKeyValue="name"
                    apiUrl="storage/components"
                    apiData={{
                        formatter: 'instance',
                        type: getComponentTypeId( 'item' ),
                        status: 'all',
                    }}
                />
            </div> }
            footer={
                <div className="padding text-right">
                    <ApButton  color="white" onClick={ this.joinModalSubmit }>
                        <SvgIcon icon="check" type="solid" />
                        OK
                    </ApButton>
                </div>
            }
        />
    }

    // ----------------------------------------
    //  Render
    // ----------------------------------------

    renderFooter( project )
    {
        let saveButton = <ApButton
            color="blue"
            onClick={this.editModalOpen}
            loading={this.state.loading}
            disabled={this.state.loading} //|| !this.state.selected.length }
        >
            <SvgIcon icon="cubes" type="solid" />
            { tr('add_as_storage_components') }
        </ApButton>

        return <div className="saveBar">
            { saveButton }
        </div>
    }

    onCEditSave()
    {
        this.getData();
        this.setState({ cEditModal: {}});
    }

    openCEdit( id )
    {
        this.setState({ cEditModal: {
            show: true,
            id: id,
        }});
    }

    render()
    {
        return <div id="storageWholesaleComponents" className="padding">
            <ApFormPage
                onSave={ this.saveCompany }
                loading={ this.state.loading }
                error={ this.state.errorMsg }
                unsaved={ this.state.selected.length }
                customFooter={ () => this.renderFooter() }
                noPrompt={ nextUrl => {
                    return nextUrl.startsWith( `/projects/${ this.props.projectId }/management` );
                }}


            >
                <ApReactTable
                    columns={ this.mainColumns() }
                    data={ this.state.data }
                    loading={ this.state.loading }

                    manual
                    onFetchData={ this.getData }
                    pages={ this.state.pages }

                    filterable
                    showFiltersInitially={ true }

                    customRowClass={ this.mainCustomRowClass }

                    rowActions={ (row) => {
                        if( row.component )
                            return <div className="apSimpleButton" onClick={ () => this.openCEdit( row.component.id ) }><SvgIcon icon="edit" type="solid" /></div>
                        return null;
                    }}

                    defaultSorted={[
                        { id: 'wholesale_id', desc: false },
                        { id: 'category_id', desc: false },
                        { id: 'name_full', desc: false },
                    ]}
                />
            </ApFormPage>
            { this.renderEditModal() }
            { this.renderCodeModal() }
            { this.renderJoinModal() }

            <ComponentEdit
                id={ this.state.cEditModal.id }
                show={ Boolean( this.state.cEditModal.show ) }
                onClose={ () => { this.setState({ cEditModal: {} }) } }
                onSave={ this.onCEditSave }
            />

        </div>
    }
}

//<div onClick={ this.debug }>{ JSON.stringify( this.state.selected ) }</div>
export default Wholesale;
