import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import ApSelect from 'common/ApSelect/ApSelect';
// import { countryCodes } from 'services/Helpers/Helpers';
import { tr } from 'services/Helpers/Helpers.js';
import api from "services/Api/Api";



class ApCountrySelect extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedCountry: null,
            countries: []
        }

        autoBind(this);
    }

    componentDidMount() {
        // document.addEventListener('mousedown', this.clickOutsideHandler);
        this.fetchData();

    }

    componentWillUnmount() {
        // document.removeEventListener('mousedown', this.clickOutsideHandler);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.country_code_id != this.props.country_code_id) {
            let selectedCountry = this.state.countries.find(row => row.id == this.props.country_code_id);

            this.setState({
                selectedCountry: selectedCountry
            }, () => {
                if (typeof this.props.onChange == 'function') {
                    this.props.onChange(selectedCountry);
                }
            });
        }
    }

    getCountryById(id) {
        return this.state.countries.find(row => row.id == id);
    }

    fetchData = () => {
        if (!this.props.options) {

            try {
                api({
                    url: 'countries/all',
                    method: 'get',

                }).then((response) => {

                    response = response.map((row) => {
                        row.name = tr(row.code);
                        return row;
                    })


                    // let selectedCountry = response.find(row=>row.id == this.props.country_code_id);
                    if (this.props.country_code_id) {
                        let selectedCountry = response.find(row => row.id == this.props.country_code_id);
                        this.setState({
                            selectedCountry: selectedCountry

                        });
                    }

                    this.setState({

                        countries: response,
                        // selectedCountry :selectedCountry

                    });

                }).catch(error => {

                    console.error(error);
                });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        else {
            if (this.props.country_code_id) {
                let selectedCountry = this.props.options.find(row => row.id == this.props.country_code_id);
                this.setState({
                    selectedCountry: selectedCountry
                });
            }
            this.setState({
                countries: this.props.options, // selectedCountry :selectedCountry
            });
        }

    };


    getSelected() {

        return this.state.selectedCountry;
    }


    render() {
        const { countries } = this.state;
        const country_code_id = this.props.country_code_id;
        // let countries = this.getCountries();
        return (

            <>
                <ApSelect
                    objKeyId="id"
                    objKeyValue="name"
                    objKeySearchable={["name", "code"]}
                    keepFocusOnScroll={true}
                    label={tr('country')}
                    // value = { this.state.billing_country_code}
                    options={countries}
                    // value = {  this.state.selectedCountry }
                    value={this.state.selectedCountry}
                    valueRenderer={(item) => {
                        return (
                            <div >
                                {tr(item.code)}
                            </div>
                        )
                    }}

                    optionRenderer={(item) => {
                        return (
                            <div>
                                {item.name}
                            </div>
                        );
                    }}
                    onChange={
                        (item) => this.setState({
                            selectedCountry: item
                        },
                            () => {
                                if (typeof this.props.onChange == 'function') {
                                    this.props.onChange(item);
                                }
                            }
                        )

                    }
                    autoComplete='country-select-input'

                >
                </ApSelect>
            </>
        );
    }





};



ApCountrySelect.propTypes = {
    country_code_id: PropTypes.number,
    selectedCountry: PropTypes.object,
    onChange: PropTypes.func
};



export default ApCountrySelect;

