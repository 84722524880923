import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
import api from 'services/Api/Api.js';
import { matchSorter } from 'match-sorter';
import { tr, currentLang, setNested } from 'services/Helpers/Helpers.js';
import './MassChange.css';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import auth from 'services/Authed/Authed.js';
import ApReactTable, { colPreset } from 'common/ApReactTable/ApReactTable.js';
import ApButton from 'common/ApButton/ApButton';
import ApFormPage from 'common/ApFormPage/ApFormPage.js';
import ApSwitch from 'common/ApSwitch/ApSwitch';
import ApSelect from 'common/ApSelect/ApSelect.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApModal from 'common/ApModal/ApModal.js';


export default class CrmMassChange extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchLoading: false,
            loading: false,
            pages: -1,
            eventTypes: null,
            CrmEvents: [],
            crmList: [],
            showModifyModal: false,
            tableIndexes: [],
            modifyModalselect: 1,
            unsavedChanges:false,
        };
        autoBind(this);

    }

    componentDidMount() {
        this.getSettings();
        // this.getCrmData();
        // this.getCustomerData();
    }

    getSettings() {
        api({
            method: 'get',
            url: 'crm/company/settings',
        }).then((response) => {
            //console.log('Settings', response);
            let billing_methods = []
            for (let key in response.billing_methods) {
                billing_methods.push({ id: response.billing_methods[key], name: key });
            }
            response.billing_methods = billing_methods;
            this.setState({ settings: response });


            this.getCustomerData(response);
        }).catch((error) => {
            console.log('ERROR while fetching settings!');
        });
    }

    // getCrmData() {
    //     this.setState({ loading: true });
    //     api({
    //         method: 'get',
    //         url: 'crm/events',
    //     }).then((response) => {

    //         this.setState({ CrmEvents: response.events, loading: false })

    //     }).catch((error) => {
    //         console.log(error);
    //         this.setState({ loading: false })
    //         // TODO retry or fail? 
    //     });
    // }

    getCustomerData(settings) {
        this.setState({ loading: true });
        api({
            method: 'post',
            url: 'crm/company/getCrmMassChangeData',
            data: {
                //     page: state.page,
                //     pageSize: state.pageSize,
                //     sorted: state.sorted,
                //     filtered: state.filterable ? state.filtered : [],
                //     showPassive: this.state.showPassive,
            },
        }).then((response) => {
            // console.log( 'getCustomerData', response );

            for (let responseRow of response) {
                let default_billing_method;
                let default_tax;
                let default_tax_code;
                for (let row of settings.billing_methods) {
                    if (row.id === responseRow.default_billing_method) {
                        default_billing_method = row;
                        break;
                    }
                }
                for (let row of settings.taxes) {
                    if (row.id === responseRow.default_tax) {
                        default_tax = row;
                        break;
                    }
                }
                for (let row of Object.values(settings.tax_codes)) {
                    if (row.id === responseRow.default_tax_code) {
                        default_tax_code = row;
                        break;
                    }
                }
                responseRow.default_billing_method = default_billing_method;
                responseRow.default_tax = default_tax;
                responseRow.default_tax_code = default_tax_code;

            }


            this.setState({
                crmList: response,

                loading: false,
            });

        }).catch((error) => {
            console.error(error);
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>{tr('get_error')}</strong>,
            });
            this.setState({
                customers: [],
                pages: -1,
                loading: false,
            })
        });
    }

    save() {
        this.setState({ loading: true });
        let data = this.state.crmList.filter((dataRow) => dataRow.valueChanged);


        api({
            method: 'post',
            url: 'crm/company/saveCrmMassChange',
            data: {
                data: data
            },
        }).then((response) => {
            //console.log( 'getCustomerData', response );

            let data = this.state.crmList.slice();
            data = data.map((dataRow) => {
                dataRow.valueChanged = false;
                return dataRow;
            });
            this.setState({ crmList: data, loading: false, unsavedChanges: false, });


        }).catch((error) => {
            console.error(error);
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>{tr('get_error')}</strong>,
            });
            this.setState({
                customers: [],
                pages: -1,
                loading: false,
            })
        });
    }

    getCrmManagerList() {
        let options = [<option key="all" value="all" style={{ color: 'black' }}>- {tr('all')} -</option>];
        this.state.CrmEvents.forEach(event => {
            let duplicate = options.find(item => item.props.value === event.user_id);
            if (!duplicate) {
                options.push(<option key={event.user_id} value={event.user_id} style={{ color: 'black' }}>{event.client_user}</option>);
            }
        })
        return options;
    }

    getCrmEventList() {
        let options = [{ value: '', label: `- ${tr('all')} -` }];
        for (let event in this.state.eventTypes) {
            options.push({ value: event, label: this.state.eventTypes[event] })
        }
        return options;
    }

    showCompanyCrm(company) {
        if ("crm_company_id" in company)
            window.emitter.emit('goTo', { pathname: '/crm/company/' + company.crm_company_id + '/events' });
    }

    changeValue(id, variableName, value, props = {}) {
        // console.log(id, variableName, value);
        let crmList = this.state.crmList.slice();

        crmList = crmList.map((row) => {
            if (row.id === id) {
                row = setNested(row, variableName, value);
                row.valueChanged = true;
                Object.assign(row, props.additionalChanges);
            }
            return row;
        });
        this.setState({
            crmList: crmList,
            unsavedChanges: true
        });
    }






    getColumns() {
        const columns = [
            {
                id: 'crm_company.name',
                Header: tr('name'),
                customizable: true,
                showInitially: true,
                Cell: (props) => {
                    return this.props.renderApInput(props, "name", this.changeValue);

                },
                accessor: (props) => {
                    return props.name;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.name",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('name')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            },
            {
                id: 'crm_company.taxnumber',
                Header: tr('company_taxnumber'),
                customizable: true,
                showInitially: true,
                // onClick: this.showCompanyCrm,
                Cell: (props) => {
                    return this.props.renderApInput(props, "taxnumber", this.changeValue, { tr: 'company_taxnumber' });

                },
                accessor: (props) => {
                    return props.taxnumber;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.taxnumber",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('company_taxnumber')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            },
            {
                id: 'crm_company.active',
                Header: tr('active'),
                customizable: true,
                showInitially: true,
                Cell: (props) => {
                    return this.props.renderApInput(props, "is_active", this.changeValue, { type: 'switch' });
                    //     <ApInput
                    //         type="text"
                    //         id="name"
                    //         name="name"
                    //         label={tr('name')}
                    //         value={props.original.name}
                    //         onChange={(data) => this.changeValue(props.original.id, "name", data.target.value)}
                    //         loading={this.state.loading}
                    //         disabled={this.state.loading}
                    //         readOnly={!auth.hasModule("clients.edit")}
                    //     />
                    // );
                },
                accessor: (props) => {
                    return props.is_active;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.is_active",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    // return (
                    //     <input
                    //         placeholder={tr('enter_name_business_id')}
                    //         onChange={event => onChange(event.target.value)}
                    //         value={filter ? filter.value : ''}
                    //         style={{
                    //             width: '100%',
                    //             backgroundColor: 'lightgray',
                    //             color: 'black',
                    //         }}
                    //     />
                    // )
                }
            },
            {
                id: 'crm_company.extranet_active',
                Header: tr('extranet_active'),
                customizable: true,
                showInitially: true,
                Cell: (props) => {
                    return this.props.renderApInput(props, "extranet_active", this.changeValue, { type: 'switch' });

                },
                accessor: (props) => {
                    return props.extranet_active;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.extranet_active",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    // return (
                    //     <input
                    //         placeholder={tr('enter_name_business_id')}
                    //         onChange={event => onChange(event.target.value)}
                    //         value={filter ? filter.value : ''}
                    //         style={{
                    //             width: '100%',
                    //             backgroundColor: 'lightgray',
                    //             color: 'black',
                    //         }}
                    //     />
                    // )
                }
            },
            {
                id: 'crm_company.is_customer',
                Header: tr('extranet_customer_active'),
                customizable: true,
                showInitially: true,
                Cell: (props) => {
                    return this.props.renderApInput(props, "is_customer", this.changeValue, { type: 'switch', tr: 'extranet_customer_active' });

                },
                accessor: (props) => {
                    return props.is_customer;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.is_customer",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    // return (
                    //     <input
                    //         placeholder={tr('enter_name_business_id')}
                    //         onChange={event => onChange(event.target.value)}
                    //         value={filter ? filter.value : ''}
                    //         style={{
                    //             width: '100%',
                    //             backgroundColor: 'lightgray',
                    //             color: 'black',
                    //         }}
                    //     />
                    // )
                }
            },
            {
                id: 'crm_company.is_supplier',
                Header: tr('extranet_supplier_active'),
                customizable: true,
                showInitially: true,
                Cell: (props) => {
                    return this.props.renderApInput(props, "is_supplier", this.changeValue, { type: 'switch', tr: 'extranet_supplier_active' });

                },
                accessor: (props) => {
                    return props.is_supplier;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.is_supplier",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    // return (
                    //     <input
                    //         placeholder={tr('enter_name_business_id')}
                    //         onChange={event => onChange(event.target.value)}
                    //         value={filter ? filter.value : ''}
                    //         style={{
                    //             width: '100%',
                    //             backgroundColor: 'lightgray',
                    //             color: 'black',
                    //         }}
                    //     />
                    // )
                }
            },
            {
                id: 'crm_company.no_mailing',
                Header: tr('no_customer_mailing'),
                customizable: true,
                showInitially: false,
                Cell: (props) => {
                    return this.props.renderApInput(props, "no_mailing", this.changeValue, { type: 'switch', tr: 'no_customer_mailing' });

                },
                accessor: (props) => {
                    return props.no_mailing;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.no_mailing",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    // return (
                    //     <input
                    //         placeholder={tr('enter_name_business_id')}
                    //         onChange={event => onChange(event.target.value)}
                    //         value={filter ? filter.value : ''}
                    //         style={{
                    //             width: '100%',
                    //             backgroundColor: 'lightgray',
                    //             color: 'black',
                    //         }}
                    //     />
                    // )
                }
            },
            {
                id: 'crm_company.phone',
                Header: tr('phone'),
                customizable: true,
                showInitially: true,
                Cell: (props) => {
                    return this.props.renderApInput(props, "phone", this.changeValue);

                },
                accessor: (props) => {
                    return props.phone;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.phone",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('phone')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            },
            {
                id: 'crm_company.email',
                Header: tr('email'),
                customizable: true,
                showInitially: false,
                Cell: (props) => {
                    return this.props.renderApInput(props, "email", this.changeValue);

                },
                accessor: (props) => {
                    return props.email;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.email",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('email')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            },
            {
                id: 'crm_company.website',
                Header: tr('website'),
                customizable: true,
                showInitially: false,
                Cell: (props) => {
                    return this.props.renderApInput(props, "website", this.changeValue);

                },
                accessor: (props) => {
                    return props.website;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.website",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('website')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            },
            {
                id: 'crm_company.language_id',
                Header: tr('business_language'),
                customizable: true,
                showInitially: false,
                Cell: (props) => {
                    return this.props.renderApInput(props, "language_id", this.changeValue, { tr: 'business_language', type: 'language' });
                },
                accessor: (props) => {
                    return props.language_id;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.language_id",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    // return (
                    //     <input
                    //         placeholder={tr('enter_name_business_id')}
                    //         onChange={event => onChange(event.target.value)}
                    //         value={filter ? filter.value : ''}
                    //         style={{
                    //             width: '100%',
                    //             backgroundColor: 'lightgray',
                    //             color: 'black',
                    //         }}
                    //     />
                    // )
                }
            },
            // {
            //     id: 'crm_company.industry',
            //     Header: tr('industry'),
            //     customizable: true,
            //     showInitially: false,
            //     Cell: (props) => {
            //         return this.props.renderApInput(props, "name", this.changeValue, { tr: 'industry' });

            //     },
            //     accessor: (props) => {
            //         return props.name;
            //     },
            //     filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
            //         keys: [
            //             "_original.name",
            //         ],
            //            threshold: matchSorter.rankings.ACRONYM,
            //     }),
            //     filterAll: true,
            //     Filter: ({ filter, onChange }) => {
            //         return (
            //             <input
            //                 placeholder={tr('enter_name_business_id')}
            //                 onChange={event => onChange(event.target.value)}
            //                 value={filter ? filter.value : ''}
            //                 style={{
            //                     width: '100%',
            //                     backgroundColor: 'lightgray',
            //                     color: 'black',
            //                 }}
            //             />
            //         )
            //     }
            // },
            {
                id: 'crm_company.groups',
                Header: tr('groups'),
                customizable: true,
                showInitially: false,
                Cell: (props) => {
                    return this.props.renderApInput(props, "groups", this.changeValue, { tr: 'groups', type: 'groups' });

                },
                accessor: (props) => {
                    return props.groups;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.groups",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    // return (
                    //     <input
                    //         placeholder={tr('enter_name_business_id')}
                    //         onChange={event => onChange(event.target.value)}
                    //         value={filter ? filter.value : ''}
                    //         style={{
                    //             width: '100%',
                    //             backgroundColor: 'lightgray',
                    //             color: 'black',
                    //         }}
                    //     />
                    // )
                }
            },
            {
                id: 'crm_company.turnover',
                Header: tr('turnover'),
                customizable: true,
                showInitially: false,
                Cell: (props) => {
                    return this.props.renderApInput(props, "turnover", this.changeValue);

                },
                accessor: (props) => {
                    return props.turnover;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.turnover",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('turnover')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            },
            {
                id: 'crm_company.personnel',
                Header: tr('personnel'),
                customizable: true,
                showInitially: false,
                Cell: (props) => {
                    return this.props.renderApInput(props, 'employs', this.changeValue, { tr: "personnel" });

                },
                accessor: (props) => {
                    return props.employs;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.employs",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('personnel')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            },
            {
                id: 'crm_company.edi_id',
                Header: tr('edi_id'),
                customizable: true,
                showInitially: false,
                Cell: (props) => {
                    return this.props.renderApInput(props, "e_invoice_ovt", this.changeValue, { tr: 'edi_id' });

                },
                accessor: (props) => {
                    return props.e_invoice_ovt;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.e_invoice_ovt",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('edi_id')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            },
            {
                id: 'crm_company.e_invoice_address',
                Header: tr('e_invoice_address'),
                customizable: true,
                showInitially: false,
                Cell: (props) => {
                    return this.props.renderApInput(props, "e_invoice_address", this.changeValue);

                },
                accessor: (props) => {
                    return props.e_invoice_address;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.e_invoice_address",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('e_invoice_address')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            },
            {
                id: 'crm_company.e_invoice_operator',
                Header: tr('e_invoice_operator_id'),
                customizable: true,
                showInitially: false,
                Cell: (props) => {
                    return this.props.renderApInput(props, "e_invoice_operator", this.changeValue, { tr: 'e_invoice_operator_id' });

                },
                accessor: (props) => {
                    return props.e_invoice_operator;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.e_invoice_operator",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('e_invoice_operator')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            },
            {
                id: 'crm_company.default_payment_term',
                Header: tr('default_payment_term'),
                customizable: true,
                showInitially: false,
                Cell: (props) => {
                    return this.props.renderApInput(props, "default_payment_condition", this.changeValue, { tr: 'default_payment_term', type: 'defaultPaymentCondition' });
                    //     <ApInput
                    //         type="text"
                    //         id="name"
                    //         name="name"
                    //         label={tr('name')}
                    //         value={props.original.name}
                    //         onChange={(data) => this.changeValue(props.original.id, "name", data.target.value)}
                    //         loading={this.state.loading}
                    //         disabled={this.state.loading}
                    //         readOnly={!auth.hasModule("clients.edit")}
                    //     />
                    // );
                },
                accessor: (props) => {
                    return props.default_payment_term?.name;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.default_payment_term.name",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    // return (
                    //     <input
                    //         placeholder={tr('default_payment_term')}
                    //         onChange={event => onChange(event.target.value)}
                    //         value={filter ? filter.value : ''}
                    //         style={{
                    //             width: '100%',
                    //             backgroundColor: 'lightgray',
                    //             color: 'black',
                    //         }}
                    //     />
                    // )
                }
            },
            {
                id: 'crm_company.default_delivery_term',
                Header: tr('default_delivery_term'),
                customizable: true,
                showInitially: false,
                Cell: (props) => {
                    return this.props.renderApInput(props, "default_delivery_term", this.changeValue, { type: 'default_delivery_term' });

                },
                accessor: (props) => {
                    return props.name;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.default_delivery_term.name",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    // return (
                    //     <input
                    //         placeholder={tr('enter_name_business_id')}
                    //         onChange={event => onChange(event.target.value)}
                    //         value={filter ? filter.value : ''}
                    //         style={{
                    //             width: '100%',
                    //             backgroundColor: 'lightgray',
                    //             color: 'black',
                    //         }}
                    //     />
                    // )
                }
            },
            {
                id: 'crm_company.default_delivery_method',
                Header: tr('default_delivery_method'),
                customizable: true,
                showInitially: false,
                Cell: (props) => {
                    return this.props.renderApInput(props, "default_delivery_method", this.changeValue, { type: 'default_delivery_method' });

                },
                accessor: (props) => {
                    return props.name;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.name",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    // return (
                    //     <input
                    //         placeholder={tr('enter_name_business_id')}
                    //         onChange={event => onChange(event.target.value)}
                    //         value={filter ? filter.value : ''}
                    //         style={{
                    //             width: '100%',
                    //             backgroundColor: 'lightgray',
                    //             color: 'black',
                    //         }}
                    //     />
                    // )
                }
            },
            {
                id: 'crm_company.default_billing_method',
                Header: tr('default_billing_method'),
                customizable: true,
                showInitially: false,
                Cell: (props) => {
                    return this.props.renderApInput(props, "default_billing_method", this.changeValue, { type: 'default_billing_method' });

                },
                accessor: (props) => {
                    return props.name;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.name",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    // return (
                    //     <input
                    //         placeholder={tr('enter_name_business_id')}
                    //         onChange={event => onChange(event.target.value)}
                    //         value={filter ? filter.value : ''}
                    //         style={{
                    //             width: '100%',
                    //             backgroundColor: 'lightgray',
                    //             color: 'black',
                    //         }}
                    //     />
                    // )
                }
            },
            // {
            //     id: 'crm_company.order_grouping_type',
            //     Header: tr('order_grouping_type'),
            //     customizable: true,
            //     showInitially: false,
            //     Cell: (props) => {
            //         return this.props.renderApInput(props, "name", this.changeValue);

            //     },
            //     accessor: (props) => {
            //         return props.name;
            //     },
            //     filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
            //         keys: [
            //             "_original.name",
            //         ],
            //            threshold: matchSorter.rankings.ACRONYM,
            //     }),
            //     filterAll: true,
            //     Filter: ({ filter, onChange }) => {
            //         // return (
            //         //     <input
            //         //         placeholder={tr('enter_name_business_id')}
            //         //         onChange={event => onChange(event.target.value)}
            //         //         value={filter ? filter.value : ''}
            //         //         style={{
            //         //             width: '100%',
            //         //             backgroundColor: 'lightgray',
            //         //             color: 'black',
            //         //         }}
            //         //     />
            //         // )
            //     }
            // },
            



            // {
            //     id: 'action',
            //     Header: tr('actions'),
            //     accessor: "action",
            //     Cell: (props) => {
            //         return (props.original.action ?
            //             <span>{props.original.action}</span> :
            //             <span className="empty">-</span>
            //         );
            //     },
            //     customizable: true,
            //     sortable: false,
            //     showInitially: true,
            //     filterable: false,
            // },
        ];

        if (this.state.settigns?.use_tax_groups) {
            columns.push({
                id: 'crm_company.default_tax',
                Header: tr('default_vat'),
                customizable: true,
                showInitially: false,
                Cell: (props) => {
                    return this.props.renderApInput(props, "default_tax", this.changeValue, { tr: 'default_vat', type: 'default_tax' });

                },
                accessor: (props) => {
                    return props.default_tax?.value;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.default_tax.value",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('default_vat')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            });
            columns.push({
                id: 'crm_company.default_tax_code',
                Header: tr('default_vat_code'),
                customizable: true,
                showInitially: false,
                className: 'overflowable',
                Cell: (props) => {
                    return this.props.renderApInput(props, "default_tax_code", this.changeValue, { type: 'default_tax_code', tr: 'default_vat_code' });

                },
                accessor: (props) => {
                    return props.default_tax_code;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.default_tax_code",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    // return (
                    //     <input
                    //         placeholder={tr('enter_name_business_id')}
                    //         onChange={event => onChange(event.target.value)}
                    //         value={filter ? filter.value : ''}
                    //         style={{
                    //             width: '100%',
                    //             backgroundColor: 'lightgray',
                    //             color: 'black',
                    //         }}
                    //     />
                    // )
                }
            });
        }
        else {
            columns.push({
                id: 'crm_company.default_item_tax_group',
                Header: tr('component_tax_group'),
                customizable: true,
                showInitially: false,
                className: 'overflowable',
                Cell: (props) => {
                    return this.props.renderApInput(props, "default_item_tax_group", this.changeValue,
                        { type: 'tax_group', tr: 'component_tax_group', IdToAdditionalChanges: 'default_item_tax_group_id' });

                },
                accessor: (props) => {
                    return props?.default_item_tax_group?.name;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.default_item_tax_group.account",
                        "_original.default_item_tax_group.name",
                        "_original.default_item_tax_group.tax_code",
                        "_original.default_item_tax_group.vat.value",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('component_tax_group')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            });
            columns.push({
                id: 'crm_company.default_work_tax_group',
                Header: tr('work_tax_group'),
                customizable: true,
                showInitially: false,
                className: 'overflowable',
                Cell: (props) => {
                    return this.props.renderApInput(props, "default_work_tax_group", this.changeValue,
                        { type: 'tax_group', tr: 'work_tax_group', IdToAdditionalChanges: 'default_work_tax_group_id' });

                },
                accessor: (props) => {
                    return props?.default_work_tax_group?.name;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.default_work_tax_group.account",
                        "_original.default_work_tax_group.name",
                        "_original.default_work_tax_group.tax_code",
                        "_original.default_work_tax_group.vat.value",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('work_tax_group')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            });
            columns.push({
                id: 'crm_company.default_expence_tax_group',
                Header: tr('expense_tax_group'),
                customizable: true,
                showInitially: false,
                className: 'overflowable',
                Cell: (props) => {
                    return this.props.renderApInput(props, "default_expence_tax_group", this.changeValue,
                        { type: 'tax_group', tr: 'expense_tax_group', IdToAdditionalChanges: 'default_expence_tax_group_id' });

                },
                accessor: (props) => {
                    return props?.default_expence_tax_group?.name;

                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.default_work_tax_group.account",
                        "_original.default_work_tax_group.name",
                        "_original.default_work_tax_group.tax_code",
                        "_original.default_work_tax_group.vat.value",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('expense_tax_group')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            });
        }


        if (this.state.settings?.cost_center_enabled) {
            columns.push({
                id: 'crm_company.cost_center',
                Header: tr('cost_center'),
                customizable: true,
                showInitially: false,
                className: 'overflowable',
                Cell: (props) => {
                    return this.props.renderApInput(props, "cost_center", this.changeValue, { type: 'cost_center', tr: 'cost_center' });

                },
                accessor: (props) => {
                    return props.cost_center;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.cost_center.full_name",
                        "_original.cost_center.code",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('cost_center')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            });
        }

        return columns
    }

    getModifyOptions() {

        let options = [];

        options.push({ value: 1, label: tr('active') });
        options.push({ value: 2, label: tr('extranet_active') });
        options.push({ value: 3, label: tr('extranet_customer_active') });
        options.push({ value: 4, label: tr('extranet_supplier_active') });
        options.push({ value: 5, label: tr('no_customer_mailing') });



        options.push({ value: 10, label: tr('default_payment_term') });
        options.push({ value: 11, label: tr('default_delivery_term') });
        options.push({ value: 12, label: tr('default_delivery_method') });
        options.push({ value: 13, label: tr('default_billing_method') });
        // options.push({ value: 14, label: tr('order_grouping_type') });
        options.push({ value: 15, label: tr('default_vat') });
        options.push({ value: 16, label: tr('default_vat_code') });
        options.push({ value: 17, label: tr('cost_center') });

        options.push({ value: 30, label: tr('add_group') });
        options.push({ value: 40, label: tr('remove_group') });

        return options;
    }

    renderSwitch() {
        let option = this.getModifyOptions().find(row => row.value == this.state.modifyModalselect);
        return <div className="apSwitchBlock">
            <label htmlFor="value-switch" className="info">
                {option?.label}
                {/* <small>{tr('sent_po_manually_info')}</small> */}
            </label>
            <ApSwitch
                id={'value-switch'}
                on={this.state.modifyValue}
                onChange={(data) => this.setState({ modifyValue: !this.state.modifyValue })}
                disabled={this.state.loading}
                loading={this.state.loading}
            />
        </div>

    }

    closeModifyModal(save = false) {
        // console.log(save);
        // console.log(this.state.modifyModalselect);
        if (save) {
            let modifyModalselect = this.state.modifyModalselect;
            if (modifyModalselect <= '5') {

                let selectedParameter = null;
                if (this.state.modifyModalselect == 1) {
                    selectedParameter = "is_active";
                }
                if (this.state.modifyModalselect == 2) {
                    selectedParameter = "extranet_active";
                }
                if (this.state.modifyModalselect == 3) {
                    selectedParameter = "is_customer";
                }
                if (this.state.modifyModalselect == 4) {
                    selectedParameter = "is_supplier";
                }
                if (this.state.modifyModalselect == 5) {
                    selectedParameter = "no_mailing";
                }

                let tableIndexes = this.state.tableIndexes;
                let data = this.state.crmList.slice();
                data = data.map((dataRow, index) => {
                    if (tableIndexes.includes(index)) {
                        dataRow[selectedParameter] = this.state.modifyValue;
                        dataRow.valueChanged = true;

                    }
                    return dataRow;
                });
                this.setState({ crmList: data, unsavedChanges: true });
            }
            if (modifyModalselect >= '30' && modifyModalselect <= '40') {
                let add = true;
                if (modifyModalselect == '40') {
                    add = false;
                }

                let tableIndexes = this.state.tableIndexes;
                let data = this.state.crmList.slice();
                data = data.map((dataRow, index) => {
                    if (tableIndexes.includes(index)) {
                        let groups = dataRow['groups'];

                        if (add) {
                            for (let rowModifyValue of this.state.modifyValue) {
                                let notExist = groups.find(item => item.id === rowModifyValue.id) == undefined;
                                if (notExist) {
                                    groups.push(rowModifyValue);
                                }
                            }
                        }
                        else {
                            for (let rowModifyValue of this.state.modifyValue) {
                                groups = groups.filter((row) => rowModifyValue.id != row.id);
                            }
                        }
                        dataRow['groups'] = groups;
                        dataRow.valueChanged = true;

                    }
                    return dataRow;
                });
                this.setState({ crmList: data, unsavedChanges: true });




            }

            if (modifyModalselect >= '10' && modifyModalselect <= '20') {

                let NewData = null;

                NewData = this.state.modifyValue;

                let tableIndexes = this.state.tableIndexes;
                let data = this.state.crmList.slice();
                data = data.map((dataRow, index) => {
                    if (tableIndexes.includes(index)) {

                        if (this.state.modifyModalselect == 10) {
                            dataRow['default_payment_condition'] = this.state.modifyValue;
                            dataRow['default_payment_condition_id'] = this.state.modifyValue?.id;
                        }

                        if (this.state.modifyModalselect == 11) {
                            dataRow['default_delivery_term'] = this.state.modifyValue;
                            dataRow['default_delivery_term_id'] = this.state.modifyValue?.id;
                        }
                        if (this.state.modifyModalselect == 12) {
                            dataRow['default_delivery_method'] = this.state.modifyValue;
                            dataRow['default_delivery_method_id'] = this.state.modifyValue?.id;
                        }
                        if (this.state.modifyModalselect == 13) {
                            dataRow['default_billing_method'] = this.state.modifyValue;
                            dataRow['default_billing_method_id'] = this.state.modifyValue?.id;
                        }
                        // if (this.state.modifyModalselect == 14) {
                        //     dataRow[selectedParameter] = this.state.modifyValue;
                        // }
                        if (this.state.modifyModalselect == 15) {
                            dataRow['default_tax'] = this.state.modifyValue;
                            dataRow['default_tax_id'] = this.state.modifyValue?.id;
                        }
                        if (this.state.modifyModalselect == 16) {
                            dataRow['default_tax_code'] = this.state.modifyValue;
                            dataRow['default_tax_code_id'] = this.state.modifyValue?.id;
                        }
                        if (this.state.modifyModalselect == 17) {
                            dataRow['cost_center'] = this.state.modifyValue;
                            dataRow['cost_center_id'] = this.state.modifyValue?.id;
                            dataRow['cost_center_group_id'] = this.state.modifyValue?.group_id;
                        }

                        dataRow.valueChanged = true;

                    }
                    return dataRow;
                });
                this.setState({ crmList: data, unsavedChanges: true });






            }





        }
        this.setState({ modifyValue: false, showModifyModal: false, tableIndexes: [] });
    }

    openModifyModal(tableIndexes) {
        this.setState({ tableIndexes: tableIndexes });
        this.setState({ modifyValue: false, showModifyModal: true });
    }
    rendershowGroups() {

        return this.props.renderApInput(this.state, "modifyGroupValues",
            (id, name, data) => { this.changeGroupValue(data) },
            { type: 'groups', tr: 'groups', getAll: true });

    }

    getRenderEditableInput() {
        switch (this.state.modifyModalselect) {
            case "1":
                return this.renderSwitch();
            case "2":
                return this.renderSwitch();
            case "3":
                return this.renderSwitch();
            case "4":
                return this.renderSwitch();
            case "5":
                return this.renderSwitch();

            case "10":
                return this.props.renderApInput(this.state,
                    "modifyValue",
                    (id, name, data) => {
                        this.setState({ modifyValue: data })
                    },
                    { tr: 'defaultPaymentCondition', type: 'defaultPaymentCondition', getAll: true, useDirect: true });

            case "11":
                return this.props.renderApInput(this.state,
                    "modifyValue",
                    (id, name, data) => {
                        this.setState({ modifyValue: data })
                    },
                    { tr: 'default_delivery_term', type: 'default_delivery_term', getAll: true, useDirect: true });
            case "12":
                return this.props.renderApInput(this.state,
                    "modifyValue",
                    (id, name, data) => {
                        this.setState({ modifyValue: data })
                    },
                    { tr: 'default_delivery_method', type: 'default_delivery_method', getAll: true, useDirect: true });
            case "13":
                return this.props.renderApInput(this.state,
                    "modifyValue",
                    (id, name, data) => {
                        this.setState({ modifyValue: data })
                    },
                    { tr: 'default_billing_method', type: 'default_billing_method', getAll: true, useDirect: true });
            // case "14":
            //     return this.props.renderApInput(this.state,
            //         "modifyValue",
            //         (id, name, data) => {
            //             this.setState({ modifyValue: data })
            //         },
            //         { tr: 'default_payment_term', type: 'default_delivery_term', getAll: true, useDirect: true });
            case "15":
                return this.props.renderApInput(this.state,
                    "modifyValue",
                    (id, name, data) => {
                        this.setState({ modifyValue: data })
                    },
                    { tr: 'default_tax', type: 'default_tax', getAll: true, useDirect: true });
            case "16":
                return this.props.renderApInput(this.state,
                    "modifyValue",
                    (id, name, data) => {
                        this.setState({ modifyValue: data })
                    },
                    { tr: 'default_tax_code', type: 'default_tax_code', getAll: true, useDirect: true });
            case "17":
                return this.props.renderApInput(this.state,
                    "modifyValue",
                    (id, name, data) => {
                        this.setState({ modifyValue: data })
                    },
                    { tr: 'default_payment_term', type: 'cost_center', getAll: true, useDirect: true });

            case "30":
                return this.props.renderApInput(this.state, "modifyValue",
                    (id, name, data) => {
                        this.setState({ modifyValue: data })
                    },
                    { type: 'groups', tr: 'groups', getAll: true, useDirect: true });

            case "40":
                return this.props.renderApInput(this.state, "modifyValue",
                    (id, name, data) => {
                        this.setState({ modifyValue: data })
                    },
                    { type: 'groups', tr: 'groups', getAll: true, useDirect: true });

            default:
                return this.renderSwitch();
        }
    }

    renderModifyModal() {

        return <ApModal
            show={this.state.showModifyModal}
            onOpen={() => { }}
            handleClose={() => this.closeModifyModal()}
            //closeFromBg
            className="narrow"
            header={
                <div className="padding">
                    <h4> {tr('edit')}</h4>
                </div>
            }
            body={<div className='padding'>
                {/* <ApInputStack> */}

                <ApInput
                    type="select"
                    options={this.getModifyOptions()}
                    id={"modalOption"}
                    name={"modalOption"}
                    value={this.state.modifyModalselect || 1}
                    onChange={(data) => this.setState({ modifyModalselect: data.target.value, modifyValue: null })}

                />

                {this.getRenderEditableInput()}

            </div>

            }
            footer={
                <div className="modalFooter">

                    <ApButton className="cancel" onClick={() => this.closeModifyModal()}>
                        <SvgIcon icon="times" type="solid" />
                        {tr('cancel')}
                    </ApButton>

                    <ApButton className="save" color="green" onClick={() => this.closeModifyModal(true)} >
                        <SvgIcon icon="check" type="solid" />
                        {tr('continue')}
                    </ApButton>

                </div>
            }
        />



    }

    render() {

        let multiselect = [];
        multiselect.push({
            icon: "edit",
            label: tr('modify_value'),
            action: (indexes) => { this.openModifyModal(indexes); },
            unselectAfter: true,
            // maxSelectedCount: 1,
        });
        // multiselect.push({
        //     icon: "edit",
        //     label: tr('add_group'),
        //     action: (indexes) => { this.setState({ tableIndexes: indexes }); },
        //     // unselectAfter: true,
        //     // maxSelectedCount: 1,
        // });
        // multiselect.push({
        //     icon: "edit",
        //     label: tr('remove_group'),
        //     action: (indexes) => { this.setState({ tableIndexes: indexes }); },
        //     // unselectAfter: true,
        //     // maxSelectedCount: 1,
        // });
        return (
            <div>
                <ApFormPage
                    onSave={this.save}
                    loading={this.state.loading}
                    unsaved={this.state.unsavedChanges}
                    error={this.state.errorMsg}
                // readOnly={!(auth.hasModule("clients.edit") || auth.hasModule("clients.events") || auth.hasModule("clients.extranet"))}
                // noPrompt={nextUrl => {
                //     return nextUrl.startsWith("/crm/company/" + this.state.data.id + "/");
                // }}
                >
                    {/* <div className="apBoxHeader">
                    <h1>{"text" }</h1>

                    

                    
                </div> */}
                    <ApReactTable
                        rememberId="crm_mass_change_list"
                        showFiltersInitially
                        loading={this.state.loading}
                        data={this.state.crmList}
                        // multiSelect


                        columns={[
                            {
                                customizable: true,
                                columns: this.getColumns(),
                            }
                        ]}
                        // defaultSorted={[
                        //     { id: "occurred_at", desc: true }
                        // ]}
                        filterable
                        // rowActions={(row) =>
                        //     <div className="apSimpleButton" onClick={() => this.showCompanyCrm(row)}><SvgIcon icon="arrow-right" type="solid" /></div>
                        // }
                        multiselect={multiselect}
                        minRows={5}
                    />



                </ApFormPage>

                {this.renderModifyModal()}
            </div>


        );
    }
}
