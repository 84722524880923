import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
import api from 'services/Api/Api.js';
import { matchSorter } from 'match-sorter';
import { tr, currentLang, keyExists, setNested } from 'services/Helpers/Helpers.js';
import './MassChange.css';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import {
    ApTabs,
    ApTab
} from 'common/ApTabs/ApTabs.js';
import CrmMassChange from './CrmMassChange';
import CrmContactMassChange from './CrmContactMassChange';
import CrmAdressesMassChange from './CrmAdressesMassChange';
import ApSwitch from 'common/ApSwitch/ApSwitch';
import ApSelect from 'common/ApSelect/ApSelect.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApCountrySelect from 'common/ApCountrySelect/ApCountrySelect.js';
import ApNestedSelect from 'common/ApNestedSelect/ApNestedSelect.js';

export default class MassChangeMain extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            settings: null,
            languageOptions: [],
            countries: [],
        };
        autoBind(this);

    }

    componentDidMount() {
        this.setState({ loading: true });
        this.getSettings();
        this.getLanguages();
        this.getCountries();
    }
    getSettings() {
        api({
            method: 'get',
            url: 'crm/company/settings',
        }).then((response) => {
            let billing_methods = []
            for (let key in response.billing_methods) {
                billing_methods.push({ id: response.billing_methods[key], name: key });
            }

            response.billing_methods = billing_methods;
            this.setState({ settings: response, loading: false });


        }).catch((error) => {
            console.log('ERROR while fetching settings!');
        });
    }
    getCountries() {
        api({
            url: 'countries/all',
            method: 'get',

        }).then((response) => {

            response = response.map((row) => {
                row.name = tr(row.code);
                return row;
            })



            this.setState({

                countries: response,
                // selectedCountry :selectedCountry

            });

        }).catch(error => {

            console.error(error);
        });
    }

    getLanguages() {
        api({
            method: 'get',
            url: 'languages/all'
        }).then((response) => {
            const languageOptions = [];
            response.forEach(lang => {
                if (lang.id === 1 || lang.id === 2 || lang.id === 3) {
                    languageOptions.push({
                        value: lang.id,
                        label: lang.name
                    });
                }
            });
            this.setState({ languageOptions: languageOptions });
        }).catch((error) => {
            console.log('Error while fetching languages.')
        });
    }

    getLanguageOptions() {
        const languages = keyExists(this.state.settings, "languages", true);

        let options = [];
        if (languages) {
            languages.forEach(lang => {
                options.push({ value: lang.id, label: lang.label });
            });
        }

        return options;
    }

    getContactGroupOptions(is_customer = true, is_supplier = true) {
        let options = keyExists(this.state.settings, "contactGroups", true, [])
        options = options.filter(option => (option.is_customer === true && is_customer) ||
            (option.is_supplier === true && is_supplier));
        return options;
    }

    getGroupOptions(is_customer = true, is_supplier = true) {
        let options = keyExists(this.state.settings, "groups", true, [])
        options = options.filter(option => (option.is_customer === true && is_customer) ||
            (option.is_supplier === true && is_supplier));
        return options;
    }

    renderTaxGroupValue(taxGroup) {
        if (!taxGroup) {
            return "";
        }
        let name = taxGroup.name || "";
        let tax = taxGroup.vat ? (taxGroup.vat.value + "%") : "";
        let account = taxGroup.account ? ("(" + taxGroup.account + ")") : "";
        let text = name + " " + tax + " " + account;
        return text
    }


    renderApInput(props, name, onChange, params = {}) {
        // params{tr,type}
        // let type = "text";
        let value = keyExists(params.useDirect ? props : props.original, name, true, null);
        // console.log(value);

        if (params.type == 'plain') {
            return <div style={{ overflow: "hidden" }}>{value}</div>
        }

        if (params.type == 'switch') {
            return <ApSwitch
                id={name + '-' + props.original.id + '-switch'}
                on={value}
                onChange={(data) => onChange(props.original.id, name, !value)}
                disabled={this.state.loading}
                loading={this.state.loading}
            />
        }
        if (params.type == 'default_tax') {
            return <ApSelect
                label={tr("default_vat")}
                value={value?.value ? (value.value + " %") : ""}
                optionRenderer={(item) => {
                    return <div>
                        {item.value + " %"}
                    </div>
                }}
                onChange={(item) => onChange(params.getAll || props.original.id, name, item, { additionalChanges: { default_tax_id: item?.id || null } })}
                objKeyId="id"
                objKeySearchable="value"

                clearable

                options={this.state.settings ? this.state.settings.taxes : []}

                loading={this.state.loading}
                disabled={this.state.loading}
            />
        }
        if (params.type === 'country') {
            return <ApCountrySelect
                selectedCountry={value?.name}
                country_code_id={value?.id}
                options={this.state.countries}
                onChange={(data) => {
                    if (value?.id != data?.id) {
                        onChange(props.original.id, name, data, { additionalChanges: { country_code_id: data?.id } });
                    }
                }
                }
            />

        }

        if (params.type === 'language') {
            return <ApInput
                type="select"
                id={name + "_language_id"}
                name={name + "_language_id"}
                value={value}
                options={this.state.languageOptions}
                onChange={(data) => onChange(props.original.id, name, data.target.value)}
                loading={this.state.loading}
                disabled={this.state.loading}
            />
        }
        if (params.type === 'languageContact') {
            return <ApInput
                type="select"
                options={this.getLanguageOptions()}
                id={name + "_language"}
                name={name + "_language"}
                value={value}
                onChange={(data) => onChange(props.original.id, name, data.target.value)}

            />
        }
        if (params.type === 'groupsContact') {
            return <ApSelect
                label={tr('groups')}
                value={value || []}
                className='overflowable'
                valueRenderer={(item) => {
                    return (
                        <div className="selectedGroup">
                            <SvgIcon className="small-inline" icon="tag" type="solid" />
                            {item.name}
                        </div>
                    );
                    if ((item.is_customer === true && props.original.crm_company?.is_customer) ||
                        (item.is_supplier === true && props.original.crm_company?.is_supplier)) {
                    }
                    else {
                        console.log("selected contact group not show:", item);
                    }

                }}
                optionRenderer={(item) => {
                    return (
                        <div className="groupOption">
                            <SvgIcon className="small-inline" icon="tag" type="solid" />
                            {item.name}
                        </div>
                    );
                }}
                // onChange={(groups) => this.handleModalChange("groups", groups)}
                onChange={(data) => onChange(params.getAll ? props.id : props.original.id, name, data)}
                objKeyId="id"
                objKeySearchable="name"
                multiselect
                options={this.getContactGroupOptions(params.getAll || props.original.crm_company?.is_customer, params.getAll || props.original.crm_company?.is_supplier)}
            // readOnly={!(auth.hasModule("clients.edit.contacts") || auth.hasModule("clients.extranet"))}
            />
        }

        if (params.type === 'groups') {
            // console.log(value);
            // console.log(props.original); 
            return <ApSelect
                label={tr('groups')}
                value={value || []}
                valueRenderer={(item) => {
                    return (
                        <div className="selectedGroup">
                            <SvgIcon className="small-inline" icon="tag" type="solid" />
                            {item.name}
                        </div>
                    );
                    if ((item.is_customer === true && props.original.is_customer) ||
                        (item.is_supplier === true && props.original.is_supplier)) {
                    }
                    else {
                        console.log("selected group not show:", item);
                    }

                }}
                optionRenderer={(item) => {
                    return (
                        <div className="groupOption">
                            <SvgIcon className="small-inline" icon="tag" type="solid" />
                            {item.name}
                        </div>
                    );
                }}

                onChange={(data) => onChange(params.getAll ? props.id : props.original.id, name, data)}
                objKeyId="id"
                objKeySearchable="name"
                multiselect
                // options={this.getGroupOptions()}
                options={this.getGroupOptions(params.getAll || props.original.is_customer, params.getAll || props.original.is_supplier)}
                //apiUrl="hour/projects"
                //apiData={{ exluded: [] }}
                loading={this.state.loading}
                disabled={this.state.loading}

            />
        }

        if (params.type === 'defaultPaymentCondition') {
            return <ApSelect
                key={'paymentConditions'}
                label={tr('default_payment_term')}
                value={value}
                optionRenderer={(item) => {
                    return <div>
                        {item.name}
                    </div>
                }}
                onChange={(item) => onChange(params.getAll || props.original.id, name, item, { additionalChanges: { default_payment_condition_id: item?.id || null } })}
                objKeyId="id"
                objKeySearchable="name"
                objKeyValue="name"
                clearable

                options={keyExists(this.state.settings, "paymentConditions", true, [])}

                loading={this.state.loading}
                disabled={this.state.loading}
            />
        }

        if (params.type === 'default_delivery_term') {
            return <ApSelect
                key={'deliveryTerms'}
                label={tr('default_delivery_term')}
                value={value}
                optionRenderer={(item) => {
                    return <div>
                        {item.name}
                    </div>
                }}
                onChange={(item) => onChange(params.getAll || props.original.id, name, item, { additionalChanges: { default_delivery_term_id: item?.id || null } })}
                objKeyId="id"
                objKeySearchable="name"
                objKeyValue="name"
                clearable

                options={keyExists(this.state.settings, "deliveryTerms", true, [])}

                loading={this.state.loading}
                disabled={this.state.loading}
            />
        }

        if (params.type === 'default_delivery_method') {
            return <ApSelect
                key={'deliveryMethods'}
                label={tr('default_delivery_method')}
                value={value}
                optionRenderer={(item) => {
                    return <div>
                        {item.name}
                    </div>
                }}
                onChange={(item) => onChange(params.getAll || props.original.id, name, item, { additionalChanges: { default_delivery_method_id: item?.id || null } })}
                objKeyId="id"
                objKeySearchable="name"
                objKeyValue="name"
                clearable

                options={keyExists(this.state.settings, "deliveryMethods", true, [])}

                loading={this.state.loading}
                disabled={this.state.loading}
            />
        }

        if (params.type === 'default_billing_method') {

            return <ApSelect
                label={tr("default_billing_method")}
                value={value?.name != null ? tr(value.name) : ""}
                optionRenderer={(item) => {
                    return <div>
                        {tr(item.name)}
                    </div>
                }}
                // onChange={(item) => this.setState({ default_billing_method: item, unsavedChanges: true, })}
                onChange={(item) => onChange(params.getAll || props.original.id, name, item, { additionalChanges: { default_billing_method_id: item?.id || null } })}

                objKeyId="id"
                objKeySearchable="name"
                objKeyValue="name"
                clearable

                options={this.state.settings ? this.state.settings.billing_methods : []}

                loading={this.state.loading}
                disabled={this.state.loading}
            />
        }

        if (params.type === 'default_tax_code') {

            return <ApSelect
                label={tr("default_vat_code")}
                value={value?.name != null ? tr(value.name) : ""}
                optionRenderer={(item) => {
                    return <div>
                        {tr(item.name)}
                    </div>
                }}

                onChange={(item) => onChange(params.getAll || props.original.id, name, item, { additionalChanges: { default_tax_code_id: item?.id || null } })}
                objKeyId="id"
                objKeySearchable="name"

                clearable

                options={this.state.settings ? Object.values(this.state.settings.tax_codes) : []}

                loading={this.state.loading}
                disabled={this.state.loading}

            />
        }

        if (params.type === 'cost_center') {

            return <ApNestedSelect
                label={tr('cost_center')}
                valueRenderer={value?.full_name}
                value={value}
                parentRenderer="value"
                parentTooltipRenderer="name"
                optionRenderer={(item) => {
                    return <div className="codeOption">
                        <span className="name">{item.nameTree}</span>
                        <span className="code">{item.code}</span>
                    </div>
                }}
                // onChange={(value) => { this.changeCostCenter(value) }}
                onChange={(item) => onChange(params.getAll || props.original.id, name, item,
                    { additionalChanges: { cost_center_id: item?.id || null, cost_center_group_id: item?.group_id || null } })}
                apiUrl="costCenter/search"
                loading={this.props.loading}
                disabled={this.props.loading || this.props.skipped}
                // validationState={(this.props.apCostCenterSettings?.is_required && this.state.data.cost_center == null) ? "error" : ""}
                //tailInput={tailInput}
                acceptOnlyLastLevel={true}
            // isEndReached={endReached => this.setState({ costCenterError: !endReached })}
            />
        }
        if (params.type === 'tax_group') {
            
            return <ApSelect
                label={tr(params.tr ? params.tr : name)}
                value={value ? this.renderTaxGroupValue(value) : ''}
                optionRenderer="tax_group"
                // onChange={(data) => this.changeTaxGroup(data, 'component')}
                onChange={(item) => {
                    let IdToAdditionalChanges = null;
                    if (params.IdToAdditionalChanges) {
                        IdToAdditionalChanges = { [params.IdToAdditionalChanges]: item?.id || null }
                    }
                    onChange(params.getAll || props.original.id, name, item,
                        { additionalChanges: { cost_center_id: item?.id || null, cost_center_group_id: item?.group_id || null, ...IdToAdditionalChanges } })}}
                objKeyId="id"
                clearable
                apiUrl="order/taxGroups/find"
                // apiData={{
                //     canReceive: true,
                //     projectStorage: false,
                // }}
                disabled={this.props.loading}
            />
        }
        


        return <ApInput
            type="text"
            id={name}
            name={name}
            label={tr(params.tr ? params.tr : name)}
            value={value}
            onChange={(data) => onChange(props.original.id, name, data.target.value)}
            loading={this.state.loading}
            disabled={this.state.loading}
        // readOnly={!auth.hasModule("clients.edit")}
        />
    }

    render() {
        return (
            <div className="padding" id="crmMassChange">



                <ApTabs>

                    <ApTab icon="building" label={tr('customers')}>
                        <CrmMassChange
                            renderApInput={this.renderApInput}
                        />
                    </ApTab>

                    <ApTab icon="building" label={tr('contact_persons')}>
                        <CrmContactMassChange

                            renderApInput={this.renderApInput}
                        />
                    </ApTab>

                    <ApTab icon="building" label={tr('addresses')}>
                        <CrmAdressesMassChange
                            renderApInput={this.renderApInput}
                        />
                    </ApTab>


                </ApTabs>


            </div>

        );
    }
}
