import React from 'react';
import autoBind from 'react-autobind';
import axios from 'axios';
import auth from 'services/Authed/Authed.js';
import api from 'services/Api/Api.js';
import ApFormPage from 'common/ApFormPage/ApFormPage.js';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import { errorPopper, ucfirst, formatMoney, onlyNumber, profitPercentToSellPrice,keyExists, tr } from 'services/Helpers/Helpers.js';
import ApModal from 'common/ApModal/ApModal.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import CodeSelect from 'modules/Storage/Components/ComponentEdit/CodeSelect/CodeSelect.js'

import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import ApButton from 'common/ApButton/ApButton.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import ComponentTooltip from 'modules/Storage/common/ComponentTooltip/ComponentTooltip.js';
import './WholesaleComponents.css';
import { getComponentTypeId } from 'modules/Storage/common/StorageHelpers.js';
import ComponentEdit from 'modules/Storage/Components/ComponentEdit/ComponentEdit.js';

import { ApInput,
         ApInputStack,ApAddon } from 'common/ApInput/ApInput.js';


const defaultProfitPercent = 10;

class WholesaleDiscount extends React.Component {

    constructor(props)
    {
        super(props);
        this.state = {
            pages: 0,
            data: [],
            discounts: [],
            editModal: false,
            excelModal:false,
            wholesales: [],

            loading: false,
            loadingRelated: false,

            selected: [],

            currencySign: auth.getCurrencySign(),

        }

        this.tableState = {};

        this.cancelToken = null;

        autoBind(this);
    }

    componentDidMount()
    {
        this.getRelated();
    }

    getRelated()
    {
        this.setState({loadingRelated: true});

        api({
            method: 'get',
            url: 'storage/wholesale/related',
        }).then((response) => {
        	let wholesales_array=[];
        	response.wholesales.map((seller, i) => {     
        		wholesales_array.push(seller.name);
             });


            this.setState({
                loadingRelated: false,
                wholesales: response.wholesales,
                wholesales_array: wholesales_array,
            });
        }).catch((error) => {
            console.error(error);
            this.setState({loadingRealated: false});
            errorPopper(error, tr('get_error'));
        });
        
    }
    
    
    getData(state = null)
    {
        api({
            method: 'get',
            url: 'storage/wholesale/getList',
            data: {
                page: state.page,
                pageSize: state.pageSize,
                sorted: state.sorted,
                filtered: state.filtered,
            },
        }).then((response) => {
            //console.log("peekele",response);
            this.setState({
                loadingRelated: false,
                discounts: response,
            });
        }).catch((error) => {
            console.error(error);
            this.setState({loadingRealated: false});
            errorPopper(error, tr('get_error'));
        });
    	
    }
    

	handleChange(e)
	{
		
		let data = this.state.data;
		if (e.target.name=="discount") {
			if (e.target.value<0) {
				e.target.value=0;
			}
			if (e.target.value>=100) {
				e.target.value=99.99;
			}
		}
		data[e.target.name] = e.target.value;
		this.setState({ data });
	}

	handleChangeSeller(e)
	{
		let data = this.state.data;
		//console.log(this.state.wholesales.find( i => i.name===e));
		let wholesale = this.state.wholesales.find( i => i.name===e);
		data['wholesale']=wholesale;
		if (wholesale!=null) {
			data['wholesale_id']=wholesale.id;
		}
		this.setState({ data });
	}
	
    // ----------------------------------------
    //  Main table
    // ----------------------------------------

    mainRowSelected( row )
    {
        if ( row === 'visible' )
        {
            return this.state.data.every( i => {
                return this.state.selected.find( s => s.id === i.id );
            })
        }
        return Boolean( this.state.selected.find( i => i.id === row.id ) );
    }

    filterListWholesale()
    {
        let options = [ { value: '', label: ` - ${tr('all')} - ` } ];
        if( !this.state ) return options;
        this.state.wholesales.forEach( item => {
            options.push({ value: item.id, label: ucfirst( item.name ) });
        })
        //console.log(options);
        return options;
        
    }

    mainColumns()
    {
        return [
            
                {
                Header: tr('supplier'),
                accessor: 'wholeseller_id',
                customizable: true,
                showInitially: true,
                width: 100,
                
                Cell: props => {
                    let wholesale = this.state.wholesales.find( i => i.id === props.value );
                    if( !wholesale ) return null;
                    return ucfirst( wholesale.name );
                },
                customFilter: {
                    type: "select",
                    options: this.filterListWholesale(),
                },

            },
            {
                Header: tr('discount_group'),
                accessor: 'discount_group',
                customizable: true,
                showInitially: true,
                width: 200,
                onClick: this.editGroup,

            },
            {
                Header: tr('product_number'),
                accessor: 'product_number',
                customizable: true,
                showInitially: true,
                width: 200,
                onClick: this.editGroup,

            },
            {
                Header: tr('discount_percentage'),
                accessor: 'discount_percent',
                customizable: true,
                showInitially: true,
                onClick: this.editGroup,
            },
            {
                Header: tr('name'),
                accessor: 'discount_group_name',
                customizable: true,
                showInitially: true,
                onClick: this.editGroup,
            }
            ];

    }
    // ----------------------------------------
    //  Render
    // ----------------------------------------

    renderFooter( project )
    {
        let saveButton = <ApButton
            color="blue"
            onClick={ this.editModalOpen }
            loading={ this.state.loading }
            disabled={ this.state.loading }
        >
            <SvgIcon icon="handshake" type="solid" />
            { tr('add_discount_group') }
        </ApButton>

        let excleButton = <ApButton
            color="green"
            onClick={this.editExcelModalOpen}

            loading={this.state.loading}
            disabled={this.state.loading}
        >
            <SvgIcon icon="handshake" type="solid" />
            {tr('read_data_excel')}
        </ApButton>

        return <div className="saveBar">
            {excleButton}
            {saveButton}
        </div>
    }
    
    editModalSave()
    {
    	this.setState({loading: true});
        //console.log(this.state.data);
        let data = this.state.data;
        api({
            method: 'post',
            url: 'storage/wholesale/discount/save',
            data: {
            	wholesale_id:this.state.data.wholesale_id,
                discount_code: this.state.data.code,
                product_number: this.state.data.product_number,
				discount_name: this.state.data.name,
				discount: this.state.data.discount
			},
            errorPopper: tr('save_error'),
        }).then((response) => {
            //console.log('response', response);
            this.setState({
                loading: false,
                editModal: {},
            })
            this.getData(this.state);
        }).catch((error) => {
            console.error(error);
            this.setState({loading: false});
            // errorPopper(error, 'Tukkurimateriaalin yhdistämisessä tapahtui virhe');
        });
    }
    
    editGroup(group)
    {
    	//console.log(group);
		
		let data = this.state.data;
		data['wholesale']=this.state.wholesales.find( i => i.id===group.wholeseller_id);
		data['wholesale_id'] = group.wholeseller_id;
        data['code'] = group.discount_group;
        data['product_number'] = group.product_number;
		data['name'] = group.discount_group_name;
		data['discount'] = group.discount_percent;
		this.setState({ data:data });
        this.setState({ editModal: {
            show: true,
        }});
    }
    
    editModalOpen()
    {
       // console.log('editModalOpen', this.state.selected );
        this.setState({ editModal: {
            show: true,
        }});
    }
    editExcelModalOpen() {
        // console.log('editModalOpen', this.state.selected );
        this.setState({
            excelModal: {
                show: true,
            }
        });
    }

    renderEditModal()
    {

        return <ApModal
            show={ Boolean( this.state.editModal.show ) }
        	handleClose={ () => this.setState({ editModal: {} }) }
            className="narrow codeModal"
            header={
                <div className="padding">
                    { tr('add_discount_group') }
                </div>
            }
            body={ <div className="padding">
			<ApSelect 
				options={this.state.wholesales_array}
				label={ tr('wholesaler') }
				value={this.state.data.wholesale}
				onChange={this.handleChangeSeller}
				objKeyId="id"
				objKeyValue="name"
				clearable
			/>
			<ApInput
				label={ tr('discount_group_code') }
				type="text"
				name="code"
				id="code"
				value={this.state.data.code}
				onChange={this.handleChange}
            />
            <ApInput
                label={tr('product_number')}
                type="text"
                name="product_number"
                id="product_number"
                value={this.state.data.product_number}
                onChange={this.handleChange}
            />
                
			<ApInput
				label={ tr('discount_percentage') }
				type="number"
				name="discount"
				id="discount"
				value={this.state.data.discount}
				onChange={this.handleChange}
			/>
			<ApInput
				label={ tr('discount_group_name') }
				type="text"
				name="name"
				id="name"
				value={this.state.data.name}
				onChange={this.handleChange}
				/>
            </div> }
            footer={
                <div className="padding text-right">
                    <ApButton  color="white" onClick={ this.editModalSave }>
                        <SvgIcon icon="check" type="solid" />
                        OK
                    </ApButton>
                </div>
            }
        />
    }
    import() {
        this.setState({
            loading: true,
        });

        let data = new FormData();
        data.append('file', this.state.selected_file);

        api({
            method: 'post',
            url: 'storage/wholesale/discount/readExcel',
            data: data,
            headers: { 'Content-Type': 'multipart/form-data' },
        }).then((response) => {
            // let headerOptions = this.state.headerOptions;
            // let otherCols = this.state.otherCols;
            console.log('response', response);


            this.setState({
                loading: false,
                excelModal: {},
                selected_file:null,
            });
        }).catch((error) => {
            console.error(error);
            errorPopper(error, tr('file_read_error'));
            this.setState({
                loading: false,
            });
        });
    }

    renderExcelModal() {

        return <ApModal
            show={Boolean(this.state.excelModal.show)}
            handleClose={() => this.setState({ excelModal: {} })}
            className="narrow codeModal"
            header={
                <div className="padding">
                    {tr('add_discount_group')}
                </div>
            }
            body={<div className="padding">
                <ApAddon width="300px" noRightBorder>
                    {tr('select_excel_file')}
                </ApAddon>
                <div className="fileSelectContainer">
                    <ApInputStack gap="0">
                        
                        <ApInput
                            type="file"
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            id="selected_file"
                            name="selected_file"
                            label={`${tr('select_excel_file')}...`}
                            value={this.state.selected_file}
                            onChange={(file) => { this.setState({ selected_file: file }) }}
                            loading={this.state.loading}
                            disabled={this.state.loading}
                        />
                        <ApAddon noLeftBorder width="150px">
                            <ApTooltip block text={tr('read_data_excel')} position="bottom">
                                <div className="apSimpleButton" onClick={this.import}>
                                    {tr('read_file')}
                                </div>
                            </ApTooltip>
                        </ApAddon>
                    </ApInputStack>
                    <div className="apInfo small">
                        <SvgIcon icon="info-circle" type="solid" />
                        {tr('wholesale_component_discount_excel_info')}

                    </div>
                </div>
            </div>}
            // footer={
            //     <div className="padding text-right">
            //         <ApButton color="white" onClick={this.editModalSave}>
            //             <SvgIcon icon="check" type="solid" />
            //             OK
            //         </ApButton>
            //     </div>
            // }
        />
    }

    render()
    {
        return <div id="storageWholesaleComponents" className="padding">
            <ApFormPage
                onSave={ this.saveCompany }
                loading={ this.state.loading }
                error={ this.state.errorMsg }
                unsaved={ this.state.selected.length }
                customFooter={ () => this.renderFooter() }
            
            >
                <ApReactTable
                    columns={ this.mainColumns() }
                    data={ this.state.discounts }
                    loading={ this.state.loading }

                    onFetchData={ this.getData }

                    filterable
                    showFiltersInitially={ true }

                    customRowClass={ this.mainCustomRowClass }

                />
            </ApFormPage>
            {this.renderEditModal()}
            {this.renderExcelModal()}

        </div>
    }
}

//<div onClick={ this.debug }>{ JSON.stringify( this.state.selected ) }</div>
export default WholesaleDiscount;
