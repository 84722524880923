/* eslint-disable no-template-curly-in-string */
import React from 'react';
import autoBind from 'react-autobind';

import Measure from 'react-measure';

import ApStickyBar from 'common/ApStickyBar/ApStickyBar.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApButton from 'common/ApButton/ApButton.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import api from 'services/Api/Api.js';
import ApModal from 'common/ApModal/ApModal.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import ApList from 'common/ApList/ApList.js';
import ApConfirm from 'common/ApConfirm/ApConfirm.js';
import {
    Container, Row, Col
} from 'react-bootstrap';

import {
    ApTabs,
    ApTab
} from 'common/ApTabs/ApTabs.js';
import {
    mapTree,
    keyExists,
    errorPopper, tr
} from 'services/Helpers/Helpers.js';

import { getComponentStatusId } from 'modules/Storage/common/StorageHelpers.js';
import ApValidate from 'services/ApValidate/ApValidate.js';



//import ComponentRelations from './ComponentRelations/ComponentRelations.js';

import { ApInput } from 'common/ApInput/ApInput.js';

import './Settings.css';
import CodeFormat from 'modules/Storage/Settings/CodeFormat/CodeFormat.js';
import Code from 'modules/Storage/Settings/Code/Code.js';


import './Settings.css';


class CostCenterSettings extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

            loading: false,

            modeOptions: [],
            codes: [],
            format: {},
            formatSaved: {},
            confirmFormatShow: false,

            CostCenterGroups: [],
            deleteCostCenterGroups: [],
            changes: false,

            netvisorDimensions: [],
            parsedNetvisorDimensions: [],

        }
        this.validator = new ApValidate(this, {
            "selectedCostCenterGroupName": { filter: 'required', state: 'error', text: tr('name_missing') },
            
            
        });

        autoBind(this);
    }

    componentDidMount() {
        this.getSettings();
        this.getNetvisorDimensions();

    }
    getSettings() {
        this.setState({ loading: true });

        return api({
            method: 'get',
            url: 'costCenter/settings',
        }).then((response) => {
            //console.log("get Settings", response);
            this.setSettings(response);
            this.setState({ loading: false ,changes:false});
        }).catch((error) => {
            console.log(error);

            errorPopper(error, tr('get_error'));
            this.setState({ loading: false });
        });
    }

    // Get netvisor dimensions for syncing cost centers with netvisor
    async getNetvisorDimensions() {
        return api({
            method: 'get',
            url: 'costCenter/getNetvisorDimensions',
        }).then((response) => {
            // If company does not have netvisor, response is null
            if (response) {
                const parsedNetvisorDimensions = this.parseNetvisorDimensions(response);
                this.setState({ netvisorDimensions: response, parsedNetvisorDimensions },
                    () => this.syncNetvisorDimensions()
                );
            }
        }).catch((error) => {
            errorPopper(error, tr('get_error'));
        });
    }

    // Check if Netvisor dimensions have changed since last sync. If they have, sync changes
    syncNetvisorDimensions() {
        if (!this.state.netvisorDimensions || this.state.codes.length === 0) return;
        let tree = JSON.parse(JSON.stringify(this.state.codes));

        const codes = mapTree(tree, null, (item) => {
            const netvisorDimensionData = {}
            if (item.netvisor_dimension_key) {
                const dimension = this.state.parsedNetvisorDimensions.find(dimension => dimension.value == item.netvisor_dimension_key);
                
                // If dimension is not found, it has been removed from Netvisor
                if (dimension) {
                    netvisorDimensionData.netvisor_dimension_key = dimension.value;
                    netvisorDimensionData.netvisor_dimension_name = dimension.dimensionName;
                    netvisorDimensionData.netvisor_dimension_father_id = dimension.dimensionFatherId;
                    netvisorDimensionData.netvisor_dimension_header_key = dimension.headerKey;
                    netvisorDimensionData.netvisor_dimension_header_name = dimension.headerName;
                } else {
                    netvisorDimensionData.netvisor_dimension_key = null;
                    netvisorDimensionData.netvisor_dimension_name = null;
                    netvisorDimensionData.netvisor_dimension_father_id = null;
                    netvisorDimensionData.netvisor_dimension_header_key = null;
                    netvisorDimensionData.netvisor_dimension_header_name = null;
                }
            }
            return {
                ...item,
                ...netvisorDimensionData
            }
        });

        this.setState({ codes }, () => {
            this.calculateCodeTree(codes);
        });
    }

    /**
     * Parse netvisor dimensions into format usable by select type apinput
     * @param {Object} dimensions - Netvisor dimensions
     * @returns {Array} - Parsed dimensions
    */ 
    parseNetvisorDimensions(dimensions) {
        // Company does not have netvisor or there are no dimensions in Netvisor
        if (!dimensions?.DimensionName) return [];
        const parsedDimensions = [];

        // DimensionName is an object if there is only one dimension, otherwise it's an array
        if (!Array.isArray(dimensions.DimensionName)) {
            dimensions.DimensionName = [dimensions.DimensionName];
        }
        
        dimensions.DimensionName.forEach(dimension => {
            const dimensionHeaderKey = dimension.Netvisorkey;
            const dimensionHeaderName = dimension.Name;

            // DimensionDetails.DimensionDetail is an object if there is only one detail, otherwise it's an array
            const details = dimension.DimensionDetails?.DimensionDetail;
            if (!details) return;
            const detailsArray = Array.isArray(details) ? details : [details];

            detailsArray.forEach(detail => {
                parsedDimensions.push({
                    value: detail.Netvisorkey,
                    label: `${dimensionHeaderName} - ${detail.Name}`,
                    dimensionName: detail.Name,
                    headerKey: dimensionHeaderKey,
                    headerName: dimensionHeaderName,
                    dimensionFatherId: detail.FatherID,
                });
            });
        });

        // Add empty option to the beginning of the list
        if (parsedDimensions.length > 0) {
            parsedDimensions.unshift({
                value: null,
                label: '-',
            });
        }

        return parsedDimensions;
    }

    deleteCostCenterGroup(id) {
        let groups = [...this.state.CostCenterGroups];
        let removed;
        for (let i = 0; i < groups.length; i++) {
            if (groups[i].id === id) {
                removed = groups.splice(i, 1);
                i--;
            }
        }


        this.setState({
            CostCenterGroups: groups,
            deleteCostCenterGroups: this.state.deleteCostCenterGroups.concat(removed),
        });


    }

    setSettings(data) {
        this.setState({
            formatSaved: JSON.stringify(data.format),
            format: data.format,
            modeOptions: data.mode_options,

            CostCenterGroups: data.cost_center_groups,
            costCenterEnabled: data.cost_center_enabled,
            costCenterIsRequired: data.is_required,
        }, () => {
            this.calculateCodeTree(data.codes);
            this.syncNetvisorDimensions();
        });
    }

    saveClicked() {
        if (this.formatIsChanged())
            this.setState({ confirmFormatShow: true });
        else
            this.saveSettings();
    }

    saveSettings() {
        this.setState({ confirmFormatShow: false });



        let data = {

        };

        if (this.formatIsChanged()) {
            let format = {
                mode_id: this.state.format.mode.id,
                levels: this.state.format.levels,
                separator: this.state.format.separator,
                //tail_length: this.state.format.tail_length,
            };
            data.format = format;
        }
        else {
            if (this.codeTreeRef)
                this.codeTreeRef.updateSelectedCodeSettings();
            let tree = JSON.parse(JSON.stringify(this.state.codes));

            data.codes = mapTree(tree, null, (item) => {
                return {
                    id: isNaN(parseInt(item.id, 10)) ? null : item.id,
                    name: item.name,
                    value: item.value,
                    code: item.code,
                    description: item.description,
                    children: item.children,
                    group_id: item.costCenterGroup ? item.costCenterGroup.id : null,

                    netvisor_dimension_key: item.netvisor_dimension_key,
                    netvisor_dimension_name: item.netvisor_dimension_name,
                    netvisor_dimension_father_id: item.netvisor_dimension_father_id,
                    netvisor_dimension_header_key: item.netvisor_dimension_header_key,
                    netvisor_dimension_header_name: item.netvisor_dimension_header_name,
                };
            });
        }

        data.cost_center_groups = this.state.CostCenterGroups;
        data.delete_cost_center_groups = this.state.deleteCostCenterGroups;
        data.cost_center_enabled = this.state.costCenterEnabled;
        data.is_required = this.state.costCenterIsRequired;

        this.setState({ loading: true });

  

        return api({
            method: 'post',
            url: 'costCenter/saveSettings',
            data: data,
        }).then((response) => {

            window.emitter.emit('popper', {
                type: 'success',
                content: <strong>{tr('saved')}</strong>,
            });

            this.setSettings(response);
            this.setState({ loading: false, changes: false, });

        }).catch((error) => {
            errorPopper(error, tr('save_error'));
            this.setState({ loading: false });
        });
    }

    renderConfirmFormatChange() {
        return <div id="confirmFormatChange" className="ApModalConfirm">
            <ApModal
                show={this.state.confirmFormatShow}
                handleClose={() => this.setState({ confirmFormatShow: false })}
                closeFromBg
                className="narrow"
                header={
                    <div className="padding-small">
                        <h4>
                            {tr('edit_cost_center_code_format_confirm')}
                        </h4>
                    </div>
                }
                body={
                    <div className="padding">
                        <div>{tr('edit_cost_center_code_format_confirm_info')}</div>
                    </div>
                }
                footer={
                    <div className="footer padding">
                        <ApButton className="cancel" onClick={() => this.setState({ confirmFormatShow: false })}>
                            <SvgIcon icon="times" type="solid" />
                            {tr('no')}
                        </ApButton>

                        <ApButton className="save" color="green" onClick={this.saveSettings}>
                            <SvgIcon icon="check" type="solid" />
                            {tr('yes')}
                        </ApButton>
                    </div>
                }
            />
        </div>
    }
    renderCostCenterGroup(data) {
        return <div id="confirmFormatChange" className="ApModalConfirm">
            <ApModal
                show={this.state.showCostcenterGroup}
                handleClose={() => this.setState({ showCostcenterGroup: false })}
                closeFromBg
                className="narrow"
                header={
                    <div className="padding-small">
                        <h4>
                            {tr('edit_cost_center_group')}
                        </h4>
                    </div>
                }
                body={
                    <div className="padding">
                        <Container fluid={true} className="padding">
                            <Row>
                                <Col sm={12}>
                                    <ApInput
                                        type="text"
                                        id="code"
                                        name="code"
                                        label={tr("name")}
                                        validationState={this.validator.getState('selectedCostCenterGroupName')}
                                        tooltip={this.validator.getText('selectedCostCenterGroupName')}
                                        autoComplete="off"
                                        onChange={(val) => this.setState({ selectedCostCenterGroupName: val.target.value })}
                                        value={this.state.selectedCostCenterGroupName}
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12}>
                                    <ApInput
                                        type="text"
                                        id="name"
                                        name="name"
                                        label={tr("code")}
                                        autoComplete="off"
                                        onChange={(val) => this.setState({ selectedCostCenterGroupCode: val.target.value })}
                                        value={this.state.selectedCostCenterGroupCode}
                                    />
                                </Col>
                            </Row>
                            <Row>

                                <Col sm={12}>
                                    <ApInput
                                        type="textarea"
                                        id="name"
                                        name="name"
                                        label={tr("description")}
                                        autoComplete="off"
                                        onChange={(val) => this.setState({ selectedCostCenterGroupDescription: val.target.value })}
                                        value={this.state.selectedCostCenterGroupDescription}
                                    />
                                </Col>

                            </Row>

                        </Container>

                    </div>
                }
                footer={
                    <div className="footer padding">
                        <ApButton className="cancel" onClick={() => this.setState({ showCostcenterGroup: false })}>
                            <SvgIcon icon="times" type="solid" />
                            {tr('cancel')}
                        </ApButton>

                        <ApButton className="save" color="blue" onClick={this.saveCostCenterGroup} disabled={this.state.selectedCostCenterGroupName === "" || this.state.selectedCostCenterGroupName==null}>
                            <SvgIcon icon="save" type="solid" />
                            {tr('save')}
                        </ApButton>
                    </div>
                }
            />
        </div>
    }
    saveCostCenterGroup() {
        let groups = [...this.state.CostCenterGroups];
        let founded = false;
        for (let group of groups) {
            if (group.id === this.state.selectedCostCenterGroupId) {
                group.name = this.state.selectedCostCenterGroupName;
                group.code = this.state.selectedCostCenterGroupCode;
                group.description = this.state.selectedCostCenterGroupDescription;
                founded = true;
                break;
            }
        }
        if (!founded) {
            let group = {
                id: "new" + Math.random(),
                name: this.state.selectedCostCenterGroupName,
                code: this.state.selectedCostCenterGroupCode,
                description: this.state.selectedCostCenterGroupDescription,
            };
            groups.push(group);
        }
        this.setState({
            showCostcenterGroup: false,
            CostCenterGroups: groups,
            selectedCostCenterGroupId: null,
            selectedCostCenterGroupName: null,
            selectedCostCenterGroupCode: null,
            selectedCostCenterGroupDescription: null,
        });


    }


    calculateCodeTree(tree = null) {
        if (tree === null)
            tree = this.state.codes.slice(0);

        tree = mapTree(tree, null, (item) => {

            item.invalid = false;
            item.child_invalid = false;
            item.missing_children = false;
            item.code_invalid = false;

            let own = {
                components_count: parseFloat(item.components_count),
            };

            // Clone own stuff to total stuff
            let all = JSON.parse(JSON.stringify(own));

            item.children.forEach((child) => {
                if (child.calculated)
                    all.components_count += child.calculated.all.components_count;
                if (child.invalid)
                    item.child_invalid = true;
            });

            item.calculated = {
                own: own,
                all: all,
            }

            item.missing_children = false;
            if (item.level < this.state.format.levels.length && item.children.length === 0)
                item.missing_children = true;

            item.code_invalid = this.codeTreeNodeCodeInvalid(item)

            if (item.child_invalid
                || item.missing_children
                || item.code_invalid
            ) {
                item.invalid = true;
            }

            return item;
        });
        
        this.setState({ codes: tree});
    }

    renderCount() {
        if (this.state.loading)
            return null;

        if (!keyExists(this.state, 'format.mode'))
            return null;

        if (this.state.format?.mode?.is_free) {
            const count = this.state.componentsCount;
            return <ApTooltip position="top" text={tr('show_in_storage_component_management')} block>
                <div className="withoutCodeContainer" onClick={() => this.goToComponents([{ id: 'status_id', value: getComponentStatusId('active') }])}>
                    <SvgIcon className="headerSvg" icon="cubes" type="solid" />
                    {tr('storage_component_count')}: <strong>{count}</strong>
                </div>
            </ApTooltip>
        }
        else {
            const count = this.state.componentsWithoutCodeCount;
            return <ApTooltip position="top" text={tr('show_in_storage_component_management')} block>
                <div className="withoutCodeContainer" onClick={() => this.goToComponents([{ id: 'code', value: '!' }, { id: 'status_id', value: getComponentStatusId('active') }])}>
                    <SvgIcon className="headerSvg" icon="ban" type="solid" />
                    {tr('storage_component_without_code_count')}: <strong>{count}</strong>
                </div>
            </ApTooltip>
        }
    }

    formatIsChanged() {
        return (this.state.formatSaved !== JSON.stringify(this.state.format));
    }
    codeTreeNodeCodeInvalid(item) {
        if (!item.value) return tr('code_missing');
        if (this.state.format?.mode?.need_length) {
            const level = this.state.format.levels.find(l => l.level === item.level)
            if (level) {
                if (item.value.length !== level.length)
                    return tr('code_min_length', [level.length]);
            }
        }
        return false;
    }
    formatChange(data) {
        let format = { ...this.state.format, ...data };
        this.setState({ format: format, changes: true });
    }

    renderCodeTree() {
        if (this.state.loading)
            return null;
        if (!keyExists(this.state, 'format.mode'))
            return null;
        if (this.state.format?.mode?.is_free)
            return null;

        return <Code
            ref={(ref) => { this.codeTreeRef = ref }}
            loading={this.state.loading}
            tree={this.state.codes}

            separator={(this.state.format.separator) ? this.state.format.separator : ''}
            mode={this.state.format.mode}
            levels={this.state.format.levels}
            //tailLength={this.state.format.tail_length}
            onTreeChange={(tree) => this.calculateCodeTree(tree)}
            formatChanged={(this.formatIsChanged())}
            isCodeInvalid={this.codeTreeNodeCodeInvalid}
            changeSaveButton={() => this.setState({ changes:true})}
            isCostCenter={true}
            costCenterGroups={this.state.CostCenterGroups}
            netvisorDimensions={this.state.parsedNetvisorDimensions}
        />
    }
    CreateItem(pos) {

        this.setState({
            showCostcenterGroup: true,
        });

    }
    renderItem(group) {

        return (
            <div>
                <strong>{group.name}</strong><br />

                <small>{group.code}</small>

            </div>


        );
    }
    editItem(item) {
        this.setState({
            showCostcenterGroup: true,
            selectedCostCenterGroupId: item.id,
            selectedCostCenterGroupName: item.name,
            selectedCostCenterGroupCode: item.code,
            selectedCostCenterGroupDescription: item.description,
        })
    }
    getListActions() {
        return [
            {
                label: tr('delete'),
                icon: "trash",
                disabled: false,
                action: (item, closeFunc) => {
                    this.clickDelete(item.id);
                    closeFunc();
                }
            },
        ]
    }
    clickDelete(id) {

        this.setState({
            showRemoveConfirmDialog: true,
            selectedCostCenterGroupId: id
        });

    }

    handleChange(value, afterFunc = false) {
        //console.log(value);
        this.setState({
            CostCenterGroups: value,
            changes: true,
        });

        //let newState = {};
        //newState[key] = value;
        //newState.unsavedChanges = true;
        //this.setState(newState, () => {
        //if (typeof (afterFunc) === "function")
        //	afterFunc();
        //});
    }


    render() {
        
        return (<div className="padding">
            <Measure
                onResize={() => {
                    if (this.stickyBar)
                        this.stickyBar.updateBounds();
                }}
            >
                {({ measureRef }) =>
                    <div className="apBox" id="storageSettings" ref={measureRef}>
                        <div className="apBoxHeader">
                            <h1>{tr('cost_center_settings')}</h1>
                        </div>
                        <ApTabs >
                            <ApTab icon="cogs" label={tr('settings')}>
                                {/*<div className="apBoxHeader">
                                    <h5>{tr('work_permit_settings')}</h5>
                                    <small>{tr('work_permits_info')}</small>
                                </div>*/}
                                <div className="apFormGroup">
                                    <div className="apSwitchBlock small">

                                        <label htmlFor="cost-center-is-enabled-switch" className="info">
                                            {tr("cost_center_is_enabled")}
                                            <small>{tr("cost_center_setting_info1")}</small>
                                        </label>
                                        <ApSwitch

                                            id="cost-center-is-enabled-switch"
                                            on={this.state.costCenterEnabled}
                                            onChange={() => {
                                                this.setState({
                                                    costCenterEnabled: !this.state.costCenterEnabled, changes: true,
                                                    costCenterIsRequired: this.state.costCenterEnabled ? false : this.state.costCenterIsRequired,
                                                })
                                            }}
                                            disabled={this.state.loading || this.state.locked}
                                        />

                                    </div>
                                </div>
                                <div className="apFormGroup">
                                    <div className="apSwitchBlock small">

                                        <label htmlFor="cost-center-is-required-switch" className="info">
                                            {tr("cost_center_is_required")}
                                            <small>{tr("cost_center_is_required_setting_info")}</small>
                                        </label>
                                        <ApSwitch

                                            id="cost-center-is-required-switch"
                                            on={this.state.costCenterIsRequired}
                                            onChange={() => { this.setState({ costCenterIsRequired: !this.state.costCenterIsRequired, changes: true }) }}
                                            disabled={this.state.loading || this.state.locked || !this.state.costCenterEnabled}
                                        />

                                    </div>
                                </div>
                            </ApTab>

                            <ApTab icon="list" label={tr('types_and_numbering')} disabled={!this.state.costCenterEnabled}>
                                <CodeFormat
                                    loading={this.state.loading}
                                    mode={this.state.format?.mode}
                                    modeOptions={this.state.modeOptions}
                                    levels={this.state.format.levels}
                                    separator={this.state.format.separator}
                                    //tailLength={this.state.format.tail_length}
                                    onChange={(data) => this.formatChange(data)}
                                    isCostCenter={true}
                                />
                                {this.renderCodeTree()}
                                {//this.renderCount()
                                }
                            </ApTab>

                            <ApTab icon="cubes" label={tr('groups')} disabled={!this.state.costCenterEnabled}>
                                <div className="apBoxHeader">

                                </div>
                                <div className="list">
                                    <ApList
                                        loading={this.props.loading}
                                        items={this.state.CostCenterGroups}
                                        itemRenderer={this.renderItem}
                                        /*columns={[
                                            {
                                                style: { width: "100px", padding: "5px" }, value: (item) => <div>
                                                    {item.note_before_expiry_dates != null &&
                                                        <small>
                                                            {tr("reminder") + " " + tr("to_supervisor").toLowerCase() + ": "}<strong>{item.note_before_expiry_dates + " " + tr("days")}</strong>
                                                            <br /></small>}
                                                    {item.note_before_expiry_dates_to_user != null &&
                                                        <small>
                                                            {tr("reminder") + " " + tr("to_user").toLowerCase() + ": "}<strong>{item.note_before_expiry_dates_to_user + " " + tr("days")}</strong>
                                                            <br /></small>}
                                                    {item.note_before_expiry_dates == null && item.note_before_expiry_dates_to_user == null &&
                                                        <small>
                                                            {tr("no_notifications")}
                                                        </small>}
                                                </div>
                                            },
                                        ]}*/
                                        onClick={(item) => this.editItem(item)}
                                        actions={this.getListActions()}
                                        validationState={(item) =>
                                            item.removed ? "removed" :
                                                item.unsaved ? "warning" :
                                                    ""
                                        }
                                        icon="tag"
                                        sortable
                                        onSort={(items) => this.handleChange(items)}
                                        addNewItem={(pos) => this.CreateItem(pos)}
                                    />


                                </div>
                            </ApTab>

                        </ApTabs>
                        <ApStickyBar bottomMode zIndex={10} ref={node => this.stickyBar = node}>
                            <div className="saveBar">
                                <div className="left">
                                </div>
                                <div className="right">
                                    <ApButton className={"save" + (this.state.changes ? " highlight" : "")}
                                        color="blue"
                                        onClick={this.saveClicked}
                                        loading={this.state.loading}
                                        disabled={this.state.loading}
                                    >
                                        <SvgIcon icon="save" type="solid" />
                                        {tr('save')}
                                    </ApButton>
                                </div>
                            </div>
                        </ApStickyBar>
                    </div>
                }
            </Measure>
            {this.renderConfirmFormatChange()}
            {this.renderCostCenterGroup()}
            {this.state.showRemoveConfirmDialog &&
                <ApConfirm
                    show={this.state.showRemoveConfirmDialog}
                    header={`${tr('delete_confirm')}?`}
                    body={`${tr('delete_sure')}?`}
                    onConfirm={() => { this.deleteCostCenterGroup(this.state.selectedCostCenterGroupId) }}
                    onClose={() => { this.setState({ showRemoveConfirmDialog: false, selectedCostCenterGroupId: null }) }}
                />
            }
        </div>);
    }
}

export default CostCenterSettings;
