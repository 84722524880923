/* eslint-disable eqeqeq */
import React from 'react';
import autoBind from 'react-autobind';
import auth from 'services/Authed/Authed.js';

import ApButton from 'common/ApButton/ApButton.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApSelect from 'common/ApSelect/ApSelect.js';
import ApSwitch from 'common/ApSwitch/ApSwitch.js';
import api from 'services/Api/Api.js'
import { keyExists, tr } from 'services/Helpers/Helpers.js';

import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';

import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import _ from 'lodash';

export default class SocialExpenses extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      year: new Date().getFullYear(),
      socialExpenses: {},
      totalPercent: 0,

      newCustomGroupId: 1,
    };
  }

  componentDidMount() {
    this.setState({
      socialExpenses: this.props.social_expenses
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if ((!prevProps.social_expenses && this.props.social_expenses) || (this.state.year && (prevState.year !== this.state.year))) {
      this.calculateTotalPercent();
    }
  }

  calculateTotalPercent = () => {
    let percent = 0;

    const fields = [
      'tyel_calc_percent',
      'group_insurance_percent',
      'accident_insurance_percent',
      //'clerk_social_percent',
      //'clerk_voluntary_percent',
      // 'production_social_percent',
      // 'production_voluntary_percent',
      //'contractor_social_percent',
      //'contractor_voluntary_percent',
      //'tyel_salary_over_percent',
      //'tyel_salary_under_percent',
      'tyel_salary_between_percent',
      'employee_part_below_age',
      //'employee_part_over_age',
      'social_security_payment',
      'illness_insurance_employee',
      //'illness_insurance_entrepreneur',
      //'illness_insurance_beneficiary',
      'unemployment_employer_percent_1',
      //'unemployment_employer_percent_2',
      'unemployment_employee_percent'
    ];
    const fields2023 = [
      'holiday_allowance_more_than_year_percent',
      'tyel_contribution_temporary_percent',
      'group_insurance_percent',
      'accident_insurance_percent',
      'illness_insurance_employer'
    ];
    
    if (this.props.social_expenses && this.props.social_expenses[this.state.year]) {

      if (parseFloat(this.state.year) >= 2023) {
        // console.log(this.getTyelCalcPercent());
        // console.log(this.getUnemploymentCalcPercent());
        percent += parseFloat(this.getTyelCalcPercent());
        percent += parseFloat(this.getUnemploymentCalcPercent());
      
        fields2023.forEach(field => {
          if (keyExists(this.props.social_expenses[this.state.year], field, false)) {
            if (this.props.social_expenses[this.state.year][field] != '' && this.props.social_expenses[this.state.year][field] != null) {
              percent += parseFloat(this.props.social_expenses[this.state.year][field]);
              // console.log(field,this.props.social_expenses[this.state.year][field]);
            }
          }
        });

        if (Array.isArray(this.props.social_expenses[this.state.year].personnel_group_expenses)) {
          const found = this.props.social_expenses[this.state.year].personnel_group_expenses.find(group => group.value != null);
          if (found) {
            if (found.value) {
              percent += parseFloat(found.value);
            }
            if (found.value_voluntary) {
              percent += parseFloat(found.value_voluntary);
            }
          }
        }
        // calculate percent from custom row groups using first role that has a value
        if (Array.isArray(this.props.social_expenses[this.state.year].custom_row_groups)) {
          this.props.social_expenses[this.state.year].custom_row_groups.forEach(group => {
            if (group.custom_rows) {
              const foundRole = this.props.roles.find(
                role => group.custom_rows.find(
                  row => row.role_id == role.id && row.value && parseFloat(row.value) > 0
                )
              );
              const foundRow = group.custom_rows.find(
                row => row.role_id == foundRole?.id && row.value != null
              );
              if (foundRow) {
                percent += parseFloat(foundRow.value);
              }
            }
          });
        }
      }
      else {
        
        fields.forEach(field => {
          if (keyExists(this.props.social_expenses[this.state.year], field, false)) {
            if (this.props.social_expenses[this.state.year][field] != '' && this.props.social_expenses[this.state.year][field] != null) {
              percent += parseFloat(this.props.social_expenses[this.state.year][field]);
            }
          }
        });
        if (Array.isArray(this.props.social_expenses[this.state.year].personnel_group_expenses)) {
          const found = this.props.social_expenses[this.state.year].personnel_group_expenses.find(group => group.value != null);
          if (found) {
            if (found.value) {
              percent += parseFloat(found.value);
            }
            if (found.value_voluntary) {
              percent += parseFloat(found.value_voluntary);
            }
          }
        }
        // calculate percent from custom row groups using first role that has a value
        if (Array.isArray(this.props.social_expenses[this.state.year].custom_row_groups)) {
          this.props.social_expenses[this.state.year].custom_row_groups.forEach(group => {
            if (group.custom_rows) {
              const foundRole = this.props.roles.find(
                role => group.custom_rows.find(
                  row => row.role_id == role.id && row.value && parseFloat(row.value) > 0
                )
              );
              const foundRow = group.custom_rows.find(
                row => row.role_id == foundRole.id && row.value != null
              );              if (foundRow) {
                percent += parseFloat(foundRow.value);
              }
            }
          });
        }
      }
    }
    

    this.setState({
      totalPercent: percent
    });
  };

  getSocialExpenses = (year) => {
    const companyId = auth.getCompanyID();
    this.setState({ loading: true });
    api({
      method: 'get',
      url: `social-expenses/company/${companyId}/year/${year}`,
    }).then((response) => {
      console.log('Social expenses', response);
      this.setState({
        socialExpenses: response,
        loading: false
      })
    }).catch((error) => {
      console.log(error);
      this.setState({ loading: false });
      /* this.setState({ 
          error: keyExists( error, "response.data.message", true, "Odottamaton virhe, asetuksia ei voitu tallentaa" ) || "VIRHE",
          loading: false 
      }); */
    });
  };

  saveSocialExpenses = () => {
    const companyId = auth.getCompanyID();
    this.setState({ loading: true });
    api({
      method: 'post',
      url: `social-expenses/save`,
      data: {
        ...this.state.socialExpenses,

      }
    }).then((response) => {
      console.log('Social expenses saved', response);
      this.setState({
        socialExpenses: response,
        loading: false
      })
    }).catch((error) => {
      console.log(error);
      this.setState({ loading: false });
      /* this.setState({ 
          error: keyExists( error, "response.data.message", true, "Odottamaton virhe, asetuksia ei voitu tallentaa" ) || "VIRHE",
          loading: false 
      }); */
    });
  };

  handleYearChange = (e) => {
    const year = e.target.value;
    this.setState({
      year: year
    });
  };

  onChange = (e) => {
    const name = e.target.name;
    let value = e.target.value.replace(/,/g, '.');
    let data = this.props.social_expenses;
    if (!data[this.state.year]) data[this.state.year] = {};
    data[this.state.year][name] = value;
    this.props.handleChange('social_expenses', data);
    this.calculateTotalPercent();
  };

  handlePersonnelGroupExpenseChange = (e, role, voluntary = false) => {
    let value = e.target.value.replace(/,/g, '.');
    let data = this.props.social_expenses;
    if (!data[this.state.year]) data[this.state.year] = {};
    if (!data[this.state.year].personnel_group_expenses) data[this.state.year].personnel_group_expenses = [];
    const foundRoleIndex = data[this.state.year].personnel_group_expenses.findIndex(group => group.role_id == role.id)
    if (foundRoleIndex != -1) {
      let key = !voluntary ? 'value' : 'value_voluntary';
      data[this.state.year].personnel_group_expenses[foundRoleIndex][key] = value;
    } else {
      data[this.state.year].personnel_group_expenses.push({
        role_id: role.id,
        value: !voluntary ? value : '',
        value_voluntary: voluntary ? value : '',
      })
    }
    this.props.handleChange('social_expenses', data);
    this.calculateTotalPercent();
  };

  renderOtherExpenses = () => {
    const socialExpenses = this.props.social_expenses && this.props.social_expenses[this.state.year] 
      ? this.props.social_expenses[this.state.year] 
      : null;    

    return <>
        <h4>{tr('other_social_expenses')}</h4>
        <div className="form-block-section">
          <div id="other-expenses">
            <div className="form-column">
              <label>{tr('expense')}</label>
              <label>{tr('other_social_expenses')}</label>
              <label>{tr('optional_side_costs')}</label>
            </div>
            {this.props.roles && this.props.roles.map(role => {
              if (!role.id) return null;
              return <div key={role.id} className='form-column'>
                <label>{role.name}</label>
                <div className="social-expenses-input">
                  <ApInput
                    type="text"
                    id={role.name + '_social_percent'}
                    name={role.name + '_social_percent'}
                    value={socialExpenses === null
                      ? ''
                      : (socialExpenses.personnel_group_expenses && socialExpenses.personnel_group_expenses.find(group => group.role_id == role.id))
                        ? socialExpenses.personnel_group_expenses.find(group => group.role_id == role.id).value
                        : ''}
                    onChange={e => this.handlePersonnelGroupExpenseChange(e, role, false)}
                    loading={this.state.loading}
                    disabled={this.state.loading}
                  />
                  <span className="social-expenses-input-unit">%</span>
                </div>
                <div className="social-expenses-input">
                  <ApInput
                    type="text"
                    id={role.name + "_voluntary_percent"}
                    name={role.name + "_voluntary_percent"}
                    value={socialExpenses === null
                      ? ''
                      : (socialExpenses.personnel_group_expenses && socialExpenses.personnel_group_expenses.find(group => group.role_id == role.id))
                        ? socialExpenses.personnel_group_expenses.find(group => group.role_id == role.id).value_voluntary
                        : ''}
                    onChange={e => this.handlePersonnelGroupExpenseChange(e, role, true)}
                    loading={this.state.loading}
                    disabled={this.state.loading}
                  />
                  <span className="social-expenses-input-unit">%</span>
                </div>
              </div>
            })}
          </div>
        </div>
      </>
  }

  renderCustomRowGroups = () => {
    const socialExpenses = this.props.social_expenses && this.props.social_expenses[this.state.year]
      ? this.props.social_expenses[this.state.year]
      : null;

    return <>
      {socialExpenses?.custom_row_groups?.map((group, index) => {
        return <div key={group.id} className="form-block-section">
          <div className="custom-row-groups">
            <div className="form-column">
              {index === 0 && <label>{tr('expense')}</label>}
              {/* Group name input */}
              <ApInput 
                type="text"
                label={tr('name')}
                id={group.id + '_name'}
                name={group.id + '_name'}
                value={group.name}
                onChange={e => {
                  let data = this.props.social_expenses;
                  if (!data[this.state.year]) data[this.state.year] = {};
                  const oldCustomGroups = data[this.state.year].custom_row_groups || [];
                  const foundIndex = oldCustomGroups.findIndex(oldGroup => oldGroup.id == group.id);
                  oldCustomGroups[foundIndex].name = e.target.value;
                  data[this.state.year].custom_row_groups = oldCustomGroups;
                  this.props.handleChange('social_expenses', data);
                }}
                loading={this.state.loading}
                disabled={this.state.loading}
              />
            </div>
            {this.props.roles.map(role => {
              const customRow = group.custom_rows.find(row => row.role_id == role.id);
              return <div key={role.id} className='form-column'>
                {index === 0 && <label>{role.name}</label>}
                <div className="social-expenses-input">
                  <ApInput
                    type="number"
                    id={group.id + '_social_percent'}
                    name={group.id + '_social_percent'}
                    value={customRow?.value}
                    onChange={e => this.handleCustomRowChange(e, group, customRow, role)}
                    loading={this.state.loading}
                    disabled={this.state.loading}
                  />
                  <span className="social-expenses-input-unit">%</span>
                </div>
              </div>
            })}
            <div className='delete-group-button'>
              <ApButton color="red" size="tiny" onClick={() => {
                let data = this.props.social_expenses;
                const oldCustomGroups = data[this.state.year].custom_row_groups || [];
                data[this.state.year].custom_row_groups = oldCustomGroups.filter(oldGroup => oldGroup.id !== group.id);
                this.props.handleChange('social_expenses', data);
              }}>
                <SvgIcon icon="trash" type="solid"/>
              </ApButton>
            </div>
          </div>
        </div>
      })}
      
      {this.props.roles &&
        <ApButton onClick={this.addNewCustomRowGroup} size="tiny" color="green">
          <SvgIcon icon="plus" type="solid" /> {tr('add_new')}
        </ApButton>}
    </>
  }

  handleCustomRowChange = (e, group, row, role) => {
    let value = e.target.value.replace(/,/g, '.');
    if (isNaN(value)) {
      return;
    }
    let data = this.props.social_expenses;
    if (!data[this.state.year]) data[this.state.year] = {};
    const oldCustomGroups = data[this.state.year].custom_row_groups || [];
    const foundGroupIndex = oldCustomGroups.findIndex(oldGroup => oldGroup.id == group.id);
    const foundRowIndex = oldCustomGroups[foundGroupIndex].custom_rows.findIndex(oldRow => oldRow.role_id == row?.role_id);
    if (foundRowIndex === -1) {
      oldCustomGroups[foundGroupIndex].custom_rows.push({
        role_id: role.id, 
        value: value,
        group_id: group.id,
      });
    } else {
      oldCustomGroups[foundGroupIndex].custom_rows[foundRowIndex].value = value;
    }
    data[this.state.year].custom_row_groups = oldCustomGroups;
    this.props.handleChange('social_expenses', data);
    this.calculateTotalPercent();
  };

  addNewCustomRowGroup = () => {
    let customRows = this.props.roles.map(role => {
      return {
        role_id: role.id,
        value: null,
      }
    });
    let customRowGroup = {
      id: 'new_' + this.state.newCustomGroupId,
      name: '',
      custom_rows: customRows,
    }
    this.setState({ newCustomGroupId: this.state.newCustomGroupId + 1 });
    let data = this.props.social_expenses;
    if (!data[this.state.year]) data[this.state.year] = {};
    const oldCustomGroups = data[this.state.year].custom_row_groups || [];
    data[this.state.year].custom_row_groups = [...oldCustomGroups, customRowGroup];
    this.props.handleChange('social_expenses', data);
  }

  getOldRender() {
    let unemploymentLowerAge = '?';
    let unemploymentUpperAge = '?';
    let tyelLowerAge = '?';
    let tyelUpperAge = '?';
    let tyelLimitAge = '?';
    let socialSecurityLowerAge = '?';
    let socialSecurityUpperAge = '?';

    const socialExpenses = this.props.social_expenses && this.props.social_expenses[this.state.year] ? this.props.social_expenses[this.state.year] : null;

    if (socialExpenses) {
      unemploymentLowerAge = socialExpenses.unemployment_insurance_age_lower ? socialExpenses.unemployment_insurance_age_lower : '?';
      unemploymentUpperAge = socialExpenses.unemployment_insurance_age_upper ? socialExpenses.unemployment_insurance_age_upper : '?';
      tyelLowerAge = socialExpenses.tyel_age_lower ? socialExpenses.tyel_age_lower : '?';
      tyelUpperAge = socialExpenses.tyel_age_upper ? socialExpenses.tyel_age_upper : '?';
      tyelLimitAge = socialExpenses.tyel_age_limit ? socialExpenses.tyel_age_limit : '?';
      socialSecurityLowerAge = socialExpenses.social_security_age_lower ? socialExpenses.social_security_age_lower : '?';
      socialSecurityUpperAge = socialExpenses.social_security_age_upper ? socialExpenses.social_security_age_upper : '?';
    }


    return <>
      <h3>{tr('company_specific_definitions', [tyelLowerAge, tyelUpperAge])}</h3>
      <div id="social-expenses-company" className="form-block">
        
        <h4>{tr('tyel_contribution')   }</h4>
       
        <div className="form-block-section">
          <div className="social-expenses-input">
            <label htmlFor="tyel-calc-percent">{tr('tyel_contribution_used_in_calculation')}</label>
            <ApInput
              type="text"
              id="tyel-calc-percent"
              name="tyel_calc_percent"
              value={socialExpenses === null ? '' : socialExpenses.tyel_calc_percent}
              onChange={this.onChange}
              loading={this.state.loading}
              disabled={this.state.loading}
            />
            <span className="social-expenses-input-unit">%</span>
          </div>
        </div>
        <h4>{tr('mandatory_insurance_contributions')}</h4>
        <div className="form-block-section">
          <div className="social-expenses-input">
            <label htmlFor="group-insurance-percent">{tr('group_life_insurance_contribution')}</label>
            <ApInput
              type="text"
              id="group-insurance-percent"
              name="group_insurance_percent"
              value={socialExpenses === null ? '' : socialExpenses.group_insurance_percent}
              onChange={this.onChange}
              loading={this.state.loading}
              disabled={this.state.loading}
            />
            <span className="social-expenses-input-unit">%</span>
          </div>
          <div className="social-expenses-input">
            <label htmlFor="accident-insurance-percent">{tr('accident_insurance_contribution')}</label>
            <ApInput
              type="text"
              id="accident-insurance-percent"
              name="accident_insurance_percent"
              value={socialExpenses === null ? '' : socialExpenses.accident_insurance_percent}
              onChange={this.onChange}
              loading={this.state.loading}
              disabled={this.state.loading}
            />
            <span className="social-expenses-input-unit">%</span>
          </div>
        </div>
        {this.renderOtherExpenses()}
        {this.renderCustomRowGroups()}
        <h4>{tr('payroll_processing')}</h4>
        <div className="form-block-section">
          <div className="social-expenses-input">
            <label htmlFor="paid-salaries">{tr('paid_salaries')}</label>
            <div style={{ flexBasis: '100%', position: 'relative' }}>
              <ApInput
                type="text"
                id="paid-salaries"
                name="paid_salaries"
                value={socialExpenses === null ? '' : socialExpenses.paid_salaries}
                onChange={this.onChange}
                loading={this.state.loading}
                disabled={this.state.loading}
              />
              <span className="social-expenses-input-unit" style={{ position: 'absolute', top: '0', right: '10px', transform: 'translateY(-15%)' }}>€</span>
            </div>
          </div>
          <div className="social-expenses-input">
            <label htmlFor="week-1-date">{tr('payroll_date_week1')}</label>
            <ApInput
              type="datetimeV2"
              id="week-1-salary-date"
              name="week_1_salary_date"
              value={socialExpenses === null ? '' : socialExpenses.week_1_salary_date}
              onChange={(val) => this.onChange({ target: { name: 'week_1_salary_date', value: val } })}
              dateFormat="YYYY-MM-DD"
              loading={this.state.loading}
              disabled={this.state.loading}
            />
          </div>
          <div className="social-expenses-input">
            <label htmlFor="week-2-date">{tr('payroll_date_week2')}</label>
            <ApInput
              type="datetimeV2"
              id="week-2-salary-date"
              name="week_2_salary_date"
              value={socialExpenses === null ? '' : socialExpenses.week_2_salary_date}
              onChange={(val) => this.onChange({ target: { name: 'week_2_salary_date', value: val } })}
              dateFormat="YYYY-MM-DD"
              loading={this.state.loading}
              disabled={this.state.loading}
            />
          </div>
        </div>
      </div>
      <h3>{tr('retirement_insurance_contributions_employee', [tyelLowerAge, tyelUpperAge])}</h3>
      <div id="social-expenses-18-67" className="form-block">
        <h4>{tr('tyel_contribution')}</h4>
        <div className="form-block-section">
          <div id="tyel-18-67">
            <div className="form-column">
              <label>{tr('salary_amount_under')}</label>
              <label>{tr('salary_amount_over')}</label>
              <label>{tr('salary_amount_between')}</label>
            </div>
            <div className="form-column">
              <div className="social-expenses-input">
                <ApInput
                  type="text"
                  id="tyel-salary-under-amount"
                  name="tyel_salary_under_amount"
                  value={socialExpenses === null ? '' : socialExpenses.tyel_salary_under_amount}
                  onChange={this.onChange}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                />
                <span className="social-expenses-input-unit">€</span>
              </div>
              <div className="social-expenses-input">
                <ApInput
                  type="text"
                  id="tyel-salary-over-amount"
                  name="tyel_salary_over_amount"
                  value={socialExpenses === null ? '' : socialExpenses.tyel_salary_over_amount}
                  onChange={this.onChange}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                />
                <span className="social-expenses-input-unit">€</span>
              </div>
            </div>
            <div className="form-column">
              <div className="social-expenses-input">
                <ApInput
                  type="text"
                  id="tyel-salary-under-percent"
                  name="tyel_salary_under_percent"
                  value={socialExpenses === null ? '' : socialExpenses.tyel_salary_under_percent}
                  onChange={this.onChange}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                />
                <span className="social-expenses-input-unit">%</span>
              </div>
              <div className="social-expenses-input">
                <ApInput
                  type="text"
                  id="tyel-salary-over-percent"
                  name="tyel_salary_over_percent"
                  value={socialExpenses === null ? '' : socialExpenses.tyel_salary_over_percent}
                  onChange={this.onChange}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                />
                <span className="social-expenses-input-unit">%</span>
              </div>
              <div className="social-expenses-input">
                <ApInput
                  type="text"
                  id="tyel-salary-between-percent"
                  name="tyel_salary_between_percent"
                  value={socialExpenses === null ? '' : socialExpenses.tyel_salary_between_percent}
                  onChange={this.onChange}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                />
                <span className="social-expenses-input-unit">%</span>
              </div>
            </div>
          </div>
        </div>
        <h4>{tr('temporary_employer')}</h4>
        <div className="form-block-section">
          <div id="temporary-employer">
            <div className="form-column">
              <label>{tr('no_permanent_employees')}</label>
              <label>{tr('salary_amount_under_6months')}</label>
            </div>
            <div className="form-column">
              <div className="push" />
              <div className="social-expenses-input">
                <ApInput
                  type="text"
                  id="temp-salary-under-amount"
                  name="temp_salary_under_amount"
                  value={socialExpenses === null ? '' : socialExpenses.temp_salary_under_amount}
                  onChange={this.onChange}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                />
                <span className="social-expenses-input-unit">€</span>
              </div>
            </div>
            <div className="form-column">
              <div className="push" />
              <div className="social-expenses-input">
                <ApInput
                  type="text"
                  id="temp-salary-under-percent"
                  name="temp_salary_under_percent"
                  value={socialExpenses === null ? '' : socialExpenses.temp_salary_under_percent}
                  onChange={this.onChange}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                />
                <span className="social-expenses-input-unit">%</span>
              </div>
            </div>
          </div>
        </div>
        <h4>{tr('employee_share')}</h4>
        <div className="form-block-section">
          <div className="social-expenses-input">
            <label htmlFor="employee-part-below-age">{tr('employee_share_age_below', [tyelLimitAge])}</label>
            <ApInput
              type="text"
              id="employee-part-below-age"
              name="employee_part_below_age"
              value={socialExpenses === null ? '' : socialExpenses.employee_part_below_age}
              onChange={this.onChange}
              loading={this.state.loading}
              disabled={this.state.loading}
            />
            <span className="social-expenses-input-unit">%</span>
          </div>
          <div className="social-expenses-input">
            <label htmlFor="employee-over-53-share">{tr('employee_share_age_over', [tyelLimitAge])}</label>
            <ApInput
              type="text"
              id="employee-part-over-age"
              name="employee_part_over_age"
              value={socialExpenses === null ? '' : socialExpenses.employee_part_over_age}
              onChange={this.onChange}
              loading={this.state.loading}
              disabled={this.state.loading}
            />
            <span className="social-expenses-input-unit">%</span>
          </div>
          <div className="social-expenses-input">
            <label htmlFor="tyel-income-lower-limit">{tr('tyel_income_lower_limit')}</label>
            <ApInput
              type="text"
              id="tyel-income-lower-limit"
              name="tyel_income_lower_limit"
              value={socialExpenses === null ? '' : socialExpenses.tyel_income_lower_limit}
              onChange={this.onChange}
              loading={this.state.loading}
              disabled={this.state.loading}
            />
            <span className="social-expenses-input-unit">/{tr('month_abbr')}</span>
          </div>
        </div>
      </div>
      <h3>{tr('social_security_contribution_employee', [socialSecurityLowerAge, socialSecurityUpperAge])}</h3>
      <div id="social-security-16-67" className="form-block">
        <div className="form-block-section">
          <div className="social-expenses-input">
            <label htmlFor="social-security-payment">{tr('social_security_contribution')}</label>
            <ApInput
              type="text"
              id="social-security-payment"
              name="social_security_payment"
              value={socialExpenses === null ? '' : socialExpenses.social_security_payment}
              onChange={this.onChange}
              loading={this.state.loading}
              disabled={this.state.loading}
            />
            <span className="social-expenses-input-unit">%</span>
          </div>
        </div>
      </div>
      <h3>{tr('health_insurance_contribution')}</h3>
      <div id="illness-insurance-cost" className="form-block">
        <div className="form-block-section">
          <div className="social-expenses-input">
            <label htmlFor="illness-insurance-employee">{tr('employees')}</label>
            <ApInput
              type="text"
              id="illness-insurance-employee"
              name="illness_insurance_employee"
              value={socialExpenses === null ? '' : socialExpenses.illness_insurance_employee}
              onChange={this.onChange}
              loading={this.state.loading}
              disabled={this.state.loading}
            />
            <span className="social-expenses-input-unit">%</span>
          </div>
          <div className="social-expenses-input">
            <label htmlFor="illness-insurance-entrepreneur">{tr('entrepreneurs')}</label>
            <ApInput
              type="text"
              id="illness-insurance-entrepreneur"
              name="illness_insurance_entrepreneur"
              value={socialExpenses === null ? '' : socialExpenses.illness_insurance_entrepreneur}
              onChange={this.onChange}
              loading={this.state.loading}
              disabled={this.state.loading}
            />
            <span className="social-expenses-input-unit">%</span>
          </div>
          <div className="social-expenses-input">
            <label htmlFor="illness-insurance-beneficiary">{tr('beneficiaries')}</label>
            <ApInput
              type="text"
              id="illness-insurance-beneficiary"
              name="illness_insurance_beneficiary"
              value={socialExpenses === null ? '' : socialExpenses.illness_insurance_beneficiary}
              onChange={this.onChange}
              loading={this.state.loading}
              disabled={this.state.loading}
            />
            <span className="social-expenses-input-unit">%</span>
          </div>
        </div>
      </div>
      <h3>{tr('unemployment_insurance_contribution_employee', [unemploymentLowerAge, unemploymentUpperAge])}</h3>
      <div id="social-security-16-67" className="form-block">
        <div className="form-block-section">
          <div id="unemployment-insurance">
            <div className="form-column">
              <label>{tr('salary_amount')}</label>
              <label>{tr('employer_share')}</label>
              <label>{tr('employee_share')}</label>
            </div>
            <div className="form-column">
              <div className="social-expenses-input">
                <ApInput
                  type="text"
                  id="unemployment-insurance-salary"
                  name="unemployment_insurance_salary"
                  value={socialExpenses === null ? '' : socialExpenses.unemployment_insurance_salary}
                  onChange={this.onChange}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                />
                <span className="social-expenses-input-unit">€</span>
              </div>
              <div className="social-expenses-input">
                <ApInput
                  type="text"
                  id="unemployment-employer-percent_1"
                  name="unemployment_employer_percent_1"
                  value={socialExpenses === null ? '' : socialExpenses.unemployment_employer_percent_1}
                  onChange={this.onChange}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                />
                <span className="social-expenses-input-unit">%</span>
              </div>
              <div className="social-expenses-input">
                <ApInput
                  type="text"
                  id="unemployment-employee-percent"
                  name="unemployment_employee_percent"
                  value={socialExpenses === null ? '' : socialExpenses.unemployment_employee_percent}
                  onChange={this.onChange}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                />
                <span className="social-expenses-input-unit">%</span>
              </div>
            </div>
            <div className="form-column">
              <div className="push" />
              <div className="social-expenses-input">
                <ApInput
                  type="text"
                  id="unemployment-employer-percent_2"
                  name="unemployment_employer_percent_2"
                  value={socialExpenses === null ? '' : socialExpenses.unemployment_employer_percent_2}
                  onChange={this.onChange}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                />
                <span className="social-expenses-input-unit">%</span>
              </div>
              <div className="push" />
            </div>
          </div>
        </div>
      </div>
      <h3>{tr('change_default_age_limits')}</h3>
      <div id="social-security-default-ages" className="form-block">
        <div id="default-ages">
          <div className="form-column">
            <label>{tr('payment')}</label>
            <label>{tr('retirement_insurance_contributions')}</label>
            <label>{tr('social_security_contribution')}</label>
            <label>{tr('unemployment_insurance_contribution')}</label>
          </div>
          <div className="form-column">
            <label>{tr('lower_age')}</label>
            <div className="social-expenses-input">
              <ApInput
                type="text"
                id="tyel-age-lower"
                name="tyel_age_lower"
                value={socialExpenses === null ? '' : socialExpenses.tyel_age_lower}
                onChange={this.onChange}
                loading={this.state.loading}
                disabled={this.state.loading}
              />
            </div>
            <div className="social-expenses-input">
              <ApInput
                type="text"
                id="social-security-age-lower"
                name="social_security_age_lower"
                value={socialExpenses === null ? '' : socialExpenses.social_security_age_lower}
                onChange={this.onChange}
                loading={this.state.loading}
                disabled={this.state.loading}
              />
            </div>
            <div className="social-expenses-input">
              <ApInput
                type="text"
                id="unemployment_insurance_age_lower"
                name="unemployment_insurance_age_lower"
                value={socialExpenses === null ? '' : socialExpenses.unemployment_insurance_age_lower}
                onChange={this.onChange}
                loading={this.state.loading}
                disabled={this.state.loading}
              />
            </div>
          </div>
          <div className="form-column">
            <label>{tr('upper_age')}</label>
            <div className="social-expenses-input">
              <ApInput
                type="text"
                id="tyel-age-upper"
                name="tyel_age_upper"
                value={socialExpenses === null ? '' : socialExpenses.tyel_age_upper}
                onChange={this.onChange}
                loading={this.state.loading}
                disabled={this.state.loading}
              />
            </div>
            <div className="social-expenses-input">
              <ApInput
                type="text"
                id="social-security-age-upper"
                name="social_security_age_upper"
                value={socialExpenses === null ? '' : socialExpenses.social_security_age_upper}
                onChange={this.onChange}
                loading={this.state.loading}
                disabled={this.state.loading}
              />
            </div>
            <div className="social-expenses-input">
              <ApInput
                type="text"
                id="unemployment-insurance-age-upper"
                name="unemployment_insurance_age_upper"
                value={socialExpenses === null ? '' : socialExpenses.unemployment_insurance_age_upper}
                onChange={this.onChange}
                loading={this.state.loading}
                disabled={this.state.loading}
              />
            </div>
          </div>
          <div className="form-column">
            <label>{tr('age_limit')}</label>
            <div className="social-expenses-input">
              <ApInput
                type="text"
                id="tyel-age-limit"
                name="tyel_age_limit"
                value={socialExpenses === null ? '' : socialExpenses.tyel_age_limit}
                onChange={this.onChange}
                loading={this.state.loading}
                disabled={this.state.loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>

  }

  getTyelCalcPercent() {
    const socialExpenses = this.props.social_expenses && this.props.social_expenses[this.state.year] ? this.props.social_expenses[this.state.year] : null;
    if (socialExpenses == null) {
      return '';
    }
    
    if (parseFloat(socialExpenses.paid_salaries) < parseFloat(socialExpenses.tyel_salary_under_amount)) {
      return socialExpenses.tyel_salary_under_percent;
    }
    if (parseFloat(socialExpenses.paid_salaries) > parseFloat(socialExpenses.tyel_salary_over_amount)) {
      return socialExpenses.tyel_salary_over_percent;
    }

    return socialExpenses.tyel_salary_between_percent;
  }

  getUnemploymentCalcPercent() {
    const socialExpenses = this.props.social_expenses && this.props.social_expenses[this.state.year] ? this.props.social_expenses[this.state.year] : null;
    if (socialExpenses == null) {
      return '';
    }

    if (parseFloat(socialExpenses.paid_salaries) <= parseFloat(socialExpenses.unemployment_insurance_salary)) {
      return socialExpenses.unemployment_employer_percent_1;
    }
    if (parseFloat(socialExpenses.paid_salaries) > parseFloat(socialExpenses.unemployment_insurance_salary)) {
      return socialExpenses.unemployment_employer_percent_2;
    }

    return socialExpenses.unemployment_employer_percent_1;
  }


  getNewRender() {
    let unemploymentLowerAge = '?';
    let unemploymentUpperAge = '?';
    let tyelLowerAge = '?';
    let tyelUpperAge = '?';
    let tyelLimitAge = '?';
    let socialSecurityLowerAge = '?';
    let socialSecurityUpperAge = '?';

    const socialExpenses = this.props.social_expenses && this.props.social_expenses[this.state.year] ? this.props.social_expenses[this.state.year] : null;

    if (socialExpenses) {
      unemploymentLowerAge = socialExpenses.unemployment_insurance_age_lower ? socialExpenses.unemployment_insurance_age_lower : '?';
      unemploymentUpperAge = socialExpenses.unemployment_insurance_age_upper ? socialExpenses.unemployment_insurance_age_upper : '?';
      tyelLowerAge = socialExpenses.tyel_age_lower ? socialExpenses.tyel_age_lower : '?';
      tyelUpperAge = socialExpenses.tyel_age_upper ? socialExpenses.tyel_age_upper : '?';
      tyelLimitAge = socialExpenses.tyel_age_limit ? socialExpenses.tyel_age_limit : '?';
      socialSecurityLowerAge = socialExpenses.social_security_age_lower ? socialExpenses.social_security_age_lower : '?';
      socialSecurityUpperAge = socialExpenses.social_security_age_upper ? socialExpenses.social_security_age_upper : '?';
    }


    return <>
      

      <h3>{tr('company_specific_definitions', [tyelLowerAge, tyelUpperAge])}</h3>
      <div id="social-expenses-company" className="form-block">
        {this.renderOtherExpenses()}
        {this.renderCustomRowGroups()}
        <h4>{tr('payroll_processing')}</h4>
        <div className="form-block-section">
          <div className="social-expenses-input">
            <label htmlFor="paid-salaries">{tr('paid_salaries')}</label>
            <div style={{ flexBasis: '100%', position: 'relative' }}>
              <ApInput
                type="number"
                id="paid-salaries"
                name="paid_salaries"
                value={socialExpenses === null ? '' : socialExpenses.paid_salaries}
                onChange={this.onChange}
                loading={this.state.loading}
                disabled={this.state.loading}
              />
              <span className="social-expenses-input-unit" style={{ position: 'absolute', top: '0', right: '10px', transform: 'translateY(-15%)' }}>€</span>
            </div>
          </div>
        </div>

        <h4>{tr('tyel_contribution')}  
        <ApTooltip position="topleft" text= {tr('tyel_contribution_company_share')}>
        {  <div className="apInfo small">
                <SvgIcon icon="info-circle" type="solid" />                                    
          </div> }   
        </ApTooltip>      
           </h4>
     
      
        <div className="form-block-section">
          <div id="tyel-18-67">
            <div className="form-column">
              <label>{tr('salary_amount_under')}</label>
              <label>{tr('salary_amount_over')}</label>
              <label>{tr('salary_amount_between')}</label>
            </div>
            <div className="form-column">
              <div className="social-expenses-input">
                <ApInput
                  type="number"
                  id="tyel-salary-under-amount"
                  name="tyel_salary_under_amount"
                  value={socialExpenses === null ? '' : socialExpenses.tyel_salary_under_amount}
                  onChange={this.onChange}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                />
                <span className="social-expenses-input-unit">€</span>
              </div>
              <div className="social-expenses-input">
                <ApInput
                  type="number"
                  id="tyel-salary-over-amount"
                  name="tyel_salary_over_amount"
                  value={socialExpenses === null ? '' : socialExpenses.tyel_salary_over_amount}
                  onChange={this.onChange}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                />
                <span className="social-expenses-input-unit">€</span>
              </div>
            </div>
            <div className="form-column">
              <div className="social-expenses-input">
                <ApInput
                  type="number"
                  id="tyel-salary-under-percent"
                  name="tyel_salary_under_percent"
                  value={socialExpenses === null ? '' : socialExpenses.tyel_salary_under_percent}
                  onChange={this.onChange}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                />
                <span className="social-expenses-input-unit">%</span>
              </div>
              <div className="social-expenses-input">
                <ApInput
                  type="number"
                  id="tyel-salary-over-percent"
                  name="tyel_salary_over_percent"
                  value={socialExpenses === null ? '' : socialExpenses.tyel_salary_over_percent}
                  onChange={this.onChange}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                />
                <span className="social-expenses-input-unit">%</span>
              </div>
              <div className="social-expenses-input">
                <ApInput
                  type="number"
                  id="tyel-salary-between-percent"
                  name="tyel_salary_between_percent"
                  value={socialExpenses === null ? '' : socialExpenses.tyel_salary_between_percent}
                  onChange={this.onChange}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                />
                <span className="social-expenses-input-unit">%</span>
              </div>
            </div>
          </div>
        </div>

        
        <h4>{tr('tyel_contribution')}</h4>


        <div className="form-block-section">
          <div className="social-expenses-input">
            <label htmlFor="tyel-calc-percent">{tr('tyel_contribution_used_in_calculation')}</label>
            <ApInput
              type="number"
              id="tyel-calc-percent"
              name="tyel_calc_percent"
              value={this.getTyelCalcPercent()}
              onChange={this.onChange}
              loading={this.state.loading}
              disabled={true}
            />
            <span className="social-expenses-input-unit">%</span>
          </div>
          <div className="social-expenses-input">
            <label htmlFor="tyel-contribution-employee-share-percent">{tr('tyel_contribution_employee_share')}</label>
            <ApInput
              type="number"
              id="tyel-contribution-employee-share-percent"
              name="tyel_contribution_employee_share_percent"
              value={socialExpenses === null ? '' : socialExpenses.tyel_contribution_employee_share_percent}
              onChange={this.onChange}
              loading={this.state.loading}
              disabled={this.state.loading}
            />
            <span className="social-expenses-input-unit">%</span>
          </div>
          
        </div>

        <h4>{tr('holiday_allowance')}</h4>
        <div className="form-block-section">
          <div className="social-expenses-input">
            <label htmlFor="holiday-allowance-under-year-percent">{tr('holiday_allowance_under_year')}</label>
            <ApInput
              type="number"
              id="holiday-allowance-under-year-percent"
              name="holiday_allowance_under_year_percent"
              value={socialExpenses === null ? '' : socialExpenses.holiday_allowance_under_year_percent}
              onChange={this.onChange}
              loading={this.state.loading}
              disabled={this.state.loading}
            />
            <span className="social-expenses-input-unit">%</span>
          </div>
          <div className="social-expenses-input">
            <label htmlFor="holiday-allowance-more-than-year-percent">{tr('holiday_allowance_more_than_year')}</label>
            <ApInput
              type="number"
              id="holiday-allowance-more-than-year-percent"
              name="holiday_allowance_more_than_year_percent"
              value={socialExpenses === null ? '' : socialExpenses.holiday_allowance_more_than_year_percent}
              onChange={this.onChange}
              loading={this.state.loading}
              disabled={this.state.loading}
            />
            <span className="social-expenses-input-unit">%</span>
          </div>
        </div>

        <h4>{tr('temporary_employer')}</h4>
          <div className="form-block-section">
          <div className="social-expenses-input">
            <label htmlFor="tyel-contribution-temporary-percent">{tr('tyel_contribution_temporary')}</label>
            <ApInput
              type="number"
              id="tyel-contribution-temporary-percent"
              name="tyel_contribution_temporary_percent"
              value={socialExpenses === null ? '' : socialExpenses.tyel_contribution_temporary_percent}
              onChange={this.onChange}
              loading={this.state.loading}
              disabled={this.state.loading}
            />
            <span className="social-expenses-input-unit">%</span>
          </div>
        </div>
        <h4>{tr('mandatory_insurance_contributions')}</h4>
        <div className="form-block-section">
          <div className="social-expenses-input">
            <label htmlFor="group-insurance-percent">{tr('group_life_insurance_contribution')}</label>
            <ApInput
              type="number"
              id="group-insurance-percent"
              name="group_insurance_percent"
              value={socialExpenses === null ? '' : socialExpenses.group_insurance_percent}
              onChange={this.onChange}
              loading={this.state.loading}
              disabled={this.state.loading}
            />
            <span className="social-expenses-input-unit">%</span>
          </div>
          <div className="social-expenses-input">
            <label htmlFor="accident-insurance-percent">{tr('accident_insurance_contribution')}</label>
            <ApInput
              type="number"
              id="accident-insurance-percent"
              name="accident_insurance_percent"
              value={socialExpenses === null ? '' : socialExpenses.accident_insurance_percent}
              onChange={this.onChange}
              loading={this.state.loading}
              disabled={this.state.loading}
            />
            <span className="social-expenses-input-unit">%</span>
          </div>
        </div>
        
      </div>
      
      
      <h3>{tr('unemployment_insurance_contribution_employee', [unemploymentLowerAge, unemploymentUpperAge])}</h3>
      <div id="social-security-16-67" className="form-block">
        <div className="form-block-section">
          <div id="unemployment-insurance">
            <div className="form-column">
              <label>{tr('salary_amount')}</label>
              <label>{tr('employer_share')}</label>
            </div>
            <div className="form-column">
              <div className="social-expenses-input">
                <ApInput
                  type="number"
                  id="unemployment-insurance-salary"
                  name="unemployment_insurance_salary"
                  value={socialExpenses === null ? '' : socialExpenses.unemployment_insurance_salary}
                  onChange={this.onChange}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                />
                <span className="social-expenses-input-unit">€</span>
              </div>
              <div className="social-expenses-input">
                <ApInput
                  type="number"
                  id="unemployment-employer-percent_1"
                  name="unemployment_employer_percent_1"
                  value={socialExpenses === null ? '' : socialExpenses.unemployment_employer_percent_1}
                  onChange={this.onChange}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                />
                <span className="social-expenses-input-unit">%</span>
              </div>
            
            </div>
            <div className="form-column">
              <div className="push" />
              <div className="social-expenses-input">
                <ApInput
                  type="number"
                  id="unemployment-employer-percent_2"
                  name="unemployment_employer_percent_2"
                  value={socialExpenses === null ? '' : socialExpenses.unemployment_employer_percent_2}
                  onChange={this.onChange}
                  loading={this.state.loading}
                  disabled={this.state.loading}
                />
                <span className="social-expenses-input-unit">%</span>
              </div>
              
            </div>
          </div>
        </div>
      </div>

      <h3>{tr('health_insurance_contribution')}</h3>
      <div id="illness-insurance-cost" className="form-block">
        <div className="form-block-section">
          <div className="social-expenses-input">
            <label htmlFor="illness-insurance-employer">{tr('employer')}</label>
            <ApInput
              type="number"
              id="illness-insurance-employer"
              name="illness_insurance_employer"
              value={socialExpenses === null ? '' : socialExpenses.illness_insurance_employer}
              onChange={this.onChange}
              loading={this.state.loading}
              disabled={this.state.loading}
            />
            <span className="social-expenses-input-unit">%</span>
          </div>
          
        </div>
      </div>

      <h3>{tr('default_age_limits')}</h3>
      <div id="social-security-default-ages" className="form-block">
        <div id="default-ages">
          <div className="form-column">
            <label>{tr('payment')}</label>
            <label>{tr('retirement_insurance_contributions')}</label>
            <label>{tr('social_security_contribution')}</label>
            <label>{tr('unemployment_insurance_contribution')}</label>
          </div>
          <div className="form-column">
            <label>{tr('lower_age')}</label>
            <div className="social-expenses-input">
              <ApInput
                type="number"
                id="tyel-age-lower"
                name="tyel_age_lower"
                value={socialExpenses === null ? '' : socialExpenses.tyel_age_lower}
                onChange={this.onChange}
                loading={this.state.loading}
                disabled={this.state.loading}
              />
            </div>
            <div className="social-expenses-input">
              <ApInput
                type="number"
                id="social-security-age-lower"
                name="social_security_age_lower"
                value={socialExpenses === null ? '' : socialExpenses.social_security_age_lower}
                onChange={this.onChange}
                loading={this.state.loading}
                disabled={this.state.loading}
              />
            </div>
            <div className="social-expenses-input">
              <ApInput
                type="number"
                id="unemployment_insurance_age_lower"
                name="unemployment_insurance_age_lower"
                value={socialExpenses === null ? '' : socialExpenses.unemployment_insurance_age_lower}
                onChange={this.onChange}
                loading={this.state.loading}
                disabled={this.state.loading}
              />
            </div>
          </div>
          <div className="form-column">
            <label>{tr('upper_age')}</label>
            <div className="social-expenses-input">
              <ApInput
                type="number"
                id="tyel-age-upper"
                name="tyel_age_upper"
                value={socialExpenses === null ? '' : socialExpenses.tyel_age_upper}
                onChange={this.onChange}
                loading={this.state.loading}
                disabled={this.state.loading}
              />
            </div>
            <div className="social-expenses-input">
              <ApInput
                type="number"
                id="social-security-age-upper"
                name="social_security_age_upper"
                value={socialExpenses === null ? '' : socialExpenses.social_security_age_upper}
                onChange={this.onChange}
                loading={this.state.loading}
                disabled={this.state.loading}
              />
            </div>
            <div className="social-expenses-input">
              <ApInput
                type="number"
                id="unemployment-insurance-age-upper"
                name="unemployment_insurance_age_upper"
                value={socialExpenses === null ? '' : socialExpenses.unemployment_insurance_age_upper}
                onChange={this.onChange}
                loading={this.state.loading}
                disabled={this.state.loading}
              />
            </div>
          </div>
          
        </div>
      </div>
    </>
  }

  getFrom(year) {
    if (year >= 2023) {
      return this.getNewRender();
    }
    return this.getOldRender();
  }

  copyFromPreviousYear = () => {
    const previousYear = this.state.year - 1;
    const prevYearSocialExpenses = this.props.social_expenses && this.props.social_expenses[previousYear] 
      ? _.cloneDeep(this.props.social_expenses[previousYear]) 
      : null;

    const currentYearSocialExpenses = this.props.social_expenses && this.props.social_expenses[this.state.year]

    let data = this.props.social_expenses;
    
    if (prevYearSocialExpenses) {
      delete prevYearSocialExpenses.id;
      delete prevYearSocialExpenses.year;
      if (prevYearSocialExpenses.personnel_group_expenses?.length) {
        prevYearSocialExpenses.personnel_group_expenses = prevYearSocialExpenses.personnel_group_expenses.map(group => {
          let id = null;
          if (currentYearSocialExpenses && currentYearSocialExpenses.personnel_group_expenses) {
            const currentGroup = currentYearSocialExpenses.personnel_group_expenses.find(g => g.role_id === group.role_id);
            if (currentGroup) {
              id = currentGroup.id;
            }
          }
          const newGroup = {
            id: id,
            role_id: group.role_id,
            value: group.value,
            value_voluntary: group.value_voluntary,
          }
          return newGroup;
        });
      }
      if (prevYearSocialExpenses.custom_row_groups?.length) {
        let newCustomGroupId = this.state.newCustomGroupId;
        prevYearSocialExpenses.custom_row_groups = prevYearSocialExpenses.custom_row_groups.map(group => {
          let id = null;
          if (currentYearSocialExpenses && currentYearSocialExpenses.custom_row_groups) {
            const currentGroup = currentYearSocialExpenses.custom_row_groups.find(g => g.id === group.id);
            if (currentGroup) {
              id = currentGroup.id;
            }
          }
          const newGroup = {
            id: "new_" + newCustomGroupId,
            name: group.name,
            custom_rows: group.custom_rows.map(row => {
              return {
                value: row.value,
                role_id: row.role_id,
              }
            })
          }
          newCustomGroupId++;
          return newGroup;
        });
      }

      if (currentYearSocialExpenses) {
        prevYearSocialExpenses.id = currentYearSocialExpenses.id;
      }
      data[this.state.year] = prevYearSocialExpenses;
      this.props.handleChange('social_expenses', data);
    }
  }


  render() {
    const yearOptions = [];
    const currentYear = new Date().getFullYear();
    for (let i = 1970; i <= currentYear + 1; i++) {
      yearOptions.push({
        id: i,
        label: i
      });
    }



    const form = this.getFrom(this.state.year);

    return <div className="padding">
      <h2>{tr('social_expenses')}</h2>
      <div id="social-expenses-form">
        <div id="social-expenses-year">
          <ApButton 
            onClick={this.copyFromPreviousYear} 
            disabled={this.state.loading}
            color="green"
            size="tiny">
              <SvgIcon icon="copy" type="solid"/> {tr('copy_last_year')}
          </ApButton>
          <div className="social-expenses-input">
            <label htmlFor="year">{tr('select_year')}</label>
            <ApInput
              type="select"
              id="year"
              name="year"
              value={this.state.year}
              options={yearOptions}
              onChange={this.handleYearChange}
              loading={this.state.loading}
              disabled={this.state.loading}
            />
          </div>
          <div className="social-expenses-input">
            <label htmlFor="total_percent">{tr('summed_up_percent')}</label>
            <ApInput
              type="number"
              id="total-percent"
              name="total_percent"
              value={this.state.totalPercent}
              loading={this.state.loading}
              disabled
            />
            <span className="social-expenses-input-unit">%</span>
          </div>
        </div>
        {form}


      </div>
    </div>
  }

}
