import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';
import auth from 'services/Authed/Authed.js';
import moment from 'moment';
import 'moment/locale/fi';

import Chart from './Chart/Chart.js';

import { ApTabs, ApTab } from 'common/ApTabs/ApTabs.js';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApTree from 'common/ApTree/ApTree.js';
import ApTimeline from 'common/ApTimeline/ApTimeline.js';
import ApProgressbar from 'common/ApProgressbar/ApProgressbar.js';
import ApTooltip from 'common/ApTooltip/ApTooltip.js';
import ApButton from 'common/ApButton/ApButton.js';
import './Tracking.css';

import Head from '../Head/Head.js';

import { sqlToDateInput
       , mapTree
       , roundToDecimals
       , pick
       , formatMoney
       , tr } from 'services/Helpers/Helpers.js';
import TrackingReports from './Tabs/Reports/TrackingReportsMain.js';

const tabIds = {
    0: "summary",
    1: "status",
    2: "expenses",
    3: "schedule",
    4: "material",
    5: "reports",
};

const lines = [
    { loading: true, active: true, name: 'hour_entries_total', color: 'blue', title: tr('recorded_hours_alt'),
        tooltip: [
            { title: tr('value'), value: 'value', unit: 'h'},
            { title: tr('change'), value: 'change', unit: 'h'},
        ],
    },
    { loading: true, active: true, name: 'estimates_total', color: 'green', title: tr('work_readiness_assessment') },
    { loading: true, active: true, name: 'goal_total', color: 'orange', title: tr('allocated_target'),
        tooltip: [
            { title: tr('value'), value: 'value', unit: 'h'},
            { title: tr('change'), value: 'change', unit: 'h'},
        ],
    },
    { loading: false, active: false, name: 'active_workload', color: 'gray', title: tr('active_workload'),
        tooltip: [
            { title: tr('value'), value: 'value', unit: 'h'},
        ],
    },
];

const bars = [
    { loaded: true, loading: false, color: 'blue', name: 'work_entry_total', total: 'work_alloc_total', tooltip: tr('recorded_hours_total') },
    { loaded: true, loading: false, color: 'green', name: 'work_estimate_total', total: 'work_alloc_total' , tooltip: tr('latest_readiness_assessment') },
    { loaded: false, loading: true, color: 'orange', name: 'goal_total', total: 'work_alloc_total', tooltip: tr('allocated_hours_to_date') },
];

const now = moment();

class ProjectTracking extends React.Component {

    constructor( props )
    {
        super( props );
        this.state = {
            has_offer: false,
            tree: [],
            lines: lines,
            bars: bars,

            lineHighlight: null,

            currencySign: auth.getCurrencySign(),
        }

        this.chart = React.createRef();

        autoBind(this);
    }

    onTabChange( tabId )
    {
        this.props.history.replace( `/projects/${ this.props.projectId }/tracking/${ tabIds[ tabId ] }`);
    }

    getTabId( param )
    {

        if( param in tabIds )
            return parseInt( param, 10 );
        return parseInt( Object.keys( tabIds ).find( key => tabIds[ key ] === param ), 10 );
    }

    componentDidMount()
    {
        //console.log('Tracking mounted');
        this.getProject();
    }

    componentDidUpdate( prevProps )
    {
        if( prevProps.projectId !== this.props.projectId )
        {
            this.setState({ lines: lines }, () => {
                this.getProject();
            });
        }
    }

    getProject()
    {
        //console.log('getProject');
        this.setState( {loading: true } );
        api({
            method: 'get',
            url: `project/tracking/${ this.props.projectId }/tree`,
            errorPopper: tr('get_error'),
        }).then((response) => {

            let tree = response.tree;

            tree = mapTree( tree, null, ( item ) => {
                item.__schedule_child_open = true;
                return item;
            });

            //console.log('getProject TREE', tree[ 0 ] );

            this.setState({
                has_offer: response.has_offer,
                loading: false,
                tree: tree,
            }, () => {
                if( !tree[0].billing_hourly )
                {
                    this.state.lines.forEach( l => {
                        if( !l.active ) return null;
                        this.getLine( l );
                    });

                    this.state.bars.forEach( b => {
                        if( b.loaded ) return null;
                        this.getBar( b );
                    });
                }
            });

        }).catch((error) => {
            console.error(error);
            this.setState({loading: false});
        });
    }

    getLine( line )
    {
        let lines = this.state.lines.slice(0);
        const i = lines.findIndex( f => f.name === line.name );
        lines[ i ].loading = true;
        this.setState({ lines: lines });

        // console.log( 'getLine' );
        line.alloc = this.state.tree[ 0 ].calculated.total.work_alloc_total;

        api({
            method: 'post',
            url: `project/tracking/${ this.props.projectId }/line`,
            data: pick( line, 'name', 'alloc' ),
            errorPopper: tr('get_error'),
        }).then((response) => {

            //console.log('getLine', response );

            let lines = this.state.lines.slice(0);
            let current = {
                ...line,
                data: response,
                loading: false,
            }
            const fIndex = lines.findIndex( l => l.name === line.name );
            if( fIndex === -1 ) lines.push( current );
            else lines[ fIndex ] = current;

            this.setState({
                lines: lines,
            });
        }).catch((error) => {
            // console.error(error);

            let lines = this.state.lines.slice(0);
            const i = lines.findIndex( f => f.name === line.name );
            lines[ i ].active = false;
            lines[ i ].loading = false;
            this.setState({ lines: lines });
        });
    }

    getBar( bar )
    {
        //console.log( 'Getting bar', bar ) ;

        let bars = this.state.bars.slice(0);
        const i = bars.findIndex( f => f.name === bar.name );
        bars[ i ].loading = true;
        this.setState({ bars: bars });

        // console.log( 'getLine' );
        const alloc = this.state.tree[ 0 ].calculated.total.work_alloc_total;

        api({
            method: 'post',
            url: `project/tracking/${ this.props.projectId }/bar`,
            data: {
                name: bar.name,
                alloc: alloc,
            },
            errorPopper: tr('get_error'),
        }).then((response) => {

            //console.log('getBars', response );

            let tree = this.state.tree.slice( 0 );

            tree = mapTree( tree, null, ( item ) => {
                item.bar_values[ bar.name ] = response.values[ item.id ];
                return item;
            });

            this.setState({ tree: tree });

/*
            let bars = this.state.bars.slice(0);
            let current = {
                ...bar,
                loading: false,
            }
            const fIndex = lines.findIndex( l => l.name === line.name );
            if( fIndex === -1 ) lines.push( current );
            else lines[ fIndex ] = current;

            this.setState({
                lines: lines,
            });
*/
        }).catch((error) => {
            // console.error(error);

            /*
            let lines = this.state.lines.slice(0);
            const i = lines.findIndex( f => f.name === line.name );
            lines[ i ].active = false;
            lines[ i ].loading = false;
            this.setState({ lines: lines });
            */
        });

        /* */
    }


    // ----------------------------------------
    //  Render
    // ----------------------------------------

    renderLineInfo()
    {
        const toggleLine = ( l )  => {
            if( l.loading ) return null;

            let lines = this.state.lines.slice(0);
            const i = lines.findIndex( f => f.name === l.name );
            lines[ i ].active  = !lines[ i ].active;
            this.setState({ lines: lines });

            if( lines[ i ].active )
                this.getLine( l );
        };

        let lineDoms = this.state.lines.map( l => {

            let checkBox = <input
                type="checkbox"
                checked={ l.active }
                readOnly
            />

            if( l.loading )
                checkBox = <div className="apInlineLoader"></div>

            return <div key={ l.name }
                className="lineInfoContainer"
                onClick={ () => toggleLine( l )}
                onMouseEnter={() => { this.setState({ lineHighlight: l.name }) }}
                onMouseLeave={() => { this.setState({ lineHighlight: null }) }}
            >
                { checkBox }
                <span className="lineInfo">
                    <div className="colorIndicator" style={{ color: l.color }}>
                        <SvgIcon icon="circle" type="solid" />
                    </div>
                    { l.title }
                </span>
            </div>
        })

        return <div>
            { lineDoms }
        </div>
    }

    renderResetZoom()
    {
        return <div className="resetZoomContainer">
            <ApButton size="tiny" onClick={ () => { this.chart.current && this.chart.current.reset() } }>
                <SvgIcon icon="search-minus" type="solid" color="white" /> Näytä kaikki
            </ApButton>
        </div>
    }

    renderSummary()
    {
        const project = this.state.tree.length ? this.state.tree[ 0 ] : null;

        if (project && project.billing_hourly) {
            return <div className='padding'>
                <p style={{textAlign:'center'}}>{tr('no_tracking_info')}</p>
            </div>;
        }

        let chartDom = null;

        let start = null;
        let startInfo = null;
        let end = null;
        let endInfo = null;

        let subProjectCount = null;

        let work = 0;
        let workAlloc = 0;

        let component = 0;
        let componentAlloc = 0;

        let money = 0;
        let moneyAlloc = 0;

        let days = 0;
        let daysAlloc = 0;

        let money_component_spent_total, money_work_spent_total, money_expence_spent_total = 0;
        let money_component_alloc_total, money_work_alloc_total , money_expence_alloc_total = 0;
        

        if( project )
        {
            days = now.diff( moment( project.start ), 'days' );

            start = sqlToDateInput( project.start );
            startInfo = now.to( project.start );
            if( project.is_continuous )
            {
                end = tr('continuous_project');
            }
            else
            {
                end = sqlToDateInput( project.end );
                endInfo = now.to( project.end );
                daysAlloc = moment( project.end ).diff( moment( project.start ), 'days' );
            }

            subProjectCount = project.calculated.total.children_count;

            work = project.calculated.total.work_entry_total;
            workAlloc = project.calculated.total.work_alloc_total;

            component = project.calculated.total.component_done_total;
            componentAlloc = project.calculated.total.component_alloc_total;

            money = project.calculated.total.money_spent_total;
            moneyAlloc = project.calculated.total.money_alloc_total;


            money_component_spent_total = project.calculated.total.money_component_spent_total;
            money_component_alloc_total = project.calculated.total.money_component_alloc_total;

            money_work_spent_total = project.calculated.total.money_work_spent_total;
            money_work_alloc_total = project.calculated.total.money_work_alloc_total;

            money_expence_spent_total = project.calculated.total.money_expence_spent_total;
            money_expence_alloc_total = project.calculated.total.money_expence_alloc_total;


            chartDom = <div className="relative">
                { this.renderLineInfo() }
                { this.renderResetZoom() }
                <Chart
                    ref={ this.chart }

                    start={ project.start }
                    end={ project.end ? project.end : now.format( 'YYYY-MM-DD' ) }
                    lines={ this.state.lines.filter( l => l.active && !l.loading ) }
                    highlight={ this.state.lineHighlight }
                    projectId={ project.id }
                />
            </div>
        }

        const renderInfo = ( data ) => {
            const decimals = data.decimals ? data.decimals : 0;
            const iconType = data.iconRegular ? 'regular' : 'solid';

            let valueDom = data.value;
            if( data.total ) valueDom = `${ formatMoney( data.value, decimals ) } / ${ formatMoney( data.total, decimals ) }`;

            const unitDom = ( data.unit ) ? <small>{ data.unit }</small> : null;

            let infoDom = data.info;
            if( data.infoPercent && data.total )
            {
                let tmp = ( data.value / data.total ) * 100;
                if( !tmp ) infoDom = null;
                else infoDom = roundToDecimals( tmp, 1 ) + ' %';
            }

            return <div className="stat">
                <SvgIcon icon={ data.icon }  type={ iconType } />
                <div className="title">{ data.title  }</div>
                <div className="value"> { valueDom } { unitDom }</div>
                <div className="info">{ infoDom }</div>
            </div>
        };

        return <div className="padding">
            <div className="stats">

                { renderInfo({
                    icon: 'flag',
                    iconRegular: true,
                    title: tr('project_start_date'),
                    value: start,
                    info: startInfo,
                }) }

                { renderInfo({
                    icon: 'flag-checkered',
                    title: tr('project_planned_end_date'),
                    value: end,
                    info: endInfo,
                }) }

                { renderInfo({
                    icon: 'sitemap',
                    title: tr('subprojects_count'),
                    value: subProjectCount,
                }) }

                { renderInfo({
                    icon: 'users',
                    title: tr('teams_count'),
                    value: 0,
                }) }
            </div>
            <div>
                { chartDom }
            </div>
            <div className="stats">
                { renderInfo({
                    icon: 'puzzle-piece',
                    title: tr('component_money_allocated'),
                    value: money_component_spent_total,
                    total: money_component_alloc_total,
                    unit: this.state.currencySign,
                    decimals: 2,
                    infoPercent: true,
                })}
                {renderInfo({
                    icon: 'user-clock',
                    title: tr('work_money_allocated'),
                    value: money_work_spent_total,
                    total: money_work_alloc_total,
                    unit: this.state.currencySign,
                    decimals: 2,
                    infoPercent: true,
                })}
                {renderInfo({
                    icon: 'money-bill-wave',
                    title: tr('expence_money_allocated'),
                    value: money_expence_spent_total,
                    total: money_expence_alloc_total,
                    unit: this.state.currencySign,
                    decimals: 2,
                    infoPercent: true,
                })}
                {renderInfo({
                    icon: 'money-bill-wave',
                    title: tr('money_allocated'),
                    value: money,
                    total: moneyAlloc,
                    unit: this.state.currencySign,
                    decimals: 2,
                    infoPercent: true,
                })}

                { renderInfo({
                    icon: 'clock',
                    title: tr('recorded_hours_alt'),
                    value: work,
                    total: workAlloc,
                    unit: 'h',
                    infoPercent: true,
                }) }

                { renderInfo({
                    icon: 'cubes',
                    title: tr('completed_components'),
                    value: component,
                    total: componentAlloc,
                    unit: tr('pcs'),
                    infoPercent: true,
                }) }

                { renderInfo({
                    icon: 'calendar',
                    title: tr('elapsed_days'),
                    value: days,
                    total: daysAlloc,
                    unit: 'pv',
                    infoPercent: true,
                }) }
            </div>

        </div>
    }

    renderSchedule()
    {
        const project = this.state.tree.length ? this.state.tree[ 0 ] : null;

        if( !project )
            return <div className="padding text-center">
                <div className="apInlineLoader"></div>
            </div>

        if( project.is_continuous )
            return <div className="padding text-center">
                { tr('continuous_project_info') }
            </div>

        return <div className="padding">
            <ApTimeline
                loading={ this.state.loading }
                items={ this.state.tree }
                onGroupClick={ this.toggleScheduleChilds }
                padding={ 2 }
                childName="children"
                nodeOpenName="__schedule_child_open"
                readOnly
            />
        </div>
    }

    renderDone()
    {
        // const project = this.state.tree.length ? this.state.tree[ 0 ] : null;


        const renderNode = ( item ) => {

            // const bars = [{ 
                // value: item.calculated.total.work_entry_total,
                // total: item.calculated.total.work_alloc_total,
                // color: 'blue',
                // tooltip: 'Kirjatut tunnit',
            // },{
                // value: item.calculated.total.work_estimate_total,
                // total: item.calculated.total.work_alloc_total,
                // color: 'green',
                // tooltip: 'Valmisuarvio',
            // },{
                // value: item.calculated.total.work_estimate_total,
                // total: item.calculated.total.work_alloc_total,
                // color: 'orange',
                // tooltip: 'Allokoitu tavoite',
            // }];

            // const bars = [
                // { color: 'blue', name: 'work_entry_total', total: 'work_alloc_total' },
                // { color: 'green', name: 'work_estimate_total', total: 'work_alloc_total'  },
                // { color: 'orange', name: 'goal_total', total: 'work_alloc_total'  },
            // ];



            const barsDom = this.state.bars.map( bar => {

                let mainProject = this.state.tree[ 0 ];
                const mainTotal = mainProject.bar_values[ bar.total ];


                const data = item.bar_values;

                const value = data[ bar.name ];
                const total = data[ bar.total ];
                const decimals = 0;

                // const mainPercent = roundToDecimals( ( ( value / mainTotal ) * 100 ), 0 );
                const percent = total != 0? roundToDecimals( ( ( value / total ) * 100 ), 0 ): 0;


                let barValue = [{
                    value: value / mainTotal, 
                    color: bar.color,
                },{
                    color: 'white',
                    start: total / mainTotal,
                    value: 1,
                }
                ];

                let barNumbers = null;
                if( typeof value !== 'undefined' )
                {
                    barNumbers = <div className="workProgressPercent">
                        { formatMoney( value, decimals ) } / { formatMoney( total, decimals ) }
                        <div className="percent">{ percent }%</div>
                    </div>
                }

                return <div>
                    <ApTooltip text={ bar.tooltip } block position="left">
                        <div className="workProgressBar">
                            <ApProgressbar height={ 14 } value={ barValue } />
                        </div>
                        { barNumbers }
                        <div className="clear"></div>
                    </ApTooltip>
                </div>
            });

            return <div className="workProgressContainer">
                <div className="workProgressProjectName">
                    { item.name } <br/>
                    <small>{ item.project_code }</small>
                </div>
                <div className="barsContainer">
                    { barsDom }
                </div>
                <div className="clear"></div>
            </div>
        }


        return <div className="padding">
            <div className="treeContainer">
                <ApTree
                    tree={ this.state.tree }
                    nodeRenderer={ renderNode }
                    onTreeChange={ ( tree ) => { this.setState({ tree: tree })}}
                    //onNodeClick={ this.selectTreeProject }
                />
            </div>
        </div>
    }

    render()
    {
        const project = this.state.tree.length ? this.state.tree[ 0 ] : null;
        // Only reports tab is working in hourly billed project at the moment
        const selectedTab = (project && project.billing_hourly) ? 5 : this.getTabId( this.props.tab );

        let content = null;

        if( project )
        {
            let tabs = [];

            if( project.billing_hourly && false  )
            {
                tabs = [
                    <ApTab key="summary" icon="chart-pie" label={ tr('summary') } mountOnSelect>
                        <div className="padding">
                            <div className="apErrorMsg">
                                <h2>{ tr('no_tracking_info') }</h2>
                            </div>
                        </div>
                    </ApTab>
                ];
            }
            else
            {
                tabs = [
                    <ApTab key="summary" icon="chart-pie" label={ tr('summary') } mountOnSelect disabled={Boolean(project.billing_hourly)}>
                        { this.renderSummary() }
                    </ApTab>,

                    <ApTab key="done" icon="hourglass-half" label={ tr('degree_of_readiness') } disabled={Boolean(project.billing_hourly)}>
                        { this.renderDone() }
                    </ApTab>,

                    <ApTab key="expenses" icon="money-bill-wave-alt" label={ tr('costs') } disabled>
                        <div className="padding">
                            Tulossa 3
                        </div>
                    </ApTab>,

                    <ApTab key="schedule" icon="calendar" label={ tr('scheduling') } disabled={Boolean(project.billing_hourly)}>
                        { this.renderSchedule() }
                    </ApTab>,

                    <ApTab key="items" icon="cubes" label={ tr('material') } disabled>
                        <div className="padding">
                            Tulossa 5
                        </div>
                    </ApTab>,
                    
                    <ApTab key="items" icon="file-excel" label={ tr('reports') } mountOnSelect>
                        <TrackingReports tree={this.state.tree} />
                    </ApTab>,
                ]
            }

            content = <ApTabs size="large" fullWidth selected={ selectedTab } onChange={ this.onTabChange } >
                { tabs }
            </ApTabs>
        }

        return <div id="projectTracking">
            <Head
                form="tracking"
                projectId={ this.props.projectId }
                project={ project }
                loading={ this.state.loading }
                locked={ true }
                content={ content }
            />
        </div>
    }
}

export default ProjectTracking;
