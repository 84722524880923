import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
import api from 'services/Api/Api.js';
import { matchSorter } from 'match-sorter';
import { tr, currentLang, setNested } from 'services/Helpers/Helpers.js';
import './MassChange.css';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import ApFormPage from 'common/ApFormPage/ApFormPage.js';
import ApSwitch from 'common/ApSwitch/ApSwitch';
import ApSelect from 'common/ApSelect/ApSelect.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';


export default class CrmAdressesMassChange extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchLoading: false,
            loading: false,
            pages: -1,
            eventTypes: null,
            CrmEvents: null,
            data: [],
            unsavedChanges:false,
        };
        autoBind(this);

    }

    componentDidMount() {
        this.getSettings();
        this.getCrmData();
    }

    getSettings() {
        api({
            method: 'get',
            url: 'crm/company/settings',
        }).then((response) => {
            //console.log('Settings', response);
            this.setState({
                eventTypes: response.eventTypes,
            });
        }).catch((error) => {
            console.log('ERROR while fetching settings!');
        });
    }

    getCrmData() {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'crm/company/getCrmAddresstMassChangeData',
        }).then((response) => {

            this.setState({ data: response, loading: false })

        }).catch((error) => {
            console.log(error);
            this.setState({ loading: false })
            // TODO retry or fail? 
        });
    }

    save() {
        this.setState({ loading: true });

        let data = this.state.data.filter((dataRow) => dataRow.valueChanged);
        api({
            method: 'post',
            url: 'crm/company/saveCrmAddresstMassChangeData',
            data: { data: data },
        }).then((response) => {

            let data = this.state.data.slice();
            data = data.map((dataRow) => {
                dataRow.valueChanged = false;
                return dataRow;
            });
            this.setState({ data: data, loading: false, unsavedChanges: false, });

        }).catch((error) => {
            console.log(error);
            this.setState({ loading: false })
            // TODO retry or fail? 
        });
    }

    getCrmManagerList() {
        let options = [<option key="all" value="all" style={{ color: 'black' }}>- {tr('all')} -</option>];
        this.state.CrmEvents.forEach(event => {
            let duplicate = options.find(item => item.props.value === event.user_id);
            if (!duplicate) {
                options.push(<option key={event.user_id} value={event.user_id} style={{ color: 'black' }}>{event.client_user}</option>);
            }
        })
        return options;
    }

    getCrmEventList() {
        let options = [{ value: '', label: `- ${tr('all')} -` }];
        for (let event in this.state.eventTypes) {
            options.push({ value: event, label: this.state.eventTypes[event] })
        }
        return options;
    }

    showCompanyCrm(company) {
        if ("crm_company_id" in company)
            window.emitter.emit('goTo', { pathname: '/crm/company/' + company.crm_company_id + '/events' });
    }

    changeValue(id, variableName, value, props = {}) {
        // console.log(id, variableName, value);
        let data = this.state.data.slice();

        data = data.map((row) => {
            if (row.id === id) {
                row = setNested(row, variableName, value);
                row.valueChanged = true;
                Object.assign(row, props.additionalChanges);
            }
            return row;
        });
        this.setState({
            data: data,
            unsavedChanges: true,
        });
    }

    getColumns() {
        return [
            {
                id: 'crm_company.name',
                Header: tr('company_name'),
                customizable: true,
                showInitially: true,
                Cell: (props) => {
                    return this.props.renderApInput(props, "crm_company.name", this.changeValue, { tr: 'company_name', type: 'plain' });

                },
                accessor: (props) => {
                    return props.crm_company?.name;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.crm_company.name",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('company_name')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            },
            {
                id: 'crm_company.taxnumber',
                Header: tr('company_taxnumber'),
                customizable: true,
                showInitially: true,
                // onClick: this.showCompanyCrm,
                Cell: (props) => {
                    return this.props.renderApInput(props, "crm_company.taxnumber", this.changeValue, { tr: 'company_taxnumber', type: 'plain' });

                },
                accessor: (props) => {
                    return props.crm_company?.taxnumber;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.crm_company.taxnumber",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('company_taxnumber')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            },
            {
                id: 'crm_company_address.name',
                Header: tr('name'),
                customizable: true,
                showInitially: true,
                Cell: (props) => {
                    return this.props.renderApInput(props, "name", this.changeValue, {
                        tr: 'name', type: props.original.type != 'billing' ? 'plain' : 'text'
                    });

                },
                accessor: (props) => {
                    return props.name;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.name",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('name')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            },
            {
                id: 'crm_company_address.street',
                Header: tr('address'),
                customizable: true,
                showInitially: true,
                Cell: (props) => {
                    return this.props.renderApInput(props, "street", this.changeValue, {
                        tr: 'address', type: 'text'
                    });

                },
                accessor: (props) => {
                    return props.street;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.street",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('address')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            },
            {
                id: 'crm_company_address.postal_code',
                Header: tr('postal_code'),
                customizable: true,
                showInitially: true,
                Cell: (props) => {
                    return this.props.renderApInput(props, "postal_code", this.changeValue, {
                        tr: 'postal_code', type: 'text'
                    });

                },
                accessor: (props) => {
                    return props.postal_code;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.postal_code",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('postal_code')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            },
            {
                id: 'crm_company_address.city',
                Header: tr('city'),
                customizable: true,
                showInitially: true,
                Cell: (props) => {
                    return this.props.renderApInput(props, "city", this.changeValue, {
                        tr: 'city', type: 'text'
                    });

                },
                accessor: (props) => {
                    return props.city;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.city",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('city')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            },
            {
                id: 'crm_company_address.country',
                Header: tr('country'),
                customizable: true,
                showInitially: true,
                className: 'overflowable',
                Cell: (props) => {
                    return this.props.renderApInput(props, "country_code", this.changeValue, {
                        tr: 'country', type: 'country'
                    });

                },
                accessor: (props) => {
                    return props.country;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.country_code.name",
                        "_original.country_code.code",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('country')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            },




            // {
            //     id: 'action',
            //     Header: tr('actions'),
            //     accessor: "action",
            //     Cell: (props) => {
            //         return (props.original.action ?
            //             <span>{props.original.action}</span> :
            //             <span className="empty">-</span>
            //         );
            //     },
            //     customizable: true,
            //     sortable: false,
            //     showInitially: true,
            //     filterable: false,
            // },
        ]
    }


    render() {

        let multiselect = [];
        // multiselect.push({
        //     icon: "edit",
        //     label: tr('change_responsible_person'),
        //     action: (indexes) => { this.CopyProjectToNewProject(projects[indexes[0]].id) },
        //     // unselectAfter: true,
        //     // maxSelectedCount: 1,
        // });
        // multiselect.push({
        //     icon: "edit",
        //     label: tr('change_industry'),
        //     action: (indexes) => { this.CopyProjectToNewProject(projects[indexes[0]].id) },
        //     // unselectAfter: true,
        //     // maxSelectedCount: 1,
        // });
        // multiselect.push({
        //     icon: "edit",
        //     label: tr('add_crm_group'),
        //     action: (indexes) => { this.CopyProjectToNewProject(projects[indexes[0]].id) },
        //     // unselectAfter: true,
        //     // maxSelectedCount: 1,
        // });
        return (
            <ApFormPage
                onSave={this.save}
                loading={this.state.loading}
                unsaved={this.state.unsavedChanges}
                error={this.state.errorMsg}
            // readOnly={!(auth.hasModule("clients.edit") || auth.hasModule("clients.events") || auth.hasModule("clients.extranet"))}
            // noPrompt={nextUrl => {
            //     return nextUrl.startsWith("/crm/company/" + this.state.data.id + "/");
            // }}
            >
                {/* <div className="apBoxHeader">
                    <h1>{"text" }</h1>

                    

                    
                </div> */}
                <ApReactTable
                    rememberId="crm_contact_mass_change_list"
                    showFiltersInitially
                    loading={this.state.loading}
                    data={this.state.data}
                    multiSelect


                    columns={[
                        {
                            customizable: true,
                            columns: this.getColumns(),
                        }
                    ]}
                    // defaultSorted={[
                    //     { id: "occurred_at", desc: true }
                    // ]}
                    filterable
                    // rowActions={(row) =>
                    //     <div className="apSimpleButton" onClick={() => this.showCompanyCrm(row)}><SvgIcon icon="arrow-right" type="solid" /></div>
                    // }
                    multiselect={multiselect}
                    minRows={5}
                />



            </ApFormPage>



        );
    }
}
