import React from 'react';
import autoBind from 'react-autobind';
import api from 'services/Api/Api.js';

import ApSwitch from 'common/ApSwitch/ApSwitch.js';
// import ApButton from 'common/ApButton/ApButton.js';
import { tr } from 'services/Helpers/Helpers.js';
import { ApInput } from 'common/ApInput/ApInput.js';
import { treemapResquarify } from 'd3';

export default class ExtranetModules extends React.Component
{

	constructor(props)
	{

		super(props);

		this.state = {
			settings: [],

			showTimetracking: false,
				showDescription: false,

			showProjects: false,
			showDocuments: false,
			showBrochures: false,
			showStats: false,
			showReports: false,
			showPermits: false,
			showSupplierPortal: false,
			showAssignmentRequest: false,

		};

		autoBind(this);

	}

	UNSAFE_componentWillReceiveProps(props)
	{

		//this.getSettings();

		this.setState({
			showTimetracking: props.data.show_timetracking,
				showDescription: props.data.show_description,

			showProjects: props.data.show_projects,
			showDocuments: props.data.show_documents,
			showBrochures: props.data.show_brochures,
			showStats: props.data.show_statistics,
			showNews: props.data.show_news,
			showReports: props.data.show_reports,
			showPermits: props.data.show_permits,
			showSupplierPortal: props.data.show_supplier_portal,
			supplierPortalIsActive: props.data.supplier_portal_is_active,
			showComponentUsageToSupplier: props.data.days_visible_supplier!==0,
			showAssignmentRequest: props.data.show_assignment_requests,
			showMaterialCertificates: props.data.show_material_certificates,
		}, () => {
			//console.log(this.state);
		});

	}
/*
	getSettings()
	{

		api({ 
			method: 'get',
			url: 'crm/extranet/settings'
		}).then((response) => {
			//response);
			this.setState({ 
				settings: response,
				showTimetracking: response.show_timetracking,
				showProjects: response.show_projects,
				showDocuments: response.show_documents,
				showBrochures: response.show_brochures,
				showStats: response.show_statistics,
				showNews: response.show_news,
				showReports: response.show_reports
			});
		}).catch((error) => {
			console.log(error);
		});

	}
	

	saveSettings()
	{

		api({
			method: 'post',
			url: 'crm/extranet/saveSettings',
			data: {
				showTimetracking: this.state.showTimetracking,
				showProjects: this.state.showProjects, 
				showDocuments: this.state.showDocuments,
				showBrochures: this.state.showBrochures,
				showStats: this.state.showStats,
				showNews: this.state.showNews,
				showReports: this.state.showReports
			}
		}).then((response) => {
			//console.log(response);
		}).catch((error) => {
			console.log(error);
		});

	}
*/
	
	handleSwitchChange(e)
	{

		switch (e.target.name)
		{

			case "showTimetracking":
				this.setState({ showTimetracking: !this.state.showTimetracking });
				this.props.handleSwitchChange("showTimetracking");
				break;

			case "showProjects":
				this.setState({ showProjects: !this.state.showProjects });
				this.props.handleSwitchChange("showProjects");
				break;

			case "showDocuments":
				this.setState({ showDocuments: !this.state.showDocuments });
				this.props.handleSwitchChange("showDocuments");
				break;

			case "showBrochures":
				this.setState({ showBrochures: !this.state.showBrochures });
				this.props.handleSwitchChange("showBrochures");
				break;

			case "showStats":
				this.setState({ showStats: !this.state.showStats });
				this.props.handleSwitchChange("showStats");
				break;

			case "showNews":
				this.setState({ showNews: !this.state.showNews });
				this.props.handleSwitchChange("showNews");
				break;
			case "showReports":
				this.setState({ showReports: !this.state.showReports });
				this.props.handleSwitchChange("showReports");
				break;
			case "showDescription":
				this.setState({ showDescription: !this.state.showDescription });
				this.props.handleSwitchChange("showDescription");
				break;
			case "showPermits":
				this.setState({ showPermits: !this.state.showPermits });
				this.props.handleSwitchChange("showPermits");
				break;
			case "showSupplierPortal":
				this.setState({ showSupplierPortal: !this.state.showSupplierPortal });
				this.props.handleSwitchChange("showSupplierPortal");
				break;
			case "supplierPortalIsActive":
				this.setState({ supplierPortalIsActive: !this.state.supplierPortalIsActive });
				this.props.handleSwitchChange("supplierPortalIsActive");
				break; 
			case "showComponentUsageToSupplier":
				if (this.state.showComponentUsageToSupplier===true) {
					this.props.onChange("days_visible_supplier", 0);
				}
				this.setState({ showComponentUsageToSupplier: !this.state.showComponentUsageToSupplier, });
				
				break; 
			case 'showAssignmentRequest':
				this.setState({showAssignmentRequest: !this.state.showAssignmentRequest});
				this.props.handleSwitchChange("showAssignmentRequest");
				break;
			case 'showMaterialCertificates':
				this.setState({showMaterialCertificates: !this.state.showMaterialCertificates});
				this.props.handleSwitchChange("showMaterialCertificates");
				break;

			default:

		}

	}

	render()
	{

		return(

			<div className="apBox" id="moduleSettings">

				<div className="apBoxHeader">
					<h1>{tr('visibility_settings_customer') }</h1>
					<p>{tr('visibility_settings_info_customer') }</p>
				</div>

				<div className="clear padding">

					<div className="apColumn w33">
						<div className="apFormGroup">
							<div className="apSwitchBlock small">
								<label>{ tr('show_approval_of_working_hours') }</label>
								<ApSwitch
									name="showTimetracking"
									id="showTimetracking"
									small
									on={this.state.showTimetracking} 
									onChange={this.handleSwitchChange}
								/>
							</div>

							<div className="apSwitchBlock small" 
								style={
									{
										paddingLeft: '2em',
										paddingRight: '3.5em'
									}}>
								<label>{ tr('show_work_description') }</label><br />
								<small>{ tr('show_work_description_info') }</small>
								<ApSwitch
									name="showDescription"
									id="showDescription"
									small
									on={this.state.showDescription} 
									onChange={this.handleSwitchChange}
								/>
							</div>
						</div>					
					</div>					

					<div className="apColumn w33">
						<div className="apFormGroup">
							<div className="apSwitchBlock small">
								<label>{ tr('show_projects') }</label>
								<ApSwitch
									name="showProjects"
									id="showProjects"
									small 
									on={this.state.showProjects} 
									onChange={this.handleSwitchChange}
								/>
							</div>
						</div>					
					</div>										

					<div className="apColumn w33">
						<div className="apFormGroup">
							<div className="apSwitchBlock small">
								<label>{ tr('show_documents') }</label>
								<ApSwitch
									name="showDocuments"
									id="showDocuments"
									small 
									on={this.state.showDocuments} 
									onChange={this.handleSwitchChange}
								/>
							</div>
						</div>					
					</div>

					<div className="apColumn w33">
						<div className="apFormGroup">
							<div className="apSwitchBlock small">
								<label>{ tr('show_brochures') }</label>
								<ApSwitch
									name="showBrochures"
									id="showBrochures"
									small 
									on={this.state.showBrochures} 
									onChange={this.handleSwitchChange}
								/>
							</div>
						</div>					
					</div>

					<div className="apColumn w33">
						<div className="apFormGroup">
							<div className="apSwitchBlock small">
								<label>{ tr('show_statistics') }</label>
								<ApSwitch
									name="showStats"
									id="showStats"
									small 
									on={this.state.showStats} 
									onChange={this.handleSwitchChange}
								/>
							</div>
						</div>					
					</div>

					<div className="apColumn w33">
						<div className="apFormGroup">
							<div className="apSwitchBlock small">
								<label>{ tr('show_news') }</label>
								<ApSwitch
									name="showNews"
									id="showNews"
									small 
									on={this.state.showNews} 
									onChange={this.handleSwitchChange}
								/>
							</div>
						</div>					
					</div>

					<div className="apColumn w33">
						<div className="apFormGroup">
							<div className="apSwitchBlock small">
								<label>{ tr('show_reports') }</label>
								<ApSwitch
									name="showReports"
									id="showReports"
									small 
									on={this.state.showReports} 
									onChange={this.handleSwitchChange}
								/>
							</div>
						</div>					
					</div>

					<div className="apColumn w33">
						<div className="apFormGroup">
							<div className="apSwitchBlock small">
								<label> {tr('show_permits')} </label>
								<ApSwitch
									name="showPermits"
									id="showPermits"
									small
									on={this.state.showPermits}
									onChange={this.handleSwitchChange}
								/>
							</div>
						</div>
					</div>

					<div className="apColumn w33">
						<div className="apFormGroup">
							<div className="apSwitchBlock small">
								<label htmlFor='showAssignmentRequest'>{tr('show_assignment_requests')}</label>
								<ApSwitch
									name="showAssignmentRequest"
									id="showAssignmentRequest"
									small
									on={this.state.showAssignmentRequest}
									onChange={this.handleSwitchChange}
								/>
							</div>
						</div>
					</div>

					<div className="apColumn w33">
						<div className="apFormGroup">
							<div className="apSwitchBlock small">
								<label htmlFor='showMaterialCertificates'>{tr('show_material_certificates')}</label>
								<ApSwitch
									name="showMaterialCertificates"
									id="showMaterialCertificates"
									small
									on={this.state.showMaterialCertificates}
									onChange={this.handleSwitchChange}
								/>
							</div>
						</div>
					</div>

				</div>

				
				<div className="clear padding">

					<div className="apBoxHeader">
						<h2>{tr('visibility_settings_supplier')}</h2>
						<p>{tr('visibility_settings_info_supplier')}</p>
					</div>
						
					<div className="apColumn w33">
						<div className="apFormGroup">
							<div className="apSwitchBlock small">
								<label> {tr('supplier_portal_is_active')} </label>
								<ApSwitch
									name="supplierPortalIsActive"
									id="supplierPortalIsActive"
									small
									on={this.state.supplierPortalIsActive}
									onChange={this.handleSwitchChange}
								/>
							</div>
						</div>
					</div>
					<div className="apColumn w33">
						<div className="apFormGroup">
							<div className="apSwitchBlock small">
								<label> {tr('show_supplier_portal')} </label>
								<ApSwitch
									name="showSupplierPortal"
									id="showSupplierPortal"
									small
									on={this.state.showSupplierPortal}
									onChange={this.handleSwitchChange}
								/>
							</div>
						</div>
					</div>
					<div className="apColumn w33">
						<div className="apFormGroup">
							<div >
								
								<label> {tr('component_usage_amount_is_visibled_to_supplier')} </label>
								<div style={{
									"right": "10px",
									"float": "right"
								}}>
									<ApSwitch
										name="showComponentUsageToSupplier"
										id="showComponentUsageToSupplier"
										small
										on={this.state.showComponentUsageToSupplier}
										onChange={this.handleSwitchChange}
										
									/>
								</div>
								
								<div style={{ "marginTop": "25px"}}>
									<label> {tr('how_long_components_count_is_visible_to_supplier')} </label>
									<ApInput
										type="number"
										name="days_visible_supplier"
										id="days_visible_supplier"
										value={this.props.data.days_visible_supplier}
										label={tr('days')}
										onChange={(e) => {
											this.props.onChange("days_visible_supplier", e.target.value)
										}}
										loading={this.props.loading}
										disabled={this.props.loading || !this.state.showComponentUsageToSupplier}
										clearable
									/>
								</div>
								
							</div>
						</div>
					</div>
						

				</div>

			</div>

		);

	}

}
