import React from 'react';
import autoBind from 'react-autobind';
import moment from 'moment';
import api from 'services/Api/Api.js';
import { matchSorter } from 'match-sorter';
import { tr, currentLang, setNested, keyExists } from 'services/Helpers/Helpers.js';
import './MassChange.css';
import SvgIcon from 'common/SvgIcon/SvgIcon.js';
import ApReactTable from 'common/ApReactTable/ApReactTable.js';
import ApFormPage from 'common/ApFormPage/ApFormPage.js';
import ApSwitch from 'common/ApSwitch/ApSwitch';
import ApSelect from 'common/ApSelect/ApSelect.js';
import { ApInput, ApInputStack, ApAddon } from 'common/ApInput/ApInput.js';
import ApModal from 'common/ApModal/ApModal.js';
import ApButton from 'common/ApButton/ApButton.js';

export default class CrmContactMassChange extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            searchLoading: false,
            loading: false,
            pages: -1,
            eventTypes: null,
            CrmEvents: null,
            data: [],
            languageOptions: [],
            showModifyModal: false,
            modifyModalselect: 1,
            tableIndexes: [],
            modifyValue: false,
            unsavedChanges: false,
        };
        autoBind(this);

    }

    componentDidMount() {
        this.getSettings();
        this.getCrmData();
        // this.getLanguages();
    }

    getSettings() {
        api({
            method: 'get',
            url: 'crm/company/settings',
        }).then((response) => {
            //console.log('Settings', response);
            this.setState({
                eventTypes: response.eventTypes,
            });
        }).catch((error) => {
            console.log('ERROR while fetching settings!');
        });
    }
    getLanguages() {
        api({
            method: 'get',
            url: 'languages/all'
        }).then((response) => {
            const languageOptions = [];
            response.forEach(lang => {
                if (lang.id === 1 || lang.id === 2 || lang.id === 3) {
                    languageOptions.push({
                        value: lang.id,
                        label: lang.name
                    });
                }
            });
            this.setState({ languageOptions: languageOptions });
        }).catch((error) => {
            console.log('Error while fetching languages.')
        });
    }

    getCrmData() {
        this.setState({ loading: true });
        api({
            method: 'get',
            url: 'crm/company/getCrmContactMassChangeData',
        }).then((response) => {
            //console.log( 'getCustomerData', response );
            this.setState({
                data: response,
                loading: false,
            });

        }).catch((error) => {
            console.error(error);
            window.emitter.emit('popper', {
                type: 'danger',
                content: <strong>{tr('get_error')}</strong>,
            });
            this.setState({
                data: [],
                loading: false,
            })
        });
    }



    save() {
        this.setState({ loading: true });

        let data = this.state.data.filter((dataRow) => dataRow.valueChanged);
        api({
            method: 'post',
            url: 'crm/company/saveCrmContactMassChangeData',
            data: { data: data },
        }).then((response) => {

            let data = this.state.data.slice();
            data = data.map((dataRow) => {
                dataRow.valueChanged = false;
                return dataRow;
            });
            this.setState({ data: data, loading: false, unsavedChanges: false, });

        }).catch((error) => {
            console.log(error);
            this.setState({ loading: false })
            // TODO retry or fail? 
        });
    }

    getCrmManagerList() {
        let options = [<option key="all" value="all" style={{ color: 'black' }}>- {tr('all')} -</option>];
        this.state.data.forEach(event => {
            let duplicate = options.find(item => item.props.value === event.user_id);
            if (!duplicate) {
                options.push(<option key={event.user_id} value={event.user_id} style={{ color: 'black' }}>{event.client_user}</option>);
            }
        })
        return options;
    }

    getCrmEventList() {
        let options = [{ value: '', label: `- ${tr('all')} -` }];
        for (let event in this.state.eventTypes) {
            options.push({ value: event, label: this.state.eventTypes[event] })
        }
        return options;
    }

    showCompanyCrm(company) {
        if ("crm_company_id" in company)
            window.emitter.emit('goTo', { pathname: '/crm/company/' + company.crm_company_id + '/events' });
    }


    changeValue(id, variableName, value, props = {}) {
        // console.log(id, variableName, value);
        let data = this.state.data.slice();

        data = data.map((row) => {
            if (row.id === id) {
                row = setNested(row, variableName, value);
                row.valueChanged = true;
                Object.assign(row, props.additionalChanges);
            }
            return row;
        });
        this.setState({
            data: data,
            unsavedChanges: true
        });
    }



    getColumns() {
        return [
            {
                id: 'crm_company.name',
                Header: tr('company_name'),
                customizable: true,
                showInitially: true,
                className: 'overflowable',
                Cell: (props) => {
                    return this.props.renderApInput(props, "crm_company.name", this.changeValue, { tr: 'company_name', type: 'plain' });

                },
                accessor: (props) => {
                    return props.crm_company?.name;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.crm_company.name",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('company_name')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            },
            {
                id: 'crm_company.taxnumber',
                Header: tr('company_taxnumber'),
                customizable: true,
                showInitially: true,
                // onClick: this.showCompanyCrm,
                Cell: (props) => {
                    return this.props.renderApInput(props, "crm_company.taxnumber", this.changeValue, { tr: 'company_taxnumber', type: 'plain' });

                },
                accessor: (props) => {
                    return props.crm_company?.taxnumber;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.crm_company.taxnumber",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('company_taxnumber')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            },
            {
                id: 'crm_companyContact.first_name',
                Header: tr('first_name'),
                customizable: true,
                showInitially: true,
                Cell: (props) => {
                    return this.props.renderApInput(props, "first_name", this.changeValue);

                },
                accessor: (props) => {
                    return props.first_name;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.first_name",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('first_name')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            },
            {
                id: 'crm_companyContact.last_name',
                Header: tr('last_name'),
                customizable: true,
                showInitially: true,
                Cell: (props) => {
                    return this.props.renderApInput(props, "last_name", this.changeValue);

                },
                accessor: (props) => {
                    return props.last_name;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.last_name",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('last_name')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            },
            {
                id: 'crm_companyContact.title',
                Header: tr('title'),
                customizable: true,
                showInitially: true,
                Cell: (props) => {
                    return this.props.renderApInput(props, "title", this.changeValue);
                },
                accessor: (props) => {
                    return props.title;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.title",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('title')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            },
            {
                id: 'crm_companyContact.phone1',
                Header: tr('phone'),
                customizable: true,
                showInitially: true,
                Cell: (props) => {
                    return this.props.renderApInput(props, "phone1", this.changeValue, { tr: 'phone' });

                },
                accessor: (props) => {
                    return props.phone1;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.phone1",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('phone')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            },
            {
                id: 'crm_companyContact.phone2',
                Header: tr('backup_phone'),
                customizable: true,
                showInitially: true,
                Cell: (props) => {
                    return this.props.renderApInput(props, "phone2", this.changeValue, { tr: 'backup_phone' });
                },
                accessor: (props) => {
                    return props.phone2;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.phone2",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('backup_phone')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            },
            {
                id: 'crm_companyContact.email',
                Header: tr('email'),
                customizable: true,
                showInitially: true,
                Cell: (props) => {
                    return this.props.renderApInput(props, "email", this.changeValue);
                },
                accessor: (props) => {
                    return props.email;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.email",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    return (
                        <input
                            placeholder={tr('email')}
                            onChange={event => onChange(event.target.value)}
                            value={filter ? filter.value : ''}
                            style={{
                                width: '100%',
                                backgroundColor: 'lightgray',
                                color: 'black',
                            }}
                        />
                    )
                }
            },
            {
                id: 'crm_companyContact.groups',
                Header: tr('groups'),
                customizable: true,
                showInitially: true,
                Cell: (props) => {
                    return this.props.renderApInput(props, "groups", this.changeValue, { type: 'groupsContact' });
                },
                accessor: (props) => {
                    return props.groups;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.groups.*.name",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: false,
                Filter: ({ filter, onChange }) => {

                    // return this.props.renderApInput(filter ? filter.value : '', "groups", onChange, { type: 'groupsContact', search:true });
                    // return (search
                    //     <input
                    //         placeholder={tr('groups')}
                    //         onChange={event => onChange(event.target.value)}
                    //         value={filter ? filter.value : ''}
                    //         style={{
                    //             width: '100%',
                    //             backgroundColor: 'lightgray',
                    //             color: 'black',
                    //         }}
                    //     />
                    // )
                }
            },
            {
                id: 'crm_companyContact.language',
                Header: tr('language'),
                customizable: true,
                showInitially: true,
                Cell: (props) => {
                    return this.props.renderApInput(props, "language", this.changeValue, { type: 'languageContact', });

                },
                accessor: (props) => {
                    return props.language;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.language",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: true,
                Filter: ({ filter, onChange }) => {
                    // return (
                    //     <input
                    //         placeholder={tr('language')}
                    //         onChange={event => onChange(event.target.value)}
                    //         value={filter ? filter.value : ''}
                    //         style={{
                    //             width: '100%',
                    //             backgroundColor: 'lightgray',
                    //             color: 'black',
                    //         }}
                    //     />
                    // )
                }
            },
            // {
            //     id: 'crm_companyContact.extranet_active',
            //     Header: tr('extranet_active'),
            //     customizable: true,
            //     showInitially: true,
            //     Cell: (props) => {
            //         return this.props.renderApInput(props, "extranet_active", this.changeValue, { type: 'switch' });

            //     },
            //     accessor: (props) => {
            //         return props.extranet_active;
            //     },
            //     filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
            //         keys: [
            //             "_original.extranet_active",
            //         ],
                        // threshold: matchSorter.rankings.ACRONYM,
            //     }),
            //     filterAll: false,
            //     Filter: ({ filter, onChange }) => {
            //         // return (
            //         //     <input
            //         //         placeholder={tr('extranet_active')}
            //         //         onChange={event => onChange(event.target.value)}
            //         //         value={filter ? filter.value : ''}
            //         //         style={{
            //         //             width: '100%',
            //         //             backgroundColor: 'lightgray',
            //         //             color: 'black',
            //         //         }}
            //         //     />
            //         // )
            //     }
            // },
            {
                id: 'crm_companyContact.no_mailing',
                Header: tr('no_customer_mailing'),
                customizable: true,
                showInitially: true,
                Cell: (props) => {
                    return this.props.renderApInput(props, "no_mailing", this.changeValue, { type: 'switch', tr: 'no_customer_mailing' });

                },
                accessor: (props) => {
                    return props.no_mailing;
                },
                filterMethod: (filter, rows) => matchSorter(rows, filter.value, {
                    keys: [
                        "_original.no_mailing",
                    ],
                    threshold: matchSorter.rankings.ACRONYM,
                }),
                filterAll: false,
                Filter: ({ filter, onChange }) => {
                    // return (
                    //     <input
                    //         placeholder={tr('no_customer_mailing')}
                    //         onChange={event => onChange(event.target.value)}
                    //         value={filter ? filter.value : ''}
                    //         style={{
                    //             width: '100%',
                    //             backgroundColor: 'lightgray',
                    //             color: 'black',
                    //         }}
                    //     />
                    // )
                }
            },



            // {
            //     id: 'action',
            //     Header: tr('actions'),
            //     accessor: "action",
            //     Cell: (props) => {
            //         return (props.original.action ?
            //             <span>{props.original.action}</span> :
            //             <span className="empty">-</span>
            //         );
            //     },
            //     customizable: true,
            //     sortable: false,
            //     showInitially: true,
            //     filterable: false,
            // },
        ]
    }
    getModifyOptions() {

        let options = [];

        // options.push({ value: 1, label: tr('extranet_active') });
        options.push({ value: 2, label: tr('no_customer_mailing') });
        options.push({ value: 3, label: tr('add_group') });
        options.push({ value: 4, label: tr('remove_group') });

        return options;
    }

    renderSwitch() {
        let option = this.getModifyOptions().find(row => row.value == this.state.modifyModalselect);
        return <div className="apSwitchBlock">
            <label htmlFor="modifyValue-switch" className="info">
                {option?.label}
                {/* <small>{tr('sent_po_manually_info')}</small> */}
            </label>
            <ApSwitch
                id={'modifyValue-switch'}
                on={Boolean(this.state.modifyValue)}
                onChange={(data) => this.setState({ modifyValue: !Boolean(this.state.modifyValue) })}
                disabled={this.state.loading}
                loading={this.state.loading}
            />
        </div>

    }
    changeGroupValue(data) {
        // console.log(data);
        this.setState({ modifyGroupValues: data })

    }
    rendershowGroups() {

        return this.props.renderApInput(this.state, "modifyGroupValues",
            (id, name, data) => { this.changeGroupValue(data) },
            { type: 'groupsContact', tr: 'groups', getAll: true, useDirect: true });



    }

    closeModifyModal(save = false) {
        if (save) {
            let modifyModalselect = this.state.modifyModalselect;
            if (modifyModalselect == '1' || modifyModalselect == '2') {

                let selectedParameter = null;
                if (this.state.modifyModalselect == 1) {
                    selectedParameter = "extranet_active";
                }
                if (this.state.modifyModalselect == 2) {
                    selectedParameter = "no_mailing";
                }

                let tableIndexes = this.state.tableIndexes;
                let data = this.state.data.slice();
                data = data.map((dataRow, index) => {
                    if (tableIndexes.includes(index)) {
                        dataRow[selectedParameter] = this.state.modifyValue;
                        dataRow.valueChanged = true;

                    }
                    return dataRow;
                });
                this.setState({ data: data, unsavedChanges: true });
            }
            if (modifyModalselect == '3' || modifyModalselect == '4') {
                let add = true;
                if (modifyModalselect == '4') {
                    add = false;
                }

                let tableIndexes = this.state.tableIndexes;
                let data = this.state.data.slice();
                data = data.map((dataRow, index) => {
                    if (tableIndexes.includes(index)) {
                        let groups = dataRow['groups'];

                        if (add) {
                            for (let modifyGroupValue of this.state.modifyGroupValues) {
                                let notExist = groups.find(item => item.id === modifyGroupValue.id) == undefined;
                                if (notExist) {
                                    groups.push(modifyGroupValue);
                                }
                            }
                        }
                        else {
                            for (let modifyGroupValue of this.state.modifyGroupValues) {
                                groups = groups.filter((row) => modifyGroupValue.id != row.id);
                            }
                        }
                        dataRow['groups'] = groups;
                        dataRow.valueChanged = true;

                    }
                    return dataRow;
                });
                this.setState({ data: data, unsavedChanges: true });




            }



        }
        this.setState({ modifyValue: false, showModifyModal: false, tableIndexes: [] });
    }

    openModifyModal(tableIndexes) {
        this.setState({ tableIndexes: tableIndexes });
        this.setState({ modifyValue: false, showModifyModal: true });
    }


    renderModifyModal() {
        let showSwitch = true;
        let showGroups = false;
        switch (this.state.modifyModalselect) {
            case "1":
                showSwitch = true;
                break;
            case "2":
                showSwitch = true;
                break;
            case "3":
                showSwitch = false;
                showGroups = true;
                break;
            case "4":
                showSwitch = false;
                showGroups = true;
                break;
        }

        return <ApModal
            show={this.state.showModifyModal}
            onOpen={() => { }}
            handleClose={() => this.closeModifyModal()}
            //closeFromBg
            className="narrow"
            header={
                <div className="padding">
                    <h4> {tr('edit')}</h4>
                </div>
            }
            body={<div className='padding'>
                {/* <ApInputStack> */}

                <ApInput
                    type="select"
                    options={this.getModifyOptions()}
                    id={"modalOption"}
                    name={"modalOption"}
                    value={this.state.modifyModalselect || 1}
                    onChange={(data) => this.setState({ modifyModalselect: data.target.value, modifyValue: false, modifyGroupValues: null })}

                />


                {showSwitch && this.renderSwitch()}
                {showGroups && this.rendershowGroups()}
            </div>

            }
            footer={
                <div className="modalFooter">

                    <ApButton className="cancel" onClick={() => this.closeModifyModal()}>
                        <SvgIcon icon="times" type="solid" />
                        {tr('cancel')}
                    </ApButton>

                    <ApButton className="save" color="green" onClick={() => this.closeModifyModal(true)} >
                        <SvgIcon icon="check" type="solid" />
                        {tr('continue')}
                    </ApButton>

                </div>
            }
        />



    }



    render() {

        let multiselect = [];
        multiselect.push({
            icon: "edit",
            label: tr('modify_value'),
            action: (indexes) => { this.openModifyModal(indexes); },
            unselectAfter: true,
            // maxSelectedCount: 1,
        });
        // multiselect.push({
        //     icon: "edit",
        //     label: tr('add_row'),
        //     action: (indexes) => { this.setState({ tableIndexes: indexes }); },
        //     // unselectAfter: true,
        //     // maxSelectedCount: 1,
        // });
        // multiselect.push({
        //     icon: "edit",
        //     label: tr('remove_row'),
        //     action: (indexes) => { this.setState({ tableIndexes: indexes }); },
        //     // unselectAfter: true,
        //     // maxSelectedCount: 1,
        // });
        return (
            <div>
                <ApFormPage
                    onSave={this.save}
                    loading={this.state.loading}
                    unsaved={this.state.unsavedChanges}
                    error={this.state.errorMsg}
                // readOnly={!(auth.hasModule("clients.edit") || auth.hasModule("clients.events") || auth.hasModule("clients.extranet"))}
                // noPrompt={nextUrl => {
                //     return nextUrl.startsWith("/crm/company/" + this.state.data.id + "/");
                // }}
                >
                    {/* <div className="apBoxHeader">
                    <h1>{"text" }</h1>

                    

                    
                </div> */}
                    <ApReactTable
                        rememberId="crm_contact_mass_change_list"
                        showFiltersInitially
                        loading={this.state.loading}
                        data={this.state.data}
                        // multiSelect


                        columns={[
                            {
                                customizable: true,
                                columns: this.getColumns(),
                            }
                        ]}
                        // defaultSorted={[
                        //     { id: "occurred_at", desc: true }
                        // ]}
                        filterable
                        // rowActions={(row) =>
                        //     <div className="apSimpleButton" onClick={() => this.showCompanyCrm(row)}><SvgIcon icon="arrow-right" type="solid" /></div>
                        // }
                        multiselect={multiselect}
                        minRows={5}
                    />



                </ApFormPage>
                {this.renderModifyModal()}
            </div>

        );
    }
}
