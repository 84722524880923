import React from 'react';
import autoBind from 'react-autobind';
import {
	ApTabs,
	ApTab
} from 'common/ApTabs/ApTabs.js';

import Crm from './Crm.js';
import CrmList from './CrmList.js';
import { tr, hasPermissions } from 'services/Helpers/Helpers.js';
import MassImport from './MassImport/MassImport.js';
import MassExport from './MassExport/MassExport.js';
import MassChangeMain from './MassChange/MassChangeMain.js';

export default class Main extends React.Component {

	constructor(props) {

		super(props);

		autoBind(this);

	}

	render() {

		return (

			<div className="apBox">

				<div className="apBoxHeader">
					<h1> {tr('customers')} </h1>
					<p> {tr('customers_info')} </p>
				</div>

				<ApTabs>

					<ApTab icon="building" label={tr('customers')}>
						<Crm />
					</ApTab>

					<ApTab icon="marker" label={tr('crm_entries')}>
						<CrmList />
					</ApTab>
					{hasPermissions("clients.edit.crm_mass_change") && <ApTab icon="snowplow" label={tr('crm_mass_change')}>
						<MassChangeMain />
					</ApTab>}
					{hasPermissions("clients.edit.import_and_export") && <ApTab icon="file-export" label={tr('export')} mountOnSelect>
						<MassExport />
					</ApTab>}
					{hasPermissions("clients.edit.import_and_export") && <ApTab icon="file-import" label={tr('import')} mountOnSelect>
						<MassImport />
					</ApTab>}

				</ApTabs>

			</div>

		);

	}

}
